import './create.scss';
import Button from '../../../components/Button/Button';
import StepLine from '../../../components/StepLine/StepLine';
import { useEffect, useState } from 'react';
import { FirstStep } from './firstStep';
import apiService from '../../../service/apiService';
import { useDispatch, useSelector } from 'react-redux';
import { SecondStep } from './secondStep';
import { ThirdStep } from './thirdStep';
import { updateUserData } from '../../../store/actions';

const steps = [
    {label: 'Collection', value: '1'},
    {label: 'NFT', value: '2'},
    {label: 'Confirm', value: '3'},
]

const service = new apiService();

export const Create = ({active}) => {

    const [currentStep, setCurrentStep] = useState(0)
    const [isNextDisable, setNextDisable] = useState(true)
    const [isStepChange, setStepChange] = useState(true)

    const {authData} = useSelector(state => state);
    const dispatch = useDispatch();

    const [collectionsList, setCollectionsList] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const [isNftsLoading, setNftsLoading] = useState(true)
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [nfts, setNfts] = useState([]);
    const [selectedNfts, setSelectedNfts] = useState([]);
    const totalWeight = selectedNfts.reduce((acc, cur) => acc + cur?.weight, 0)

    const [stakeInfo, setStakeInfo] = useState(null)

    useEffect(() => {
        service.GetNFTStakePools(authData?.token, authData?.wallet).then(res => {
            setCollectionsList(res);
            setLoading(false);
        })
    }, [authData])

    useEffect(() => {
        if (selectedCollection) {
            setNextDisable(false)
        }
    }, [selectedCollection])

    useEffect(() => {
        setNextDisable(true)

        if (currentStep === 2) {
            setStakeInfo(null)
            service.NewNFTStake(authData?.token, authData?.wallet, selectedCollection.index, selectedNfts.map(s => s.nfTokenID))
                .then(res => setStakeInfo(res)).then(() => {
                    service.getUserData(authData.wallet, authData.token).then(res => {
                        dispatch(updateUserData(res))
                    })
                })
        }
    }, [currentStep]);

    useEffect(() => {
        if (selectedCollection) {
            setNftsLoading(true)
            service.GetUserStakableNFTs(authData?.token, authData?.wallet, selectedCollection.index).then(res => {
                setNftsLoading(false);
                setNfts(res);
            })
        }
    }, [selectedCollection]);

    useEffect(() => {
        setNextDisable(!(selectedNfts.length > 0))
    }, [selectedNfts])

    return(
        <div className={"BurnCreate" + (active ? ' active ' : '')}>
            <div className="BurnCreate__steps">
                <StepLine
                    stepChange={isStepChange}
                    nextStep={() => setCurrentStep(state => state + 1)}
                    items={steps}
                    currentIndex={currentStep}
                    setCurrentStep={setCurrentStep}
                    nextDisable={isNextDisable}
                    isOnlyThree={true}
                /> 
            </div>
            <div className="BurnCreate__body">
                <FirstStep
                    active={currentStep === 0} 
                    collectionsList={collectionsList}
                    selectedCollection={selectedCollection}
                    setSelectedCollection={setSelectedCollection}
                    isLoading={isLoading}
                />
                <SecondStep
                    active={currentStep === 1} 
                    selectedCollection={selectedCollection}
                    nfts={nfts}
                    selectedNfts={selectedNfts}
                    setSelectedNfts={setSelectedNfts}
                    totalWeight={totalWeight}
                    isNftsLoading={isNftsLoading}
                />
                <ThirdStep
                    active={currentStep === 2} 
                    selectedCollection={selectedCollection}
                    totalWeight={totalWeight}
                    selectedNfts={selectedNfts}
                    stakeInfo={stakeInfo}
                />
            </div>
            {
                currentStep != 3 ? (
                    <div className="BurnCreate__action">
                        <Button
                            text={'Next'}
                            disabled={isNextDisable}
                            onClick={() => {
                                setCurrentStep(state => state + 1)
                            }}
                            variant={'default'}
                            />
                    </div>
                ) : null
            }
            
        </div>
    )
}