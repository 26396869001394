import { useEffect } from 'react';
import { useState } from 'react';
import './ProfitDg.scss';
import * as _ from 'lodash';

const ProfitDg = ({
    count,
    tokenName,
    selectedPercent,
    active,
    label,
    stake,
    isSame = true
}) => {

    const [first, setFirst] = useState(100)
    const [second, setSecond] = useState(100)
    const [profit, setProfit] = useState(0)

    useEffect(() => {
       
        if(stake) {
            if(count && selectedPercent?.reward && selectedPercent?.countRewards && active) {
                const profit = _.round(selectedPercent.reward * 100 * selectedPercent.countRewards)
                const f = 100 - (100 - profit);
                setFirst(100 - f)
                setProfit(Number(count) - (Number(count) - (Number(count) / 100 * f)))
            }
        } else {
            if(count && selectedPercent?.reward && selectedPercent?.countRewards && active) {
                const profit = _.round(selectedPercent.reward * 100 * selectedPercent.countRewards)
                const f = profit - 100;
                isSame ? setFirst(100 - f) : setFirst(80);
                
                setProfit(Number(count) - (Number(count) - (Number(count) / 100 * f)))
            }
        }
        

    }, [count, selectedPercent, active, stake])
    
    if(stake) {
        return (
            <div className="ProfitDg">
                <div className="ProfitDg__cv">
                    <div className="ProfitDg__cv_item" style={{height: `${first}%`}}>
                        <div className="ProfitDg__cv_item_head">
                            <span>{_.round(count, 3)}</span>
                            <span>{selectedPercent?.currencyCode_Symbols}</span>
                        </div>
                        <div className="ProfitDg__cv_item_info">
                            {label}
                        </div>
                    </div>
                    <div className="ProfitDg__cv_item" style={{height: `${second}%`}}>
                        {
                            isSame ?
                            <div className="ProfitDg__cv_item_head">
                                <span>{`+ ${_.round(profit, 3)}`}</span>
                                <span>{selectedPercent?.currencyCode_Symbols}</span>
                            </div>
                            :
                            <div className="ProfitDg__cv_item_head">
                                <span>{`+ ${_.round(Number(count) * selectedPercent?.reward  * selectedPercent?.countRewards, 3)}`}</span>
                                <span>{selectedPercent?.tokenReward?.currencyCode_Symbols}</span>
                            </div>
                        }
                        <div className="ProfitDg__cv_item_info">
                        You’ll get
                        </div>
                    </div>
                   
                </div>
                <div className="ProfitDg__info"></div>
            </div>
        )  
    }

    return (
        <div className="ProfitDg">
            <div className="ProfitDg__cv">
                <div className="ProfitDg__cv_item" style={{height: `${first}%`}}>
                    <div className="ProfitDg__cv_item_head">
                        <span>{_.round(count, 3)}</span>
                        <span>{selectedPercent?.currencyCode_Symbols}</span>
                    </div>
                    <div className="ProfitDg__cv_item_info">
                        {label}
                    </div>
                </div>
                <div className="ProfitDg__cv_item" style={{height: `${second}%`}}>
                        {
                            isSame ?
                            <div className="ProfitDg__cv_item_head">
                                <span>{`+ ${_.round(profit, 3)}`}</span>
                                <span>{selectedPercent?.currencyCode_Symbols}</span>
                            </div>
                            :
                            <div className="ProfitDg__cv_item_head">
                                <span>{`+ ${_.round(Number(count) * selectedPercent?.reward  * selectedPercent?.countRewards, 3)}`}</span>
                                <span>{selectedPercent?.tokenReward?.currencyCode_Symbols}</span>
                            </div>
                        }
                    <div className="ProfitDg__cv_item_info">
                    You’ll get
                    </div>
                </div>
            </div>
            <div className="ProfitDg__info"></div>
        </div>
    )
}

export default ProfitDg;