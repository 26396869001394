import './StepCn.scss';
import { Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import apiService from '../../../../service/apiService';
import { MoonLoader } from 'react-spinners';
import LoadConfirm from '../../../../components/LoadConfirm/LoadConfirm';
import ProfitDg from '../../../../components/ProfitDg/ProfitDg';
import * as _ from 'lodash';
import { useDispatch } from 'react-redux';
import { updateUserData } from '../../../../store/actions';
import { Twitter } from '../../../../icons/twitter';
import { Website } from '../../../../icons/website';
import { Trustline } from '../../../../icons/trustline';

const service = new apiService();


const StepCn = ({
    active,
    balance,
    selectedPercent,
    currentStep,
    count,
    declineProc,
    updateList,
    getStakePoolsFn
}) => {
    const dispatch = useDispatch();
    const {authData} = useSelector(state => state)
    const [load, setLoad] = useState(true)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')
    const [checkUuid, setCheckUuid] = useState('');

    const isSame = selectedPercent ? (selectedPercent.currencyCode_Symbols + selectedPercent.issuer) === (selectedPercent?.tokenReward?.currencyCode_Symbols + selectedPercent?.tokenReward?.issuer) : false 
   
    useEffect(() => {
        
        if(active && authData?.wallet && authData?.token && selectedPercent != null && currentStep == 3) {
            setLoad(true)
            service.newStake(authData?.wallet, authData?.token, selectedPercent.index, count).then(res => {
                if(res.error) {
                    setError(true)
                } else {
                    // updateList()
                    service.getUserData(authData?.wallet, authData?.token).then(res => {
                        dispatch(updateUserData(res))
                    }).finally(_ => {
                        setError(false)
                        setLoad(false)
                        setCheckUuid(res?.uuid)
                    })
                    
                    
                }
                setMessage(res.message)
                getStakePoolsFn()
            })
        }
    }, [active, authData, selectedPercent, currentStep, count])



    

    return (
        <div className={"StepCn" + (active ? ' active ' : '')}>
            {/* <h2 className="step__head nd-step__head">Amount</h2> */}
            <Row gutter={[20, 60]}>
                <Col span={24}>
                    {
                        error ? (
                            <div className="StepCn__error">
                                {message}
                            </div>   
                        ) : (
                            <LoadConfirm
                                load={load}
                                success={`${selectedPercent?.currencyCode_Symbols} token stake confirmed!`}
                                text={message}/>
                        )
                    }
                </Col>
                <Col span={24}>
                    <Row gutter={[20,50]}>
                        <Col md={12} span={24}>
                            <ProfitDg
                                stake={true}
                                selectedPercent={selectedPercent}
                                count={count}
                                active={active}
                                label={'You stake'}
                                isSame={isSame}
                            />
                        </Col>
                        {
                            selectedPercent ? (
                                <Col md={12} span={24}>
                                    <div className="StepPer__info">
                                        <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                            <div>
                                                <div className="StepPer__info_head">
                                                    {selectedPercent?.header}
                                                </div>
                                                <div className="StepPer__info_links">
                                                    <div className="StepPer__info_links_i">
                                                        <a rel="noreferrer" href={selectedPercent?.twitter} target="_blank" className='StepPer__info_links_item'>
                                                            <Twitter />
                                                        </a>
                                                    </div>
                                                    <div className="StepPer__info_links_i">
                                                        <a rel="noreferrer" href={selectedPercent?.webSite} target="_blank" className='StepPer__info_links_item'>
                                                            <Website />
                                                        </a>
                                                    </div>
                                                    <div className="StepPer__info_links_i">
                                                        <a rel="noreferrer" href={selectedPercent?.tl} target="_blank" className='StepPer__info_links_item'>
                                                            <Trustline />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isSame ?
                                                ''
                                                :
                                                <div>
                                                    <div className="StepPer__info_head">
                                                        {selectedPercent?.tokenReward?.header}
                                                    </div>
                                                    <div className="StepPer__info_links">
                                                        <div className="StepPer__info_links_i">
                                                            <a rel="noreferrer" href={selectedPercent?.tokenReward?.twitter} target="_blank" className='StepPer__info_links_item'>
                                                                <Twitter />
                                                            </a>
                                                        </div>
                                                        <div className="StepPer__info_links_i">
                                                            <a rel="noreferrer" href={selectedPercent?.tokenReward?.webSite} target="_blank" className='StepPer__info_links_item'>
                                                                <Website />
                                                            </a>
                                                        </div>
                                                        <div className="StepPer__info_links_i">
                                                            <a rel="noreferrer" href={selectedPercent?.tokenReward?.tl} target="_blank" className='StepPer__info_links_item'>
                                                                <Trustline />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="StepPer__info_body">
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Total Pool size:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent?.maxPool}
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Availible Pool size:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent?.nowPool}
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Minimum amount of staking:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent?.minStake}
                                                </span>
                                            </div>
                                            {
                                                isSame ?
                                                <div className="StepPer__info_body_item">
                                                    <span className="StepPer__info_body_item_name">
                                                        Stake Percentage Yeild:
                                                    </span>
                                                    <span className="StepPer__info_body_item_value">
                                                        {_.round(selectedPercent.reward * 100 * selectedPercent.countRewards)}%
                                                    </span>
                                                </div>
                                                :
                                                <div className="StepPer__info_body_item">
                                                    <span className="StepPer__info_body_item_name">
                                                        Staking ratio:
                                                    </span>
                                                    <span className="StepPer__info_body_item_value">
                                                        {Number(count)} / {_.round(Number(count) * selectedPercent.reward * selectedPercent.countRewards)}
                                                    </span>
                                                </div>
                                            }
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Reward period:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent.countRewards} days
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Daily reward:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {
                                                        isSame ?
                                                        `${_.round(selectedPercent.reward * 100, 3)}%`
                                                        :
                                                        `${selectedPercent.reward} ${selectedPercent?.tokenReward.currencyCode_Symbols}`
                                                    }
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Total Rewards:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {
                                                        isSame ?
                                                        `${_.round(selectedPercent.reward * 100 * selectedPercent.countRewards)}%`
                                                        :
                                                        _.round(Number(count) * selectedPercent.reward * selectedPercent.countRewards)
                                                    }
                                                </span>
                                            </div>
                                            {
                                                selectedPercent?.points?.stakeForOnePoint ? (
                                                    <div className="StepPer__info_body_item">
                                                        <span className="StepPer__info_body_item_name">
                                                            For 1 RLT point you can stake &nbsp;
                                                        </span>
                                                        <span className="StepPer__info_body_item_value">
                                                            {selectedPercent.points?.stakeForOnePoint} {selectedPercent.currencyCode_Symbols}
                                                        </span>
                                                    </div>
                                                ) : null
                                            }
                                            
                                        </div>
                                    </div>
                                </Col>
                            ) : null
                        }  
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default StepCn;