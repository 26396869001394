import './icon.scss';


const WalletIcon = ({color = 'var(--gray1)', size = 25}) => {
    return (
        <div className="WalletIcon icon" style={{width: size, height: size}}>
            <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M22.9229 2H4C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H22.9229C24.0275 22 24.9229 21.1046 24.9229 20V14H16.5C15.6716 14 15 13.3284 15 12.5C15 11.6716 15.6716 11 16.5 11H24.9229V4C24.9229 2.89543 24.0275 2 22.9229 2ZM26.9229 11.0604V4C26.9229 1.79086 25.1321 0 22.9229 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H22.9229C25.1321 24 26.9229 22.2091 26.9229 20V13.9396C27.5454 13.757 28 13.1816 28 12.5C28 11.8184 27.5454 11.243 26.9229 11.0604ZM13 12.5C13 13.3284 12.3284 14 11.5 14C10.6716 14 10 13.3284 10 12.5C10 11.6716 10.6716 11 11.5 11C12.3284 11 13 11.6716 13 12.5Z" fill={color}/>
            </svg>

        </div>
    )
}

export default WalletIcon;