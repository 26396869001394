import './NoSnapshot.scss';
import Button from '../../../../components/Button/Button';

const NoSnapshot = ({createSnapshot, uploadSnapshot}) => {



    return (
        <div className="NoSnapshot">
            <div className="NoSnapshot__label">No SnapShots</div>
            <div className="NoSnapshot__action">
                <div className="NoSnapshot__action_item">
                    <Button
                    text={'Create SnapShot'}
                    onClick={createSnapshot}
                    variant={'default'}
                    /> 
                </div>
                <div className="NoSnapshot__action_item">
                    <Button
                    text={'Upload SnapShot'}
                    onClick={uploadSnapshot}
                    variant={'default'}
                    /> 
                </div>
            </div>
        </div>
    )
}

export default NoSnapshot;