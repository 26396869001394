import dropsMock from "../../helpers/dropsMock";
import AirDropItem from "../AirDropItem/AirDropItem";
import CancelDrop from "../../modals/CancelDrop/CancelDrop";
import { useState } from "react";
import ProcessItem from "../AirDropItem/ProcessItem";
import AirDropEmpty from "../AirDropEmpty/AirDropEmpty";
import { useSelector } from "react-redux";
import apiService from "../../../../service/apiService";


const service = new apiService()

const ProcessTab = ({open, list, updateList}) => {
    const [cancelDropModal, setCancelDropModal] = useState(false);
    const {authData} = useSelector(state => state)

    const openCancelDropModal = () => {
        setCancelDropModal(true)
    }
    const closeCancelDropModal = () => {
        setCancelDropModal(false)
    }



    const pauseDrop = (index_drop) => {
        service.deleteAirdrop(authData.wallet, authData.token, index_drop).then(res => {
            if(res == 200) {
                updateList()
            } else {
               
            }
        })
    }

    const continueDrop = (item) => {
        const body = {
            index_drop: item.index_drop,
            label: item.label,
            procentTokens: item.ProcentTokens,
            countTokens: item.CountTokens,
            address: item.Address,
            secret: item.Secret,
            issuer: item.Issuer,
            asset: item.Asset,
            memo: item.Memo,
            onlyKyc: item.onlyKyc,
            // onlyTl: ,
            onlyIssuer: item.onlyIssuer,
            snapshotIndex: item.snapshotIndex,
            timeStart: item.timeStart,
            discountName: "",
            blackList: null,
            firstReciever: item.LastReciever,
            old_index_drop: Number(item.index_drop)
        }

        
        
        service.createAirdrop(authData?.wallet, authData?.token, body).then(res => {
            updateList()
        })
    }


    if(open) {
        return (
            <>
                <CancelDrop visible={cancelDropModal} close={closeCancelDropModal}/>
                {
                    list && list.length > 0 ? (
                        list.map((item, index) => (
                            <div className="AirDrop__tabs_content_item" key={index}>
                                <ProcessItem 
                                    {...item}
                                    pauseDrop={pauseDrop}
                                    continueDrop={() => continueDrop(item)}
                                    onCancelDrop={openCancelDropModal}
                                    paused={item.Status == 2 && (item.CountSends != item.TotalTotalRecipients)}
                                    // paused={index == 1 ? true : false}
                                    />
                            </div>
                        ))
                    ) : <AirDropEmpty/>
                    
                }
            </>
        )
    }
}

export default ProcessTab;