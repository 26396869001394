const LOCAL_STORAGE = window.localStorage;

const initState = {
    authData: {
        token: LOCAL_STORAGE.getItem('xumm-adtools-user-token') ? LOCAL_STORAGE.getItem('xumm-adtools-user-token') : null,
        uuid: LOCAL_STORAGE.getItem('xumm-adtools-user-uuid') ? LOCAL_STORAGE.getItem('xumm-adtools-user-uuid') : null,
        wallet: LOCAL_STORAGE.getItem('xumm-adtools-user-wallet') ? LOCAL_STORAGE.getItem('xumm-adtools-user-wallet') : null
    },
    userData: {},
    menuState: '0',
    token: LOCAL_STORAGE.getItem('xumm-adtools-user-token') ? LOCAL_STORAGE.getItem('xumm-adtools-user-token') : null,
    theme: LOCAL_STORAGE.getItem('xumm-adtools-theme') ? LOCAL_STORAGE.getItem('xumm-adtools-theme') : 'dark',
    lines: [],
    xrp: null,
    tokensInOffer: [],
    userCarats: 0,
    userPower: 0,
}

const reducer = (state = initState, action) => {
    switch(action.type) {
        case 'USER_AUTH':
            return {
                ...state,
                authData: action.data
            }
        case 'USER_DATA':
            return {
                ...state,
                userData: action.data
            }
        case 'MENU_STATE':
            return {
                ...state,
                menuState: state.menuState == '1' ? '0' : '1'
            }
        case 'TOKEN':
            return {
                ...state,
                token: action.token
            }
        case 'THEME':
            return {
                ...state,
                theme: action.theme
            }
        case 'LINES':
            return {
                ...state,
                lines: action.lines
            }
        case 'XRP':
            const lockedBalance = action.data.account_data.OwnerCount * 2 + 10
            return {
                ...state,
                xrp: {
                    ...action.data,
                    lockedBalance, 
                    freeXrpBalance:  action.data.account_data.Balance * 0.000001 - lockedBalance
                }
            }
        case 'TOKENS':
            return {
                ...state,
                tokensInOffer: action.tokens
            }
        case 'CARATS':
            return {
                ...state,
                userCarats: action.userCarats
            }
        case 'POWER':
            return {
                ...state,
                userPower: action.userPower
            }
        default:
            return state;
    }
}

export default reducer;