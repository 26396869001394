import './AirDropEmpty.scss';
import Button from '../../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';


const AirDropEmpty = () => {
    const nav = useNavigate()


    return (
        <div className="AirDropEmpty">
            <div className="AirDropEmpty__label">No one event yet</div>
            <div className="AirDropEmpty__action">
                <Button
                    onClick={() => nav('/airdrop/new-drop')}
                    text={'Create new AirDrop'}
                    variant={'default'}
                    />
            </div>
        </div>
    )
}

export default AirDropEmpty;