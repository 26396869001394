import './StepAm.scss';
import { Row, Col } from 'antd';
import Radio from '../../../../components/Radio/Radio';
import { useState, useEffect } from 'react';
import * as _ from 'lodash';
import Input from '../../../../components/Input/Input';
import { useSelector } from 'react-redux';
import compareBalance, { getStaked } from '../../../../funcs/compareBalance';
import { Twitter } from '../../../../icons/twitter';
import { Website } from '../../../../icons/website';
import { Trustline } from '../../../../icons/trustline';

const StepAm = ({
    active,
    balance,
    poolList,
    setCount,
    count,
    selectedPercent,
    processList,
    youCanStake,
    pointPrice,
    topNft,
    setNextDisable
}) => {
    const [list, setList] = useState([])
    const {userData, tokensInOffer} = useSelector(state => state)
    const [ys, setYs] = useState(0);
    const [staked, setStaked] = useState('')

    const tokensInOfferCurrent = tokensInOffer.find(e => e.issuer === selectedPercent?.issuer)

    const isXrp = selectedPercent?.currencyCode_Symbols === "XRP" || selectedPercent?.issuer === "XRP";
    const perPoint = _.round(selectedPercent?.points?.stakeForOnePoint, 4);
    const perPointTr = ((perPoint > ys) || ((topNft === 'inf') && (!isXrp))) ? ys : perPoint

    const rtlPoints = userData?.Points;
    const needPoints = _.round(Number(count) / Number(selectedPercent?.points?.stakeForOnePoint), 3)

    useEffect(() => {
       if (balance) {
        setYs(compareBalance(balance, userData))
        setStaked(getStaked(balance, userData))
       }
    }, [selectedPercent, balance, userData])

    const isSame = selectedPercent ? (selectedPercent.currencyCode_Symbols + selectedPercent.issuer) === (selectedPercent?.tokenReward?.currencyCode_Symbols + selectedPercent?.tokenReward?.issuer) : false 

    useEffect(() => {
        if(poolList?.length > 0) {
            setList(poolList.filter(item => item.currencyCode_Symbols == balance?.currency).filter(item => item.nowPool != 0 && item.nowPool > item.minBurn))
        }
    }, [poolList, balance])

    useEffect(() => {
        if (!count) {
            setNextDisable(true)
        }
        if ((Number(count) <= ys) && (Number(count) >= selectedPercent?.minStake) && (Number(count) <= selectedPercent?.nowPool)) {
            if (topNft === 'inf' && !isXrp) {
                setNextDisable(false)
            } else if (needPoints <= rtlPoints) {
                setNextDisable(false)
            }
        } else {
            setNextDisable(true)
        }
    }, [count, selectedPercent, ys, active]);

    return (
        <div className={"StepAm" + (active ? ' active ' : '')}>
            <h2 className="step__head nd-step__head">Amount</h2>
            <Row gutter={[0, 40]}>
                <Col span={24}>
                    <div className="StepAm__head">
                        You have {_.round(balance?.balance, 4)} {balance?.currency}
                        {staked ? <div>Total staked {staked} {balance?.currency}</div> : ''}
                        {tokensInOfferCurrent?.value ? <div>In offers {tokensInOfferCurrent.value} {balance?.currency}</div> : ''}
                        <div>You can stake no more than {tokensInOfferCurrent ? ((_.round(ys, 4) - tokensInOfferCurrent.value) < 0 ? 0 : (_.round(ys, 4) - tokensInOfferCurrent.value)) : _.round(ys, 4)} {balance?.currency}</div>
                    </div>
                    
                </Col>
                <Col span={24}>
                    <Row gutter={[20,20]}>
                        <Col md={12} span={24}>
                            <div className="StepAm__count">
                                <div className="StepAm__count_label">
                                    Write amount of {balance?.currency} you want to stake
                                </div>
                                <div className="StepAm__count_input">
                                    <Input  
                                        mask={Number}
                                        value={count}
                                        onChange={e => setCount(e)}
                                        placeholder={''}
                                        />
                                </div>
                                {
                                    selectedPercent?.points?.stakeForOnePoint && count && ((topNft != 'inf') || isXrp) ? (
                                        <div className="StepAm__count_label" style={{marginTop: 10}}>
                                            To stake {count} {selectedPercent?.currencyCode_Symbols}, you need {needPoints} RLT points.
                                        </div>
                                    ) : null
                                }
                            </div>
                        </Col>
                        {
                            selectedPercent ? (
                                <Col md={12} span={24}>
                                    <div className="StepPer__info">
                                        <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                            <div>
                                                <div className="StepPer__info_head">
                                                    {selectedPercent?.header}
                                                </div>
                                                <div className="StepPer__info_links">
                                                    <div className="StepPer__info_links_i">
                                                        <a rel="noreferrer" href={selectedPercent?.twitter} target="_blank" className='StepPer__info_links_item'>
                                                            <Twitter />
                                                        </a>
                                                    </div>
                                                    <div className="StepPer__info_links_i">
                                                        <a rel="noreferrer" href={selectedPercent?.webSite} target="_blank" className='StepPer__info_links_item'>
                                                            <Website />
                                                        </a>
                                                    </div>
                                                    <div className="StepPer__info_links_i">
                                                        <a rel="noreferrer" href={selectedPercent?.tl} target="_blank" className='StepPer__info_links_item'>
                                                            <Trustline />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isSame ?
                                                ''
                                                :
                                                <div>
                                                    <div className="StepPer__info_head">
                                                        {selectedPercent?.tokenReward?.header}
                                                    </div>
                                                    <div className="StepPer__info_links">
                                                        <div className="StepPer__info_links_i">
                                                            <a rel="noreferrer" href={selectedPercent?.tokenReward?.twitter} target="_blank" className='StepPer__info_links_item'>
                                                                <Twitter />
                                                            </a>
                                                        </div>
                                                        <div className="StepPer__info_links_i">
                                                            <a rel="noreferrer" href={selectedPercent?.tokenReward?.webSite} target="_blank" className='StepPer__info_links_item'>
                                                                <Website />
                                                            </a>
                                                        </div>
                                                        <div className="StepPer__info_links_i">
                                                            <a rel="noreferrer" href={selectedPercent?.tokenReward?.tl} target="_blank" className='StepPer__info_links_item'>
                                                                <Trustline />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="StepPer__info_body">
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Total Pool size:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent?.maxPool}
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Availible Pool size:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent?.nowPool}
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Minimum amount of staking:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent?.minStake}
                                                </span>
                                            </div>
                                            {
                                                isSame ?
                                                <div className="StepPer__info_body_item">
                                                    <span className="StepPer__info_body_item_name">
                                                        Stake Percentage Yeild:
                                                    </span>
                                                    <span className="StepPer__info_body_item_value">
                                                        {_.round(selectedPercent.reward * 100 * selectedPercent.countRewards)}%
                                                    </span>
                                                </div>
                                                :
                                                <div className="StepPer__info_body_item">
                                                    <span className="StepPer__info_body_item_name">
                                                        Staking ratio:
                                                    </span>
                                                    <span className="StepPer__info_body_item_value">
                                                        {Number(count)} / {_.round(Number(count) * selectedPercent.reward * selectedPercent.countRewards)}
                                                    </span>
                                                </div>
                                            }
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Reward period:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent.countRewards} days
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Daily reward:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {
                                                        isSame ?
                                                        `${_.round(selectedPercent.reward * 100, 3)}%`
                                                        :
                                                        `${selectedPercent.reward} ${selectedPercent?.tokenReward.currencyCode_Symbols}`
                                                    }
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Total Rewards:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {
                                                        isSame ?
                                                        `${_.round(selectedPercent.reward * 100 * selectedPercent.countRewards)}%`
                                                        :
                                                        _.round(Number(count) * selectedPercent.reward * selectedPercent.countRewards)
                                                    }
                                                </span>
                                            </div>
                                            {
                                                selectedPercent?.points?.stakeForOnePoint && ((topNft !== 'inf') || isXrp) ? (
                                                    <div className="StepPer__info_body_item">
                                                        <span className="StepPer__info_body_item_name">
                                                            For 1 RLT point you can stake &nbsp;
                                                        </span>
                                                        <span className="StepPer__info_body_item_value">
                                                            {perPointTr} {selectedPercent.currencyCode_Symbols}
                                                        </span>
                                                    </div>
                                                ) : null
                                            }
                                            
                                        </div>
                                    </div>
                                </Col>
                            ) : null
                        }  
                    </Row>    
                </Col> 
            </Row>
        </div>  
    )
}

export default StepAm;