import './TokenItem.scss';
import Radio from '../../../Radio/Radio';
import { Row, Col } from 'antd';
import { useState } from 'react';

const TokenItem = (props) => {
    const {
        currencyCode_Symbols,
        selectPool,
        selectedPool,
        index,
        balance,
        logo,
        setSelectedPercent,
        priority,
        needCarats,
        needPower
    } = props

    const [isHoverCarats, setHoverCarats] = useState(false);
    const [isHoverPower, setHoverPower] = useState(false);

    return (
        <div className={"TokenItem" + (!balance  || balance?.balance == '0' ? ' not-disabled' : '') + (priority ? ' TokenItem_priority' : '')} onClick={() => {
            setSelectedPercent(null)
            if(selectedPool?.currency == currencyCode_Symbols) {
                selectPool(null)
            } else {
                selectPool(balance)
            }
        }}>
            <Row gutter={[0,0]}>
                <Col span={12}>
                    <div className="TokenItem__main">
                        <div className="TokenItem__main_action">
                            <Radio
                                id={index}
                                checked={selectedPool?.currency == currencyCode_Symbols}
                                />
                        </div>
                        <div className="TokenItem__main_info">
                            <div className={"TokenItem__main_info_icon" + (logo ? '' : ' empty ')}>
                                {
                                    logo ? (
                                        <img src={logo} alt={currencyCode_Symbols} />
                                    ) : null
                                }
                            </div>
                            <div className="TokenItem__main_info_name">
                                {currencyCode_Symbols}
                                <div className='TokenItem__icons'>
                                    {
                                        priority ? 
                                        <div className='TokenItem__icons-new'>
                                            <span>New!</span>
                                        </div> : ''
                                    }
                                    {
                                        needPower > 0 ?
                                        <span 
                                            className='TokenItem__icons-power'
                                            onMouseEnter={() => setHoverPower(true)}
                                            onMouseLeave={() => setHoverPower(false)}
                                        >
                                            Power
                                            {
                                                isHoverPower ? 
                                                <span className='TokenItem__icons-tip'>
                                                    To use this pool, you must have {'>'}= {needPower} Power 
                                                </span> : ''
                                            }
                                        </span> : ''
                                    }
                                    {
                                        needCarats > 0 ?
                                        <span 
                                            className='TokenItem__icons-karat'
                                            onMouseEnter={() => setHoverCarats(true)}
                                            onMouseLeave={() => setHoverCarats(false)}
                                        >
                                            Karat
                                            {
                                                isHoverCarats ? 
                                                <span className='TokenItem__icons-tip'>
                                                    To use this pool, you must have {'>'}= {needCarats} Karats 
                                                </span> : ''
                                            }
                                        </span> : ''
                                    }
                                </div>
                                {/* {
                                    priority ?
                                    <div className='TokenItem__new'>
                                        <span>New!</span>
                                    </div>
                                    :
                                    ''
                                } */}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="TokenItem__balance">
                        {
                            balance?.balance ? balance?.balance : 0
                        } 
                        &nbsp;
                        {
                            balance?.currency ? balance?.currency : currencyCode_Symbols
                        }
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default TokenItem;