import BurnHistItem from "../../components/BurnHistItem/BurnHistItem";
import BurnProcItem from "../../components/BurnProcItem/BurnProcItem";


const BurnHistory = ({active, list}) => {
    if(active) {
        return (
            <>
                {
                    list?.length > 0 ? (
                        list.map((item, index) => (
                            <BurnProcItem key={index} complete={true} {...item}/>
                        ))
                    ) : null
                }
            </>
        ) 
    }
    return null
}

export default BurnHistory;