import './RlPag.scss';
import { Pagination } from 'antd';

const RlPag = ({
    pages, 
    total,
    currentPage,
    onChange
}) => {


    return (
        <div className="RlPag">
            <Pagination
                total={total}
                current={currentPage + 1}
                showQuickJumper={true}
                showSizeChanger={false}
                pageSize={1}
                onChange={onChange}
                />
        </div>
    )

}

export default RlPag;