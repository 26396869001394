import './NotItem.scss';
import { Link } from 'react-router-dom';


const NotItem = ({
    date,
    text,
    danger,
    link
}) => {
    return (
        <div className="NotItem">
            <div className="NotItem__date">{date}</div>
            <div className="NotItem__text" style={{color: danger ? 'var(--red2)' : 'var(--gray3)'}}>{text}</div>
            <div className="NotItem__link">
                <Link to="/">see details</Link>
            </div>
        </div>
    )
}

export default NotItem;