import './TimeA.scss';
import { TimePicker } from 'antd';

const TimeA = ({onChange, value}) => {
    return (
        <TimePicker
            onChange={onChange}
            value={value}
            className="TimeA"
            />
    )
}

export default TimeA;