import './Stake.scss';
import Header from '../../components/Header/Header';
import Tabs from '../../components/Tabs/Tabs';
import { useState, useEffect } from 'react';
import MainCalendar from '../../components/MainCalendar/MainCalendar';
import StepLine from '../../components/StepLine/StepLine';
import { useSelector } from 'react-redux';
import apiService from '../../service/apiService';
import BurnCreate from './create/BurnCreate/BurnCreate';
import BurnProc from './create/BurnProc/BurnProc';
import BurnHistory from './create/BurnHistory/BurnHistory';
import StatModal from '../../components/StatModal/StatModal';
import SbInfo from '../../components/SbInfo/SbInfo';



const service = new apiService();

const stakeTabs = ['Staking', 'In process', 'History']


const Stake = () => {
    const {userData, authData} = useSelector(state => state)
    

    const [stat, setStat] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const [tokenList, setTokenList] = useState([])
    const [reset, setReset] = useState(false)
    const [sbInfo, setSbInfo] = useState(null);
    const [historyList, setHistoryList] = useState([])
    const [processList, setProcessList] = useState([])

    const updateList = () => {
        setHistoryList(userData.Staked.filter(item => item.remainingReward == 0).reverse())
        setProcessList(userData.Staked.filter(item => item.remainingReward > 0).reverse())
    }   

    useEffect(() => {
      
        if(userData?.Staked) {
            updateList()
        }
    }, [userData])

    const openStat = () => setStat(true)
    const closeStat = () => setStat(false)


    return (
        <div className="Stake page"> 
            <StatModal
                visible={stat}
                close={closeStat}
                type={'staked'}
                />
            <div className="page__body">
                <Header/>
                <div className="page__body_in">
                    <div className="content">
                        <div className="Burn__tabs">
                            <div className="Burn__tabs_head">
                                <Tabs
                                    decline={setReset}
                                    tabs={stakeTabs}
                                    activeIndex={activeTab}
                                    onSelect={setActiveTab}
                                    />
                            </div>
                            <div className="Burn__tabs_content">
                                {
                                    activeTab == 1 || activeTab == 2 ? (
                                        <div className="Burn__get">
                                            <button onClick={openStat} className="Burn__get_stat">Get statistics</button>
                                        </div>
                                    ) : null
                                }
                                <BurnCreate processList={processList} reset={reset} setReset={setReset} updateList={updateList} active={activeTab == 0}/>
                                <BurnProc list={processList} active={activeTab == 1}/>
                                <BurnHistory list={historyList} active={activeTab == 2}/>
                            </div>
                        </div>
                    </div>
                    <div className="cd">
                        <MainCalendar/>
                        <SbInfo
                            data={sbInfo}
                            />
                    </div>
                </div>
            </div>
        </div>  
    )
}

export default Stake;