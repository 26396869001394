import './StepCn.scss';
import { Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import apiService from '../../../../service/apiService';
import { MoonLoader } from 'react-spinners';
import LoadConfirm from '../../../../components/LoadConfirm/LoadConfirm';
import ProfitDg from '../../../../components/ProfitDg/ProfitDg';
import * as _ from 'lodash';
import { useDispatch } from 'react-redux';
import { updateUserData } from '../../../../store/actions';
import { Twitter } from '../../../../icons/twitter';
import { Website } from '../../../../icons/website';
import { Trustline } from '../../../../icons/trustline';

const service = new apiService();


const StepCn = ({
    active,
    balance,
    selectedPercent,
    currentStep,
    count,
    declineProc,
    updateList,
    setStepChange,
    topNft,
    pointsMultiplier,
    pointPrice
}) => {
    const dispatch = useDispatch();
    const authData = useSelector(state => state.authData)
    const [load, setLoad] = useState(true)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')
    const [checkUuid, setCheckUuid] = useState('');
    const [success, setSuccess] = useState('')

    const isSame = selectedPercent ? (selectedPercent.currencyCode_Symbols + selectedPercent.issuer) === (selectedPercent?.tokenReward?.currencyCode_Symbols + selectedPercent?.tokenReward?.issuer) : false 
   
    useEffect(() => {
        if(active && authData?.wallet && authData?.token && selectedPercent != null && currentStep == 3) {
            setLoad(true)
            service.newBurn(authData?.wallet, authData?.token, selectedPercent.index, count).then(res => {
     
                if(res.error) {
                    setError(true)
                } else {
                    setError(false)
                    setCheckUuid(res.uuid)
                }
                setMessage(res.message)
            })
          
      
        }
    }, [active, authData, selectedPercent, currentStep, count])


    useEffect(() => {
        if(error) {
            setError('')
            setMessage('')
        }
    }, [currentStep])

    useEffect(() => {
        let int;
        if(checkUuid) {
            int = setInterval(() => {
                service.checkBurnTrans(checkUuid).then(res => {
                    if(res === false) {
                        // declineProc()
                        setLoad(false)
                        setSuccess('Transaction has been cancelled')
                        
                    } 
                    if(res === true) {
                        setSuccess(`${selectedPercent?.currencyCode_Symbols} token burn confirmed!`)
                        service.getUserData(authData?.wallet, authData?.token).then(r => {
                            dispatch(updateUserData(r))
                        }).finally(_ => {
                            setLoad(false)
                        })
                    }
                    clearInterval(int)
                })
            }, 3000)
        }

        return () => {
            if(int) {
                clearInterval(int)
            }
        }
    }, [checkUuid])

    return (
        <div className={"StepCn" + (active ? ' active ' : '')}>
            <h2 className="Step__head nd-step__head">Confirm</h2>
            <Row gutter={[20, 60]}>
                <Col span={24}>
                    {
                        error ? (
                            <div className="StepCn__error">
                                {message}
                            </div>   
                        ) : (
                            <LoadConfirm
                                load={load}
                                success={success}
                                text={message}
                                checkUuid={checkUuid}
                            />
                        )
                    }
                </Col>
                <Col span={24}>
                    <Row gutter={[20,50]}>
                        <Col md={12} span={24}>
                            <ProfitDg
                                selectedPercent={selectedPercent}
                                count={count}
                                active={active}
                                label={'You burn'}
                                isSame={isSame}
                            />
                        </Col>
                        {
                            selectedPercent ? (
                                <Col md={12} span={24}>
                                    <div className="StepPer__info">
                                        <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                            <div>
                                                <div className="StepPer__info_head">
                                                    {selectedPercent?.header}
                                                </div>
                                                <div className="StepPer__info_links">
                                                    <div className="StepPer__info_links_i">
                                                        <a rel="noreferrer" href={selectedPercent?.twitter} target="_blank" className='StepPer__info_links_item'>
                                                            <Twitter />
                                                        </a>
                                                    </div>
                                                    <div className="StepPer__info_links_i">
                                                        <a rel="noreferrer" href={selectedPercent?.webSite} target="_blank" className='StepPer__info_links_item'>
                                                            <Website />
                                                        </a>
                                                    </div>
                                                    <div className="StepPer__info_links_i">
                                                        <a rel="noreferrer" href={selectedPercent?.tl} target="_blank" className='StepPer__info_links_item'>
                                                            <Trustline />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isSame ?
                                                ''
                                                :
                                                <div>
                                                    <div className="StepPer__info_head">
                                                        {selectedPercent?.tokenReward?.header}
                                                    </div>
                                                    <div className="StepPer__info_links">
                                                        <div className="StepPer__info_links_i">
                                                            <a rel="noreferrer" href={selectedPercent?.tokenReward?.twitter} target="_blank" className='StepPer__info_links_item'>
                                                                <Twitter />
                                                            </a>
                                                        </div>
                                                        <div className="StepPer__info_links_i">
                                                            <a rel="noreferrer" href={selectedPercent?.tokenReward?.webSite} target="_blank" className='StepPer__info_links_item'>
                                                                <Website />
                                                            </a>
                                                        </div>
                                                        <div className="StepPer__info_links_i">
                                                            <a rel="noreferrer" href={selectedPercent?.tokenReward?.tl} target="_blank" className='StepPer__info_links_item'>
                                                                <Trustline />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="StepPer__info_body">
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Total Pool size:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent?.maxPool}
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Availible Pool size:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent?.nowPool}
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Minimum amount of burning:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent?.minBurn}
                                                </span>
                                            </div>
                                            {
                                                isSame ?
                                                <div className="StepPer__info_body_item">
                                                    <span className="StepPer__info_body_item_name">
                                                        Burn Percentage Yeild:
                                                    </span>
                                                    <span className="StepPer__info_body_item_value">
                                                        {_.round(selectedPercent.reward * 100 * selectedPercent.countRewards - 100)}%
                                                    </span>
                                                </div>
                                                :
                                                <div className="StepPer__info_body_item">
                                                    <span className="StepPer__info_body_item_name">
                                                        Burn ratio:
                                                    </span>
                                                    <span className="StepPer__info_body_item_value">
                                                        {Number(count)} / {_.round(Number(count) * selectedPercent.reward * selectedPercent.countRewards)}
                                                    </span>
                                                </div>
                                            }
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Reward period:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent.countRewards} days
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Daily reward:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {
                                                        isSame ?
                                                        `${_.round(selectedPercent.reward * 100, 3)}%`
                                                        :
                                                        `${selectedPercent.reward} ${selectedPercent?.tokenReward.currencyCode_Symbols}`
                                                    }
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Total Rewards:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {
                                                        isSame ?
                                                        `${_.round(selectedPercent.reward * 100 * selectedPercent.countRewards)}%`
                                                        :
                                                        _.round(Number(count) * selectedPercent.reward * selectedPercent.countRewards)
                                                    }
                                                </span>
                                            </div>
                                            {
                                                ((selectedPercent?.currencyCode_Symbols == 'RLT') && (selectedPercent?.tokenReward?.currencyCode_Symbols === 'RLT')) ? (
                                                        pointPrice && count ? (
                                                            <div className="StepPer__info_body_item" style={{marginTop: 10, marginBottom: 10}}>
                                                                <span className="StepPer__info_body_item_name">
                                                                For burn {count} {selectedPercent?.currencyCode_Symbols}, you receive {_.round((Number(count) / Number(pointPrice)) * Number(pointsMultiplier) * Number(selectedPercent?.pointIndex),3)} RLT points.
                                                                </span>
                                                            </div>
                                                        ) : null
                                                ) : (
                                                    topNft == 'inf' ? (
                                                        null
                                                    ) : (
                                                        selectedPercent?.points?.burnForOnePoint && count ? (
                                                            <div className="StepPer__info_body_item" style={{marginTop: 10, marginBottom: 10}}>
                                                                <span className="StepPer__info_body_item_name">
                                                                For burn {count} {selectedPercent?.currencyCode_Symbols}, you need {_.round(Number(count) / Number(selectedPercent?.points?.burnForOnePoint), 3)} RLT points.
                                                                </span>
                                                            </div>
                                                        ) : null
                                                    )
                                                    
                                                )
                                            }
                                            {/* {
                                                topNft == 'inf' ? (
                                                    null
                                                ) : (
                                                    <>
                                                        {
                                                            count && pointPrice && selectedPercent?.currencyCode_Symbols == 'RLT' ? (
                                                                <div className="StepPer__info_body_item">
                                                                    <span className="StepPer__info_body_item_name">
                                                                    For burning {count} RLT you will receive  {_.round((count / pointPrice) * topNft, 3)} RLT points
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                null
                                                            )   
                                                        }
                                                        {
                                                            count && pointPrice && selectedPercent?.currencyCode_Symbols != 'RLT' ? (
                                                                <div className="StepPer__info_body_item">
                                                                    <span className="StepPer__info_body_item_name">
                                                                        For 1 RLT point you can burn &nbsp;
                                                                    </span>
                                                                    <span className="StepPer__info_body_item_value">
                                                                        {selectedPercent.points?.burnForOnePoint} {selectedPercent.currencyCode_Symbols}
                                                                    </span>
                                                                </div>
                                                            ) : null
                                                        }
                                                    </>
                                                                                                    
                                                )
                                            } */}
                                        </div>
                                    </div>
                                </Col>
                            ) : null
                        }  
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default StepCn;