import './AirDrop.scss';
import Header from '../../components/Header/Header';
import { useEffect, useState } from 'react';
import AirDropTab from './components/AirDropTab/AirDropTab';
import HistoryTab from './components/HistoryTab/HistoryTab';
import ProcessTab from './components/ProcessTab/ProcessTab';
import MainCalendar from '../../components/MainCalendar/MainCalendar';
import apiService from '../../service/apiService';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserData } from '../../store/actions';
import SbInfo from '../../components/SbInfo/SbInfo';

const tabs = [
    {
        head: 'AirDrop',
    },
    {
        head: 'In process'
    },
    {
        head: 'History'
    }
]


const service = new apiService()

const AirDrop = () => {
    const dispatch = useDispatch();
    const {authData} = useSelector(state => state)
    const [activeTab, setActiveTab] = useState(0)
    const [dropList, setDropList] = useState([])
    const [dropProcess, setDropProcess] = useState([])
    const [dropHistory, setDropHistory] = useState([])
    const [changeDrop, setChangeDrop] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false);

    const selectTab = (index) => {
        setActiveTab(index)
    }

    const openChangeDrop = () => {
        setChangeDrop(true)
    }
    const closeChangeDrop = () => {
        setChangeDrop(false)
    }

    const updateList = () => {
        service.getUserData(authData.wallet, authData.token).then(res => {
            dispatch(updateUserData(res))
            const drops = res.Drops;
                setDropList(drops.filter(item => item.Status == 0).reverse())
                setDropProcess(drops.filter(item => item.Status == 1).reverse())
                setDropHistory(drops.filter(item => item.Status == 2).reverse())
            })
    }

    useEffect(() => {
        (async function(){
            if(authData?.token && authData?.wallet && !isLoaded) {
                await updateList();
                setIsLoaded(true);
            }
        }())
    }, [authData])
    

    return (
        <div className="page AirDrop">
            
            <div className="page__body">
                <Header/>
                {/* <ChangeDrop visible={changeDrop} close={closeChangeDrop}/> */}
                <div className="page__body_in">
                    <div className="content">
                        <div className="AirDrop__tabs">
                            <div className="AirDrop__tabs_head">
                                {
                                    tabs.map((item, index) => (
                                        <div onClick={() => selectTab(index)} key={index} className={"AirDrop__tabs_item" + (activeTab == index ? ' active ' : '')}>{item.head}</div>
                                    ))
                                }
                            </div>
                            <div className="AirDrop__tabs_content">
                                <>
                                    <AirDropTab 
                                        updateList={updateList}
                                        change={openChangeDrop} 
                                        list={dropList}
                                        open={activeTab == '0'}/>

                                    <ProcessTab 
                                        updateList={updateList}
                                        list={dropProcess}
                                        open={activeTab == '1'}/>

                                    <HistoryTab 
                                        updateList={updateList}
                                        list={dropHistory}
                                        open={activeTab == '2'}/>
                                </>
    
                            </div>
                        </div>
                    </div>
                    <div className="cd">
                        <MainCalendar/>
                        <SbInfo/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AirDrop;