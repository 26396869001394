import './RichSearch.scss';
import {Modal} from 'antd';
import { useState, useEffect } from 'react';
import Input from '../../../../components/Input/Input';
import ResultItem from './components/ResultItem/ResultItem';
import resultMock from './resultMock';
import ResultPlaceholder from './components/ResultPlaceholder/ResultPlaceholder';
import {FaRegSadTear} from 'react-icons/fa';
import {BsSearch} from 'react-icons/bs';
import { useSelector } from 'react-redux';
import apiService from '../../../../service/apiService';
import coin from '../../../../assets/coin.png';
import { DefLoader } from '../../../../components/Loaders';
import { useNavigate } from 'react-router-dom';
const service = new apiService();

const NotResult = () => {
    return (
        <ResultPlaceholder
            text={'Sorry, nothing found'}
            icon={<FaRegSadTear/>}
            />
    )
}

const StartSearch = () => {
    return (
        <ResultPlaceholder
            text={'Type something'}
            icon={<BsSearch/>}
            />
    )
}

const EmptySearch = () => {
    return (
        <ResultPlaceholder
            text={'Nothing found'}
            />
    )
}


const RichSearch = ({visible, close, setCoin}) => {
    const {authData} = useSelector(state => state)
    const [list, setList] = useState(resultMock)
    const [searched, setSearched] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [fetch, setFetch] = useState(false)
    const nav = useNavigate();
   

    const closeHandle = () => {
        close()
    }



    useEffect(() => {
        service.getPairs().then(res => {
            setList(res.pairs)
            setSearched(res.pairs)
        })
    }, [])

    useEffect(() => {
        if(searchValue != '' && list?.length > 0) {
            setSearched(state => {
                const ll = list;
                return ll.filter(item => item?.base?.currency.toUpperCase().includes(searchValue.toUpperCase()))  
            })
        }
    }, [searchValue, list])


    const selectCoin = (item) => {
        nav(`/richlist/${item.base.currency}/${item.base.issuer}`)
        setSearched(list)
        // setCoin(item)
        closeHandle()
    }


    return (
        <Modal 
            className='modal RichSearch' 
            width={485} 
            onCancel={closeHandle} 
            open={visible}
            >
            <div className="modal__body RichSearch__body">
                <div className="RichSearch__body_search">
                    <Input
                        value={searchValue}
                        onChange={e => setSearchValue(e.target.value)}
                        placeholder={'Search by coin number or ticker'}
                        />
                </div>
                <div className="RichSearch__body_list">
                    {
                        fetch ? (
                            <DefLoader/>
                        ) : (
                            searched && searched.length > 0 ? (
                                searched.map((item, index) => (
                                    <div className="RichSearch__body_list_item" onClick={() => selectCoin(item)} key={index}>
                                        <ResultItem 
                                            // onSelect={() => selectCoin(item)}
                                            icon={coin}
                                            {...item}
                                            />
                                    </div>
                                    
                                ))
                            ) : <EmptySearch/>
                        ) 
                    }
                    
                </div>
            </div>
        </Modal>
    )
}

export default RichSearch;