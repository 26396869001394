import IconButton from '../../../../components/IconButton/IconButton';
import './AboutItem.scss';
import {BsChevronDown} from 'react-icons/bs';
import { useEffect, useRef, useState } from 'react';



const AboutItem = ({name, text}) => {
    const [bodyHeight, setBodyHeight] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const bodyRef = useRef()

    const toggleItem = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        if(bodyRef && bodyRef.current) {
            if(isOpen) {
                setBodyHeight(bodyRef.current.scrollHeight)
            } else {
                setBodyHeight(0)
            }
        }
        
    }, [isOpen, bodyRef])


    return (
        <div className={"AboutItem" + (isOpen ? ' active ' : '')}>
            <div className="AboutItem__head" onClick={toggleItem}>
                <div className="AboutItem__head_name">{name}</div>
                <IconButton  icon={<BsChevronDown/>}/>
            </div>
            <div className="AboutItem__body" ref={bodyRef}
                    style={{height: isOpen ? bodyHeight : 0 }}>
                <div className="AboutItem__body_content" 
                    >
                    <p>{text}</p>
                </div>
            </div>
        </div>
    )
}

export default AboutItem;