import './Text.scss';

const Text = ({
    placeholder,
    onChange,
    value,
    minHeight
}) => {
    return (
        <div className="Text">
            <textarea
                style={{minHeight: minHeight}}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                ></textarea>
        </div>
    )
}

export default Text;