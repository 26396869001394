import './MobileSearch.scss';
import Input from '../../components/Input/Input';
import {IoMdClose} from 'react-icons/io';
import IconButton from '../IconButton/IconButton';


const MobileSearch = ({visible, close}) => {
    
    const closeSearch = () => {
        close()
    }

    return (
        <div className={"MobileSearch" + (visible ? ' active ' : '')}>
            <div className="MobileSearch__main">
                <Input
                    placeholder={'Search'}

                    />
            </div>
            <div className="MobileSearch__close">
                <IconButton
                    onClick={closeSearch}
                    icon={<IoMdClose/>}
                    />
            </div>
        </div>
    )
}

export default MobileSearch;