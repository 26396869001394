import './RichList.scss';
import {Row, Col} from 'antd';
import Header from '../../components/Header/Header';
import {CgSearch} from 'react-icons/cg';
import RlTable from './components/RlTable/RlTable';
import RlPag from './components/RlPag/RlPag';
import RlInfo from './components/RlInfo/RlInfo';
import RichSearch from './modals/RichSearch/RichSearch';
import { useEffect, useState } from 'react';
import apiService from '../../service/apiService';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const _ = require('lodash');

const service = new apiService()

const RichList = () => {
    const {coin, issuer} = useParams();
    const {authData} = useSelector(state => state)
    const [searchModal, setSearchModal] = useState(false)
    const [list, setList] = useState([])
    // const [coin, setCoin] = useState(null)
    const [listFetch, setListFetch] = useState(true)
    const [totalPageCount, setTotalPageCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [userInfo, setUserInfo] = useState(null)
    const [totalList, setTotalList] = useState([])
    const [coinInfo, setCoinInfo] = useState(null)
    const [pairs, setPairs] = useState([])
    const openSearch = () => setSearchModal(true)
    const closeSearch = () => setSearchModal(false)
    

    // useEffect(() => {
    //     if(authData?.token && authData?.wallet) {
    //         service.getPairs().then(res => {
    //             const initCoin = res.pairs.find(item => item?.base?.currency == 'RLT')
    //             setCoin(initCoin)

    //         })
    //     }
    // }, [authData])

    const updateData = (loadingF) => {
        if(loadingF) {
            if(service && coin && issuer && authData && pairs?.length > 0) {
                setListFetch(true)
                const thisCoin = pairs.find(item => item.base.currency == coin)
                service.getRichList(issuer, authData?.wallet, coin).then(res => {
                    setUserInfo({
                        myBalance: res?.myBalance,
                        myNumber: res?.myNumber,
                        myProcent: res?.myPercent,
                        price: thisCoin?.last
                    })
                    if(res?.tLs?.length > 0) {
                        const cp = _.chunk(res.tLs, 20)
                        setTotalList(res.tLs)
                        setTotalPageCount(cp.length)
                        setCurrentPage(0)
                        setList(cp.map(item => {
                            return item.map((i, index) => {
                                return {
                                    ...i,
                                    price: thisCoin?.last,
                                    index,
                                }
                            })
                        }))
                        
                    } else {
                        setTotalPageCount(0)
                        setCurrentPage(0)
                        setList([])
                    }
                }).finally(_ => setListFetch(false))
            }
        } else {
            if(service && coin && issuer && authData && pairs?.length > 0) {
                const thisCoin = pairs.find(item => item.base.currency == coin)
                service.getRichList(issuer, authData?.wallet, coin).then(res => {
                    setUserInfo({
                        myBalance: res?.myBalance,
                        myNumber: res?.myNumber,
                        myProcent: res?.myPercent,
                        price: thisCoin?.last
                    })
                    if(res?.tLs?.length > 0) {
                        const cp = _.chunk(res.tLs, 20)
                        setTotalList(res.tLs)
                        setTotalPageCount(cp.length)
                        setCurrentPage(0)
                        setList(cp.map(item => {
                            return item.map((i, index) => {
                                return {
                                    ...i,
                                    price: thisCoin?.last,
                                    index,
                                }
                            })
                        }))
                        
                    } else {
                        setTotalPageCount(0)
                        setCurrentPage(0)
                        setList([])
                    }
                }).finally(_ => setListFetch(false))
            }
        }
    }

    useEffect(() => {
        if(service) {
            service.getPairs().then(res => {
                setPairs(res.pairs)
            })
        }
    }, [])

    useEffect(() => {
        updateData(true)
        // const tm = setInterval(() => updateData(false), 10000)
        // return () => clearInterval(tm)
    }, [coin, service, issuer, authData, pairs])

    const onPageChange = (e) => {
        setCurrentPage(e - 1)
    }



    return (
        <div className="page RichList">
            <div className="page__body">
                <Header/>
                <RichSearch
                    close={closeSearch} 
                    visible={searchModal}
                    // setCoin={setCoinInfo}
                    // setCoin={setCoin}
                    />
                <div className="page__body_in">
                    <div className="RichList__in">
                        <Row 
                            // style={{width: '100%'}}
                            gutter={[40, 20]}
                            >
                            <Col 
                                xs={24}
                                sm={24}
                                xl={18}
                                className="RichList__col"
                                >
                                <div className="RichList__main">
                                    <div className="RichList__main_head">
                                        <div className="RichList__main_head_title">Richlist</div>
                                        <div className="RichList__main_head_coin">
                                            {coin}
                                        </div>
                                    </div>
                                    <div className="RichList__main_filter">
                                        <div className="RichList__main_filter_search">
                                            <button onClick={openSearch} className="RichList__main_filter_search_btn">
                                                <span className="RichList__main_filter_search_btn_icon">
                                                    <CgSearch/>
                                                </span>
                                                <span className="RichList__main_filter_search_btn_text">
                                                Search coins
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="RichList__main_body">
                                        
                                        <RlTable currentPage={currentPage} load={listFetch} list={list[currentPage]}/>
                                        {
                                            list && list.length > 0 ? (
                                                <RlPag
                                                    onChange={onPageChange}
                                                    currentPage={currentPage} 
                                                    total={totalPageCount}/>
                                            ) : null
                                        }
                                        
                                    </div>
                                </div>
                            </Col>
                            <Col 
                                xs={24}
                                sm={24}
                                xl={6}
                                className="RichList__col"
                                >
                                <div className="RichList__ex">
                                    <RlInfo
                                        coin={coin} 
                                        list={totalList} 
                                        info={userInfo}/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RichList;