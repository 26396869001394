import './icon.scss';
import trustline from '../assets/icons/trustline.svg'
import { useSelector } from 'react-redux'

export const Trustline = () => {

    const {theme} = useSelector(s => s)

    return(
        <div className='tooltip'>
            <div className='tooltiptext'>TrustLine</div>
            <img alt="" src={trustline} className={`link-icon ${theme === 'dark' ? '' : 'link-icon_d'}`} />
        </div>
    )
}