import './About.scss';
import Header from '../../components/Header/Header';
import AboutItem from './components/AboutItem/AboutItem';

const About = () => {
    return (
        <div className="About page">
            <div className="page__body">
                <Header/>
                <div className="page__body_in">
                    <div className="content">
                        <h2 className="About__head md-title">About</h2>
                        <div className="About__body">
                            <div className="About__body_ac">
                                <AboutItem
                                    name={'About XRPLTOOLS.PRO'}
                                    text={
                                        <>
                                            <p>
                                                XRPLTOOLS.PRO is more than an assistant, it is a new and convenient program for managing your tokens (not related to storage) 
                                                for distributing them to holders, multiplying them by Burn to earn or Staking and more, compiling Richlists, 
                                                generating Snapshots. 
                                            <br />
                                            <br />
                                                We are constantly working on improving and updating functionality.
                                            <br />
                                            <br />
                                                The AirDrop giveaway navigation also contains information on tracking AirDrops in the moment, as well as viewing the history of all 
                                                hands conducted through XRPLTOOLS.PRO. All upcoming events (drop distributions) are marked on the calendar, 
                                                so this information is always clearly presented for each user.
                                            <br/>
                                            <br />
                                                Multiply your tokens by burning with Burn to earn, choose the tokens burning pool you need.
                                            <br />
                                            <br />
                                                Use Staking to replenish your balance, also choose the pool you need and follow all the points.
                                            <br />
                                            <br />
                                                In the Richlist tab, check the rating of holders in the table and chart for the given tokens, 
                                                your data for the token you have chosen will also be displayed in the Information section.
                                            <br />
                                            <br />
                                                Generate SnapShot according to the parameters you set, and also upload your own.
                                            <br />
                                            <br />
                                            <br />
                                                Discover our tools::
                                            <br />
                                            <br />
                                            <ul>
                                                <li>1. Tipbot/ faucet <a href="https://t.me/donation_xrpl_bot">https://t.me/donation_xrpl_bot</a></li>
                                                <li>2. NFT auction  <a href="https://t.me/XRPLNFTAuction">https://t.me/XRPLNFTAuction</a></li>
                                                <li>3. Watcher  <a href="https://t.me/watcherxrpl">https://t.me/watcherxrpl</a></li>
                                            </ul>
                                            </p>
                                        </>
                                    }
                                    />
                                <AboutItem
                                    name={'FAQ'}
                                    text={
                                        <>
                                            <p>
                                                <b>How to create a distribution?</b>
                                                <br />
                                                
                                                To create a new Airdrop, you need to click on the NewAirdrop button in the top panel and go through the necessary steps for this.
                                                <br />
                                                <br />
                                                <b>Terms and concepts when creating NewAirdrop</b>
                                                <br />
                                                
                                                Global ID is your secure digital identity that you can access through the third party app Globalid
                                                <br />
                                                <br />
                                                
                                                KYC - mandatory verification of the client's personal data, as part of the procedure, documents confirming the identity of the client are used
                                                <br />
                                                <br />
                                                Sid phrase (a secret key) - code phrase to access the wallet from which the distribution is made
                                                <br />
                                                <br />
                                                Sender wallet - number of the wallet from which the distribution takes place
                                                <br />
                                                <br />
                                                Currency code - token name
                                                <br />
                                                <br />
                                                Issuer address - token issuer, address of the wallet that issued this token
                                                <br />
                                                <br />
                                                Memo - an informational message that the user will receive along with the token, it is not necessary to fill in
                                                <br />
                                                <br />
                                                <b>What is Trustline?</b>
                                                <br />
                                                Trustlines is a peer-to-peer payment system that does not require a payment card, ID or bank account
                                                A trust line allows two accounts to trade a specific currency up to a set limit. This gives participants confidence that any exchanges between known entities are carried out at the agreed maximum amounts.
                                                <br />
                                                <br />
                                                <b>What is a Token burner?</b>
                                                <br />
                                                This is a "burning of coins" in which a certain amount is deducted from you and returned daily in installments, taking into account the percentage, depending on the selected pool
                                                <br />
                                                <br />
                                                <b>What is Staking?</b>
                                                <br />
                                                This is a kind of contribution of a certain amount for the selected percentage (pool conditions), but here the amount is not debited from your wallet, and after a certain time, if this amount is still on the wallet, you receive an additional amount of tokens
                                                <br />
                                                <br />
                                                <b>Which wallet is linked in the program if I have several?</b>
                                                <br />
                                                The one you are logged in from.
                                                <br />
                                                <br />
                                                <b>Which wallet will distribute AirDrop from when it is created?</b>
                                                <br />
                                                From the wallet whose Sid phrase you specify in the corresponding paragraph when creating a distribution
                                                <br />
                                                <br />
                                                <b>What is a snapshot and what is it for?</b>
                                                <br />
                                                Snapshot is a list of wallets in txt format. To form it, you need to click on the Create Snapshot button in the Richlist section for the coin you have chosen. To download Snapshot, you need to go to the SnapShot tab in the menu and select the desired file to download. You can also create a SnapShot right in the section of the same name. All created SnapShots are stored in this section, where you can delete unnecessary and previously created
                                                <br />
                                                <br />
                                                <b>What the Richlist shows</b>
                                                <br />
                                                This is a list of token holders, sorted in descending order, with wallet number, balance, rating in %, and for your convenience, a chart of the top 5 holders in the form of a chart
                                                <br />
                                                <br />
                                                <b>Search icons in Richlist</b>
                                                <br />
                                                You can make Richlists of your choice of tokens present in the search box.
                                                <br />
                                                <br />
                                                <b>Where is my data for a given coin displayed in the Richlist section?</b>
                                                <br />
                                                In a separate field next to the Richlist table, you can see your balance and all the data for the selected coin
                                                <br />
                                                <br />
                                                <b>What does XRPScan and Bithomp mean in the Links column in the RichList?</b>
                                                <br />
                                                Links to XRPL network projects where you can get more information about the wallet or token.
                                                <br />
                                                <br />
                                                <b>What are the features of the calendar and what does it display?</b>
                                                <br />
                                                The calendar will mark the dates of the AirDrops (in white if the theme is dark, black if the theme is light), current date (in green tint)
                                                
                                            </p>
                                        </>
                                    }
                                    />
                              
                            </div>
                            <div className="About__body_link">
                                <a href="https://t.me/rocketeerschat" target={'_blank'}>Need help?</a>
                            </div>
                        </div>
                        
                    </div>
                    <div className="cd">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;