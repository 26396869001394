import './PushList.scss';
import PushItem from './components/PushItem';



const STATUS = {
    error: 'ERROR',
    success: 'SUCCESS',
    warning: 'WARNING',
    info: 'INFO'
}


const pushMock = [
    {status: STATUS.error},
    {status: STATUS.success},
    {status: STATUS.warning},
    {status: STATUS.info}
]


const PushList = () => {
    return (
        <div className="PushList">
            {
                pushMock && pushMock.length > 0 ? (
                    pushMock.map((item, index) => (
                        <PushItem
                            key={index}
                            status={item.status}
                            />
                    ))
                ) : null
            }
        </div>
    )
}

export default PushList;