import './Button.scss';
import {BeatLoader} from 'react-spinners';

const Button = ({
    text, 
    size, 
    variant, 
    load, 
    disabled, 
    style,
    onClick,
    beforeIcon,
    afterIcon,
    mobileIcon,
    link
}) => {
    if(link) {
        return (
            <a href={link} download={link}  onClick={onClick} style={style} className={"Button" + (load ? ' load ' : '') + (disabled ? ' disabled ' : '') + ` ${variant} ` + (mobileIcon ? ' mobile ' : '')}>
            {
                mobileIcon ? (
                    <div className="Button__mobile">
                        {mobileIcon}
                    </div>
                ) : null
            }
            {
                load ? (
                    <div className="Button__load">
                        <BeatLoader size={10} color={'#fff'}/>
                    </div>
                ) : null
            }
            {
                beforeIcon ? (
                    <span className="Button__icon Button__icon-before">
                        {beforeIcon}
                    </span>
                ) : null
            }
            <span className="Button__text">{text}</span>
            {
                afterIcon ? (
                    <span className="Button__icon Button__icon-after">
                        {afterIcon}
                    </span>
                ) : null
            }
    
            </a>
        )
    }
    return (
        <button onClick={onClick} style={style} className={"Button" + (load ? ' load ' : '') + (disabled ? ' disabled ' : '') + ` ${variant} ` + (mobileIcon ? ' mobile ' : '')}>
            {
                mobileIcon ? (
                    <div className="Button__mobile">
                        {mobileIcon}
                    </div>
                ) : null
            }
            {
                load ? (
                    <div className="Button__load">
                        <BeatLoader size={10} color={'#fff'}/>
                    </div>
                ) : null
            }
            {
                beforeIcon ? (
                    <span className="Button__icon Button__icon-before">
                        {beforeIcon}
                    </span>
                ) : null
            }
            <span className="Button__text">{text}</span>
            {
                afterIcon ? (
                    <span className="Button__icon Button__icon-after">
                        {afterIcon}
                    </span>
                ) : null
            }
    
        </button>
    )
}

export default Button;