import './LogoutCn.scss';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { updateUserAuth, updateToken } from '../../store/actions';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {Row, Col} from 'antd';
import Button from '../Button/Button';

const LOCAL_STORAGE = window.localStorage;



const LogoutCn = ({visible, close}) => {
    const dispatch = useDispatch()
    const nav = useNavigate()
    const [load, setLoad] = useState(false)



    const logoutHandle = () => {
        setLoad(true)
        LOCAL_STORAGE.removeItem('xumm-adtools-user-token')
        LOCAL_STORAGE.removeItem('xumm-adtools-user-uuid')
        LOCAL_STORAGE.removeItem('xumm-adtools-user-wallet')
        dispatch(updateUserAuth({
            token: null,
            uuid: null,
            wallet: null
        }))
        dispatch(updateToken(null))
        window.location.reload()
        setLoad(false)
    }

    const closeHandle = () => {
        close()
    }


    return (
        <Modal
            open={visible}
            onCancel={closeHandle}
            width={360}
            className="modal LogoutCn"
            zIndex={2000}
            >
            <div className="modal__body">
                <h2 className="LogoutCn__head">
                    Are you sure you want to Log out?
                </h2>
                <Row gutter={[20, 0]}>
                    <Col span={12}>
                        <Button
                            text={'No'}
                            onClick={closeHandle}
                            variant={'danger'}
                            />
                    </Col>
                    <Col span={12}>
                        <Button
                            text={'Yes'}
                            variant={'default'}
                            load={load}
                            onClick={logoutHandle}
                            />
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default LogoutCn;