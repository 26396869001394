import './Qr.scss';
import qr from '../../../assets/qr-mock.svg';
import {MoonLoader} from 'react-spinners';
import Loader from '../../Loaders/Loader/Loader';


const Qr = ({image}) => {
    return (
        <div className="Qr">
            <div className="Qr__el">
                {
                    image ? (
                        <img src={image}/>
                    ) : (
                        <div className="Qr__loader">
                            <Loader />
                        </div>   
                    )
                }
            </div>
        </div>
    )
}

export default Qr;