import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const CheckAuth = ({children}) => {

    const {token} = useSelector(state => state)

    if(!token && window.innerWidth < 1000) {
        return <Navigate to={'/auth'}/>
    } else {
        return (
            <>
                {children}
            </>
        )
    }

    

}

export default CheckAuth;