import './DefLoader.scss';
import { Loader } from '../';
import { motion } from 'framer-motion';


const DefLoader = ({show}) => {


    if(show) {
        return (
            <motion.div 
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 0.5}}
                exit={{opacity: 0}}
                className="DefLoader">
                <Loader/>
            </motion.div>      
        )
    }
    return (
        null   
    )
}

export default DefLoader;