import './SSItem.scss';
import { Row, Col } from 'antd';
import Button from '../../../../components/Button/Button';
import {BiTrash} from 'react-icons/bi';
import {AiOutlineDownload} from 'react-icons/ai';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import apiService from '../../../../service/apiService';
import { message } from 'antd';
import {MoonLoader} from 'react-spinners';

const as = new apiService();

const SSItem = ({
    name,
    deleteItem,
    index,
    download,
    link,
    list,
    setList,
    loading
}) => {
    const authData = useSelector(state => state.authData)
    const [delLoad, setDelLoad] = useState(false)

    const deleteSnapshot = () => {
        setDelLoad(true)
        as.deleteSnapshot(authData?.wallet, authData?.token, index).then(res => {
        
            if(res == 200) {
                message.success('SnapShot deleted')
                const m = list;
                const rm = m.splice(m.findIndex(item => item.index == index), 1)
                setList([...m])
            } else {
                message.error('Error')
            }
        }).finally(_ => setDelLoad(false))
    }


    return (
        <div className={"SSItem"}>
            
            <Row gutter={[5, 0]} style={{alignItems: 'flex-end'}}>
                <Col span={16}>
                    <div className="SSItem__el">
                        {name}
                    </div>
                </Col>
                <Col span={8}>
                    {
                        loading ? (
                            <div className="SSItem__action">
                                <Row gutter={[15, 0]}>
                                    <Col span={12}>
                                        <div className="SSItem__load">
                                            <MoonLoader size={28} color='var(--green1)'/>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <Button 
                                            // disabled={loading}
                                            load={delLoad}
                                            onClick={deleteSnapshot} 
                                            mobileIcon={<BiTrash/>} 
                                            text={'Delete'} 
                                            variant={'danger'}/>
                                    </Col>
                                </Row>
                            </div>
                            
                        ) : (
                            <div className="SSItem__action">
                                <Row gutter={[15, 0]}>
                                    <Col span={12}>
                                        <Button
                                            disabled={loading}
                                            link={link}
                                            // onClick={() => download(index)} 
                                            mobileIcon={<AiOutlineDownload/>} 
                                            text={'Download'}
                                            variant={'default'}/>
                                    </Col>
                                    <Col span={12}>
                                        <Button 
                                            disabled={loading}
                                            load={delLoad}
                                            onClick={deleteSnapshot} 
                                            mobileIcon={<BiTrash/>} 
                                            text={'Delete'} 
                                            variant={'danger'}/>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </Col>
            </Row>
            
            
        </div>
    )
}

export default SSItem;