const BASE_DOMAIN = 'https://api.xrpltools.pro';

const endpoints = {
    sign: `${BASE_DOMAIN}/RLTAPI/Sign`,
    userData: `${BASE_DOMAIN}/RLTAPI/UserData`,
    airdrop: `${BASE_DOMAIN}/RLTAPI/Airdrop`,
    snapshot: `${BASE_DOMAIN}/RLTAPI/MakeSnapshot_XLS20D`,
    snaphto_token: `${BASE_DOMAIN}/RLTAPI/MakeSnapshot_Tokens`,
    checktr: `${BASE_DOMAIN}/RLTAPI/CheckTransaction`,
    deleteSnapshot: `${BASE_DOMAIN}/RLTAPI/DeleteSnapshot`,
    richlist: `${BASE_DOMAIN}/RLTAPI/GetRichList`,
    getPairs: `${BASE_DOMAIN}/RLTAPI/GetPairs`,
    downloadSnapshot: `${BASE_DOMAIN}/RLTAPI/GetSnapshot`,
    deleteAirdrop: `${BASE_DOMAIN}/RLTAPI/Airdrop`,
    // GetCommision
    getComission: `${BASE_DOMAIN}/RLTAPI/GetCommision`,
    checkComission: `${BASE_DOMAIN}/RLTAPI/CheckCommision`,
    uploadSnapshot: `${BASE_DOMAIN}/RLTAPI/AddSnapshot`,


    //burn
    getBurnPools: `${BASE_DOMAIN}/RLTAPI/GetBurnPools`,
    newBurn: `${BASE_DOMAIN}/RLTAPI/NewBurn`,
    checkBurnTrans: `${BASE_DOMAIN}/RLTAPI/CheckBurnTransaction`,


    getBalance: `https://xrplcluster.com/`,

    getTokensInOffers: `${BASE_DOMAIN}/RLTAPI/GetTokensInOffers`,


    //stake
    getStakePools: `${BASE_DOMAIN}/RLTAPI/GetStakePools`,
    newStake: `${BASE_DOMAIN}/RLTAPI/NewStake`,
    getTopNft: `${BASE_DOMAIN}/RLTAPI/GetTopNft`,
    getPointsMultiplier: `${BASE_DOMAIN}/RLTAPI/GetPointsMultiplier`,

    PointPrice: `${BASE_DOMAIN}/RLTAPI/PointPrice`,

    //nft
    GetNFTStakePools: `${BASE_DOMAIN}/RLTAPI/GetNFTStakePools`,
    GetUserStakableNFTs: `${BASE_DOMAIN}/RLTAPI/GetUserStakableNFTs`,
    NewNFTStake: `${BASE_DOMAIN}/RLTAPI/NewNFTStake`,
    GetUserCarats: `${BASE_DOMAIN}/RLTAPI/GetUserCarats`,
    GetUserPower: `${BASE_DOMAIN}/RLTAPI/GetPower`,

    GetVote: `${BASE_DOMAIN}/RLTAPI/GetVote`,
    VoteTransaction: `${BASE_DOMAIN}/RLTAPI/VoteTransaction`
}

export default endpoints;