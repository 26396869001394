import './TabToken.scss';
import { Row, Col, message } from 'antd';
import { useEffect, useState } from 'react';
import Radio from '../../../Radio/Radio';
import Button from '../../../Button/Button';
import Input from '../../../Input/Input';
import {BiPlus, BiTrash} from 'react-icons/bi';
import IconButton from '../../../IconButton/IconButton';
import { useSelector } from 'react-redux';
import apiService from '../../../../service/apiService';


const service = new apiService()



const TabToken = ({close, updateList}) => {
    const [load, setLoad] = useState(false)
    const {authData} = useSelector(state => state)

    const [part, setPart] = useState('0')
    const [label, setLabel] = useState('')

    const [firstCode, setFirstCode] = useState('')
    const [fisrtIssuer, setFirstIssuer] = useState('')

    const [min, setMin] = useState('')
    const [max, setMax] = useState('')

    const [list, setList] = useState([
        {
            code: '',
            issuer: '',
            min: '',
            max: ''
        }
    ])

    const actionFunc = (part) => {
        switch(part) {
            case '0':
                return(
                    <Button
                        disabled={!fisrtIssuer || !firstCode}
                        load={load}
                        text={'Create'}
                        onClick={onSave}
                        variant={'default'}
                        />
                )
            case '1':
                return (
                    <Button
                        disabled={!fisrtIssuer || !firstCode}
                        load={load}
                        text={'Create'}
                        onClick={onSave}
                        variant={'default'}
                        />
                )
            case '2':
                return (
                    <Button
                        disabled={list.every((i) => {
                            if(i.code && i.issuer) {
                                return false
                            } else {
                                return true
                            }
                        })}
                        load={load}
                        text={'Create'}
                        onClick={onSave}
                        variant={'default'}
                        />
                )
            default:
                return null;
        }
    }


    useEffect(() => {
        setFirstCode('')
        setFirstIssuer('')
        setMax('')
        setMin('')
        setList([{
            code: '',
            issuer: '',
            min: '',
            max: ''
        }])
    }, [part])


    const closeHandle = () => {
        close()
        setFirstCode('')
        setFirstIssuer('')
        setMin('')
        setMax('')
        setLabel('')
        setList([
            {
                code: '',
                issuer: '',
                min: '',
                max: ''
            }
        ])
        setPart('')
    }

    const addNew = () => {
        setList(state => {
            return [
                ...state,
                {
                    code: '',
                    issuer: '',
                    min: '',
                    max: '' 
                }
            ]
        })
    }

    const removeNex = (index) => {
        setList(state => {
            const m = state;
            const rm = m.splice(index, 1)
            return [...m]
        })
    }

    const onSave = () => {
        const {wallet, token} = authData;
        setLoad(true)
        if(part == '0') {
            const body = [
                {
                    issuer: fisrtIssuer,
                    tokenCode: firstCode,
                    minBalance: -1,
                    maxBalance: -1
                }
            ]
            service.makeSnapshot_token(wallet, token, label ? label : 'new_name', body).then(res => {
            
                if(res?.status == 200) {
                    closeHandle()
                    message.success('Snapshot created')
                    if(updateList) {
                        updateList()
                    }
                } else {
                    message.error('An error has occurred, please check your details')
                }

            }).finally(_ => {
                setLoad(false)
            })
        }
        if(part == '1') {
            const body = [
                {
                    issuer: fisrtIssuer,
                    tokenCode: firstCode,
                    minBalance: min ? Number(min) : -1,
                    maxBalance: max ? Number(max) : -1
                }
            ]
            
            service.makeSnapshot_token(wallet, token, label ? label : 'new_name', body).then(res => {
               
                if(res?.status == 200) {
                    closeHandle()
                    message.success('Snapshot created')
                    if(updateList) {
                        updateList()
                    }
                } else {
                    message.error('An error has occurred, please check your details')
                }
            }).finally(_ => {
                setLoad(false)
                
            })
        }
        if(part == '2') {
            const body = list.map(item => {
                return {
                    issuer: item.issuer,
                    tokenCode: item.code,
                    minBalance: item.min ? Number(item.min) : -1,
                    maxBalance: item.max ? Number(item.max) : -1
                }
            })
            
            service.makeSnapshot_token(wallet, token, label ? label : 'new_name', body).then(res => {
                if(res?.status == 200) {
                    closeHandle()
                    message.success('Snapshot created')
                    if(updateList) {
                        updateList()
                    }
                } else {
                    message.error('An error has occurred, please check your details')
                }
            }).finally(_ => {
                setLoad(false)
            })
        }
    }




    return (
        <div className="TabToken">
            <Row gutter={[30, 30]}>
                <Col span={24}>
                    <Input
                        placeholder={'Snapshot name'}
                        value={label}
                        onChange={e => setLabel(e.target.value)}
                        />
                </Col>
                <Col span={24}>
                    <Radio
                        text={'Snapshot for one issuer'}
                        checked={part == '0'}
                        onChange={() => setPart('0')}
                        id={'token-snapshot-create-1'}
                        />
                </Col>
                {
                    part == '0' ? (
                        <Col span={24}>
                            <Row gutter={[15,15]}>
                                <Col span={12}>
                                    <Input
                                        placeholder={'Code'}
                                        value={firstCode}
                                        onChange={e => setFirstCode(e.target.value)}
                                        />
                                </Col>
                                <Col span={12}>
                                    <Input
                                        placeholder={'Issuer'}
                                        value={fisrtIssuer}
                                        onChange={e => setFirstIssuer(e.target.value)}
                                        />
                                </Col>
                            </Row>
                        </Col>
                    ) : null
                }
                <Col span={24}>
                    <Radio
                        text={'Snapshot by holders that have tokens in a certain range'}
                        checked={part == '1'}
                        onChange={() => setPart('1')}
                        id={'token-snapshot-create-2'}
                        />
                </Col>
                {
                    part == '1' ? (
                        <Col span={24}>
                            <Row gutter={[15,15]}>
                                <Col span={12}>
                                    <Input
                                        placeholder={'Code'}
                                        value={firstCode}
                                        onChange={e => setFirstCode(e.target.value)}
                                        />
                                </Col>
                                <Col span={12}>
                                    <Input
                                        placeholder={'Issuer'}
                                        value={fisrtIssuer}
                                        onChange={e => setFirstIssuer(e.target.value)}
                                        />
                                </Col>
                                <Col span={12}>
                                    <Input
                                        placeholder={'Min token value'}
                                        value={min}
                                        onChange={e => setMin(e.target.value)}
                                        />
                                </Col>
                                <Col span={12}>
                                    <Input
                                        placeholder={'Max token value'}
                                        value={max}
                                        onChange={e => setMax(e.target.value)}
                                        />
                                </Col>
                            </Row>
                        </Col>
                    ) : null
                }
                <Col span={24}>
                    <Radio
                        text={'Multi-snapshot with elimination of holders in a certain range'}
                        checked={part == '2'}
                        onChange={() => setPart('2')}
                        id={'token-snapshot-create-3'}
                        />
                </Col>
                {
                    part == '2' ? (
                        <Col span={24}>
                            <Row gutter={[20, 20]}>
                                {
                                    list && list.length > 0 ? (
                                        list.map((item, index) => (
                                            <Col span={24} key={index}>
                                                <Row gutter={[15,15]}>
                                                    <Col span={12}>
                                                        <Input
                                                            placeholder={'Code'}
                                                            value={item.code}
                                                            onChange={e => {
                                                                const obj = {
                                                                    code: e.target.value,
                                                                    issuer: list[index].issuer,
                                                                    min: list[index].min,
                                                                    max: list[index].max
                                                                }
                                                                const m = list;
                                                                const rm = m.splice(index, 1, obj)
                                                                setList([...m])
                                                            }}
                                                            />
                                                    </Col>
                                                    <Col span={12}>
                                                        <Input
                                                            placeholder={'Issuer'}
                                                            value={item.issuer}
                                                            onChange={e => {
                                                                const obj = {
                                                                    code: list[index].code,
                                                                    issuer: e.target.value,
                                                                    min: list[index].min,
                                                                    max: list[index].max
                                                                }
                                                                const m = list;
                                                                const rm = m.splice(index, 1, obj)
                                                                setList([...m])
                                                            }}
                                                            />
                                                    </Col>
                                                    <Col span={12}>
                                                        <Input
                                                            placeholder={'Min token value'}
                                                            value={item.min}
                                                            onChange={e => {
                                                                const obj = {
                                                                    code: list[index].code,
                                                                    issuer: list[index].issuer,
                                                                    min: e.target.value,
                                                                    max: list[index].max
                                                                }
                                                                const m = list;
                                                                const rm = m.splice(index, 1, obj)
                                                                setList([...m])
                                                            }}
                                                            />
                                                    </Col>
                                                    <Col span={12}>
                                                        <Input
                                                            placeholder={'Max token value'}
                                                            value={item.max}
                                                            onChange={e => {
                                                                const obj = {
                                                                    code: list[index].code,
                                                                    issuer: list[index].issuer,
                                                                    min: list[index].min,
                                                                    max: e.target.value
                                                                }
                                                                const m = list;
                                                                const rm = m.splice(index, 1, obj)
                                                                setList([...m])
                                                            }}
                                                            />
                                                    </Col>
                                                    <Col span={12}>
                                                        <IconButton
                                                            icon={<BiTrash color='var(--red2)'/>}
                                                            onClick={() => removeNex(index)}
                                                            />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        ))
                                    ) : null
                                }
                                <div style={{marginTop: 20}}>
                                    <Button
                                        onClick={addNew}
                                        style={{color: 'var(--text)'}}
                                        text={'Add new'}
                                        beforeIcon={<BiPlus/>}
                                        />
                                </div>
                            </Row>
                        </Col>
                    ) : null
                }
                {
                    
                }
                <div className="TabToken__action">
                    {/* <Button
                        // disabled={}
                        disabled={(!firstCode || !fisrtIssuer) || ((!firstCode || !fisrtIssuer) && (!min || !max))}
                        load={load}
                        text={'Create'}
                        onClick={onSave}
                        variant={'default'}
                        /> */}

                    {actionFunc(part)}
                </div>
            </Row>
        </div>
    )
}

export default TabToken;