import { Modal } from "antd";
import './CreateSnapshot.scss';
import apiService from "../../service/apiService";
import { useState } from "react";
import TabToken from "./components/TabToken/TabToken";
import TabNft from "./components/TabNft/TabNft";
const as = new apiService()

const snapshotTabs = [
    {
        label: 'Token',
    }, 
    {
        label: 'NFT'
    }
]

const CreateSnapshot = ({visible, close, updateList}) => {
    const [activeTab, setActiveTab] = useState(0)

    return (
        <Modal  
            className="modal CreateSnapshot"
            open={visible}
            onCancel={close}
            >
            <div className="modal__body">
                <h2 className="modal__body_head CreateSnapshot__head">Create Snapshot</h2>
                <div className="CreateSnapshot__body">
                    <div className="CreateSnapshot__body_tabs">
                        {
                            snapshotTabs.map((item, index) => (
                                <div onClick={() => setActiveTab(index)} className={"CreateSnapshot__body_tab" + (activeTab == index ? ' active ' : '')} key={index}>
                                    {item.label}
                                </div>
                            ))
                        }
                    </div>
                    <div className="CreateSnapshot__body_content">
                        {
                            activeTab == 0 ? (
                                <TabToken close={close} updateList={updateList}/>
                            ) : null
                        }
                        {
                            activeTab == 1 ? (
                                <TabNft close={close} updateList={updateList}/>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CreateSnapshot;