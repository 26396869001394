import './icon.scss';
import twitter from '../assets/icons/twitter.svg'
import { useSelector } from 'react-redux'

export const Twitter = ({ tooltip = true }) => {

    const {theme} = useSelector(s => s)

    return(
        <div className='tooltip'>
            {
                tooltip ? <div className='tooltiptext'>Twitter</div> : ''
            }
            <img alt="" src={twitter} className={`link-icon ${theme === 'dark' ? '' : 'link-icon_d'}`} />
        </div>
    )
}