import './WalletSearch.scss';
import IconButton from '../IconButton/IconButton';
import {BiSearch} from 'react-icons/bi';
import MobileSearch from '../MobileSearch/MobileSearch';
import { useState } from 'react';

const WalletSearch = ({list, placeholder}) => {
    const [mobSearch, setMobSearch] = useState(false)


    const closeMobSearch = () => {
        setMobSearch(false)
    }

    const openMobSearch = () => {
        setMobSearch(true)
    }


    return (
        <div className="WalletSearch">
            <MobileSearch
                visible={mobSearch}
                close={closeMobSearch}
                />
            <div className="WalletSearch__mb">
                <IconButton
                    onClick={openMobSearch}
                    icon={<BiSearch/>}
                    />
            </div>
            <div className="WalletSearch__main">
                <div className="WalletSearch__main_icon">
                <IconButton
                    icon={<BiSearch/>}
                    />
                </div>
                <div className="WalletSearch__main_body">
                    <input type="text" placeholder={placeholder}/>
                </div>
            </div>
        </div>
    )
}

export default WalletSearch;