import './ProfileDrop.scss';
import {RiSunLine} from 'react-icons/ri';
import {TbBellRinging} from 'react-icons/tb';
import {Link, NavLink, useNavigate, useLocation} from 'react-router-dom';
import IconButton from '../IconButton/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button/Button';
import LogoutCn from '../LogoutCn/LogoutCn';
import { useState } from 'react';
import { themeToggle } from '../../store/actions';
import {GiNightSleep} from 'react-icons/gi';
import { useEffect } from 'react';
import SunIcon from '../../icons/SunIcon';
import MoonIcon from '../../icons/MoonIcon';
import apiService from '../../service/apiService';
import arrow from '../../assets/icons/arrow.svg';
import wallet from '../../assets/icons/wallet.svg';
import { Dropdown } from 'antd';
import LogoutMenu from '../LogoutMenu/LogoutMenu';

const service = new apiService();
const LOCAL_STORAGE = window.localStorage;

const ProfileDrop = ({close}) => {
    const {token,theme} = useSelector(state => state)
    const dispatch = useDispatch();
    const nav = useNavigate()
    const [logout, setLogout] = useState(false)
    const [defaultCoin, setDefaultCoin] = useState('RLT')
    const [defaultIssuer, setDefaultIssuer] = useState('')

    const [openedMenu, setOpenedMenu] = useState([])

    const { pathname } = useLocation()

    useEffect(() => {
        if(defaultCoin) {
            service.getPairs().then(res => {
                const initCoin = res.pairs.find(item => item?.base?.currency == 'RLT')
                setDefaultIssuer(initCoin?.base?.issuer)
            })
        }
    }, [defaultCoin])


    const logoutClose = () => setLogout(false)
    const logoutOpen = () => {
        setLogout(true)
        close(false)
    }

    const toggleTheme = (value) => {
        LOCAL_STORAGE.setItem('xumm-adtools-theme', value)
        dispatch(themeToggle(value))
    }


    return (
        <div className="ProfileDrop">
            <LogoutCn
                visible={logout}
                close={logoutClose}
            />
            <div className={`Sidebar__main ${theme === 'dark' ? '' : 'Sidebar__main_l'}`}>
                    <div className="Sidebar__main_list">
                        <div className="Sidebar__main_item">
                            <NavLink 
                                onClick={() => setOpenedMenu(o => o.includes(1) ? o.filter(e => e !== 1) : [...o, 1])} 
                            >
                                <span className={theme === 'dark' ? 'Sidebar__main_item-drop_d' : 'Sidebar__main_item-drop'}>Distribution</span>
                                <img 
                                    className={`Sidebar__main_arrow ${openedMenu.includes(1) ? 'Sidebar__main_arrow_opened' : ''} ${theme === 'light' ? 'Sidebar__main_arrow_d' : ''}`} 
                                    src={arrow} 
                                /> 
                            </NavLink>
                            <div className={`Sidebar__main_subitem ${openedMenu.includes(1) ? 'Sidebar__main_subitem_opened' : ''}`}>
                                <NavLink to={'/airdrop'}>AirDrop</NavLink>
                                <NavLink to={'/snapshot'}>SnapShot</NavLink>
                            </div>
                        </div>
                        <div className="Sidebar__main_item">
                            <NavLink to={`/richlist/${defaultCoin}/${defaultIssuer}`}>
                                <span>Richlist</span>
                            </NavLink>
                        </div>
                        <div className="Sidebar__main_item">
                            <NavLink to={'/burn'}>
                                <span>Burn to earn</span>
                            </NavLink>
                        </div>
                        <div className="Sidebar__main_item">
                            <NavLink 
                                onClick={() => setOpenedMenu(o => o.includes(2) ? o.filter(e => e !== 2) : [...o, 2])} 
                            >
                                <span className={theme === 'dark' ? 'Sidebar__main_item-drop_d' : 'Sidebar__main_item-drop'}>Staking</span>
                                <img 
                                    className={`Sidebar__main_arrow ${openedMenu.includes(2) ? 'Sidebar__main_arrow_opened' : ''} ${theme === 'light' ? 'Sidebar__main_arrow_d' : ''}`} 
                                    src={arrow} 
                                /> 
                            </NavLink>
                            <div className={`Sidebar__main_subitem ${openedMenu.includes(2) ? 'Sidebar__main_subitem_opened' : ''}`}>
                                <NavLink to={'/staking'}>Token</NavLink>
                                <NavLink to={'/staking-nft'}>NFT</NavLink>
                            </div>
                        </div>
                        <div className="Sidebar__main_item">
                            <NavLink to={'/voting'}>
                                <span>Voting</span>
                            </NavLink>
                        </div>
                        <div className="Sidebar__main_item">
                            <NavLink to={'/about'}>
                                <span>About</span>
                            </NavLink>
                        </div>
                    </div>    
                </div>  
            <div className="ProfileDrop__theme">
                    {
                        theme == 'light' ? (
                            <IconButton
                                onClick={() => toggleTheme('dark')}
                                icon={<MoonIcon color='var(--text)'/>}
                                />
                        ) : (
                            <IconButton
                                onClick={() => toggleTheme('light')}
                                icon={<SunIcon color='var(--text)'/>}
                                />
                        )
                    }
                    
                    <Dropdown
                        overlay={<LogoutMenu />}
                    >
                        <img alt="" src={wallet} className={theme === 'light' ? '' : 'icon-d'} />
                    </Dropdown>
            </div>
        </div>  
    )
}

export default ProfileDrop;