import './MainCalendar.scss';
// import Calendar from 'react-calendar';
import { Calendar } from 'antd';
import { useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import apiService from '../../service/apiService';
import { useEffect } from 'react';
import moment from 'moment';


//SELECTED TYPES
//обведены только даты раздачи

//red --- остановленный дроп или с ошибкой
//green --- успешно завершенный дроп
//black --- текущий дроп

const service = new apiService();


const MainCalendar = () => {
    const [date, setDate] = useState(new Date())
    const {authData, userData} = useSelector(state => state)
    const [drops, setDrops] = useState([])



    useEffect(() => {
        if(authData?.wallet && authData?.token && userData?.Drops?.length > 0) {
            
            setDrops(userData?.Drops?.map(item => {
                return {
                    dropDate: Date.parse(moment(item.timeStart).format()),
                    status: item.Status
                }
            }))
        }
    }, [authData, userData])




    return (
        <div className="MainCalendar">
            <Calendar
                className='MainCalendar__el'
                fullscreen={false}
                headerRender={({value}) => {
                    return (
                        <div className='MainCalendar__head'>
                            <div className="MainCalendar__head_today">
                                <Moment date={Date.now()} format={'DD.MM.YYYY'}/>
                            </div>
                            <div className="MainCalendar__head_main">
                                <div className="MainCalendar__head_main_item">
                                    <Moment date={value._d} format={'MMMM'} />
                                </div>
                                <div className="MainCalendar__head_main_item">
                                    <Moment date={value._d} format={'YYYY'}/>
                                </div>
                            </div>
                        </div>
                    )
                }}
                
                dateCellRender={e => {
                    let i = Date.parse(moment(e._d).format());
                    const findItem = drops?.find(item => moment(item.dropDate).format('YYYY-MM-DD') == moment(i).format('YYYY-MM-DD'))
     
                    if(findItem && findItem.dropDate > Date.now() && moment(i).format('YYYY-MM-DD') != moment(Date.now()).format('YYYY-MM-DD') && findItem.status != 2  ) {
                        return (
                            <div className="ddff">

                            </div>
                        )
                    }
                }}
                />
        </div>
    )
}

export default MainCalendar;