import './DateA.scss';
import { DatePicker } from 'antd';




const DateA = ({onChange}) => {
    return (
        <DatePicker 
            onChange={onChange}
            className='DateA'/>  
    )
}

export default DateA;