import './StepAm.scss';
import { Row, Col } from 'antd';
import Radio from '../../../../components/Radio/Radio';
import { useState, useEffect } from 'react';
import * as _ from 'lodash';
import Input from '../../../../components/Input/Input';
import { Twitter } from '../../../../icons/twitter';
import { Website } from '../../../../icons/website';
import { Trustline } from '../../../../icons/trustline';
import { useSelector } from 'react-redux';
import apiService from '../../../../service/apiService';


const StepAm = ({
    active,
    balance,
    poolList,
    setCount,
    count,
    selectedPercent,
    topNft,
    pointsMultiplier,
    setTopNft,
    pointPrice,
    setNextDisable
}) => {

    const service = new apiService();

    const userData = useSelector(s => s.userData);

    const [list, setList] = useState([]);
    const [multipleValue, setMultipleValue] = useState(null);
    const need = _.round(Number(count) / Number(selectedPercent?.points?.burnForOnePoint), 3);
    const enough = userData?.Points * Number(selectedPercent?.points?.burnForOnePoint);

    useEffect(() => {
        if(poolList?.length > 0) {
            setList(poolList.filter(item => item.currencyCode_Symbols == balance?.currency).filter(item => item.nowPool != 0 && item.nowPool > item.minBurn))
        }
    }, [poolList, balance])

    const isSame = selectedPercent ? (selectedPercent.currencyCode_Symbols + selectedPercent.issuer) === (selectedPercent?.tokenReward?.currencyCode_Symbols + selectedPercent?.tokenReward?.issuer) : false 

    useEffect(() => {
        (async function() {

            if (!userData || !userData.wallet) {
                return;
            };

            const res = await service.getTopNft(userData.wallet);
            
            if (!res) {
                return setMultipleValue(1);
            }

            const value = Number(res.replace("x", ""));
            
            setMultipleValue(value);

        }())
    }, [userData]);

    useEffect(() => {

        (function() {
            if (count == '' || count < selectedPercent?.minBurn) {
                return setNextDisable(true)
            } 

            if (Number(count) > Number(balance?.balance)) {
                return setNextDisable(true)
            }

            if (enough !== NaN && topNft !== 'inf' && (Number(count) > enough)) {
                return setNextDisable(true)
            }

            if (Number(count) > selectedPercent?.maxPool) {
                return setNextDisable(true)
            } 

            setNextDisable(false)

        }());

    }, [count, balance, selectedPercent])

    return (
        <div className={"StepAm" + (active ? ' active ' : '')}>
            <h2 className="step__head nd-step__head">Amount</h2>
            <Row gutter={[0, 40]}>
                
                <Col span={24}>
                    <div className="StepAm__head">
                        You have {balance?.balance} {balance?.currency}
                        {
                            (topNft === 'inf' || selectedPercent?.points === null) ?
                            <div>You can burn no more than {
                                selectedPercent?.maxPool < selectedPercent?.minBurn ?
                                '0'
                                :
                                balance?.balance < selectedPercent?.minBurn ?
                                '0'
                                :
                                balance?.balance > selectedPercent?.maxPool
                                ?
                                selectedPercent?.maxPool : balance?.balance  
                            } {balance?.currency}
                            </div>
                            :
                            <div>
                                You can burn no more than {
                                selectedPercent?.maxPool < selectedPercent?.minBurn ?
                                '0'
                                :
                                enough < selectedPercent?.minBurn ?
                                '0'
                                :
                                enough > selectedPercent?.maxPool
                                ?
                                selectedPercent?.maxPool : enough 
                                } {balance?.currency}
                            </div>
                        }
                    </div>
                </Col>
                <Col span={24}>
                    <Row gutter={[20,20]}>
                        <Col md={12} span={24}>
                            <div className="StepAm__count">
                                <div className="StepAm__count_label">
                                    Write amount of {balance?.currency} you want to burn
                                </div>
                                <div className="StepAm__count_input">
                                    <Input
                                        mask={Number}
                                        value={count}
                                        onChange={e => setCount(e)}
                                        placeholder={''}
                                        />
                                </div> 
                                <div className="StepAm__count_label" style={{marginTop: 10}}>
                                    {
                                        ((selectedPercent?.currencyCode_Symbols == 'RLT') && (selectedPercent?.tokenReward?.currencyCode_Symbols === 'RLT')) ? (
                                                (pointPrice && count) ? (
                                                    <div className="StepPer__info_body_item" style={{marginTop: 10, marginBottom: 10}}>
                                                        <span className="StepPer__info_body_item_name">
                                                        For burn {count} {selectedPercent?.currencyCode_Symbols}, you receive {_.round((Number(count) / Number(pointPrice)) 
                                                        // * Number(pointsMultiplier) 
                                                        * Number(selectedPercent?.pointIndex)*(1 + (multipleValue || 0)),3)} RLT points.
                                                        </span>
                                                    </div>
                                                ) : null
                                        ) : (
                                            topNft == 'inf' ? (
                                                null
                                            ) : (
                                                (selectedPercent?.points?.burnForOnePoint && count) ? (
                                                    <div className="StepPer__info_body_item" style={{marginTop: 10, marginBottom: 10}}>
                                                        <span className="StepPer__info_body_item_name">
                                                        For burn {count} {selectedPercent?.currencyCode_Symbols}, you need {need} RLT points.
                                                        </span>
                                                    </div>
                                                ) : null
                                            )
                                            
                                        )
                                    }
                                </div>
                                
                            </div>
                        </Col>
                        {
                            selectedPercent ? (
                                <Col md={12} span={24}>
                                    <div className="StepPer__info">
                                        <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                            <div>
                                                <div className="StepPer__info_head">
                                                    {selectedPercent?.header}
                                                </div>
                                                <div className="StepPer__info_links">
                                                    <div className="StepPer__info_links_i">
                                                        <a rel="noreferrer" href={selectedPercent?.twitter} target="_blank" className='StepPer__info_links_item'>
                                                            <Twitter />
                                                        </a>
                                                    </div>
                                                    <div className="StepPer__info_links_i">
                                                        <a rel="noreferrer" href={selectedPercent?.webSite} target="_blank" className='StepPer__info_links_item'>
                                                            <Website />
                                                        </a>
                                                    </div>
                                                    <div className="StepPer__info_links_i">
                                                        <a rel="noreferrer" href={selectedPercent?.tl} target="_blank" className='StepPer__info_links_item'>
                                                            <Trustline />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isSame ?
                                                ''
                                                :
                                                <div>
                                                    <div className="StepPer__info_head">
                                                        {selectedPercent?.tokenReward?.header}
                                                    </div>
                                                    <div className="StepPer__info_links">
                                                        <div className="StepPer__info_links_i">
                                                            <a rel="noreferrer" href={selectedPercent?.tokenReward?.twitter} target="_blank" className='StepPer__info_links_item'>
                                                                <Twitter />
                                                            </a>
                                                        </div>
                                                        <div className="StepPer__info_links_i">
                                                            <a rel="noreferrer" href={selectedPercent?.tokenReward?.webSite} target="_blank" className='StepPer__info_links_item'>
                                                                <Website />
                                                            </a>
                                                        </div>
                                                        <div className="StepPer__info_links_i">
                                                            <a rel="noreferrer" href={selectedPercent?.tokenReward?.tl} target="_blank" className='StepPer__info_links_item'>
                                                                <Trustline />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="StepPer__info_body">
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Total Pool size:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent?.maxPool}
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Availible Pool size:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent?.nowPool}
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Minimum amount of burning:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent?.minBurn}
                                                </span>
                                            </div>
                                            {
                                                isSame ?
                                                <div className="StepPer__info_body_item">
                                                    <span className="StepPer__info_body_item_name">
                                                        Burn Percentage Yeild:
                                                    </span>
                                                    <span className="StepPer__info_body_item_value">
                                                        {_.round(selectedPercent.reward * 100 * selectedPercent.countRewards - 100)}%
                                                    </span>
                                                </div>
                                                :
                                                <div className="StepPer__info_body_item">
                                                    <span className="StepPer__info_body_item_name">
                                                        Burn ratio:
                                                    </span>
                                                    <span className="StepPer__info_body_item_value">
                                                        {Number(count)} / {_.round(Number(count) * selectedPercent.reward * selectedPercent.countRewards)}
                                                    </span>
                                                </div>
                                            }
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Reward period:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent.countRewards} days
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Daily reward:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {
                                                        isSame ?
                                                        `${_.round(selectedPercent.reward * 100, 3)}%`
                                                        :
                                                        `${selectedPercent.reward} ${selectedPercent?.tokenReward.currencyCode_Symbols}`
                                                    }
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Total Rewards:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {
                                                        isSame ?
                                                        `${_.round(selectedPercent.reward * 100 * selectedPercent.countRewards)}%`
                                                        :
                                                        _.round(Number(count) * selectedPercent.reward * selectedPercent.countRewards)
                                                    }
                                                </span>
                                            </div>
                                            
                                            {/* {
                                                selectedPercent?.points?.burnForOnePoint ? (
                                                    <div className="StepPer__info_body_item">
                                                        <span className="StepPer__info_body_item_name">
                                                            For 1 RLT point you can burn &nbsp;
                                                        </span>
                                                        <span className="StepPer__info_body_item_value">
                                                            {selectedPercent.points?.burnForOnePoint} {selectedPercent.currencyCode_Symbols}
                                                        </span>
                                                    </div>
                                                ) : null
                                            } */}
                                        </div>
                                    </div>
                                </Col>
                            ) : null
                        }  
                    </Row>    
                </Col> 
            </Row>
        </div>  
    )
}

export default StepAm;