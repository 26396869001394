import './icon.scss';


const SunIcon = ({color = 'var(--gray1)', size = 25}) => {
    return (
        <div className="SunIcon icon" style={{width: size, height: size}}>
            
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3638 12.0001C16.3638 14.4102 14.41 16.364 12.0001 16.364C9.59013 16.364 7.63642 14.4102 7.63642 12.0001C7.63642 9.59005 9.59013 7.63633 12.0001 7.63633C14.41 7.63633 16.3638 9.59005 16.3638 12.0001Z" stroke={color} strokeWidth="2.18183"/>
            <line x1="12.1684" y1="1.09083" x2="12.1684" y2="2.60143" stroke={color} strokeWidth="2.18183" strokeLinecap="round"/>
            <line x1="1.09092" y1="-1.09092" x2="2.60146" y2="-1.09092" transform="matrix(0.707098 0.707116 -0.707098 0.707116 17.5399 18.4603)" stroke={color} strokeWidth="2.18183" strokeLinecap="round"/>
            <line x1="1.09092" y1="-1.09092" x2="2.60146" y2="-1.09092" transform="matrix(-0.707098 0.707116 0.707098 0.707116 6.30472 18.4603)" stroke={color} strokeWidth="2.18183" strokeLinecap="round"/>
            <line x1="12.1684" y1="21.3985" x2="12.1684" y2="22.9091" stroke={color} strokeWidth="2.18183" strokeLinecap="round"/>
            <line x1="1.09092" y1="11.8317" x2="2.60142" y2="11.8317" stroke={color} strokeWidth="2.18183" strokeLinecap="round"/>
            <line x1="21.3986" y1="11.8317" x2="22.9091" y2="11.8317" stroke={color} strokeWidth="2.18183" strokeLinecap="round"/>
            <line x1="1.09092" y1="-1.09092" x2="2.60146" y2="-1.09092" transform="matrix(0.707098 -0.707116 0.707098 0.707116 18.4604 6.30465)" stroke={color} strokeWidth="2.18183" strokeLinecap="round"/>
            <line x1="1.09092" y1="-1.09092" x2="2.60146" y2="-1.09092" transform="matrix(-0.707098 -0.707116 -0.707098 0.707116 5.38 6.30465)" stroke={color} strokeWidth="2.18183" strokeLinecap="round"/>
            </svg>

        </div>
    )
}

export default SunIcon;