import './LoadConfirm.scss';
import { Loader } from '../Loaders';

const LoadConfirm = ({
    text,
    load,
    success,
    checkUuid
}) => {
    return (
        <div className="LoadConfirm">
            {
                load ? (
                    <div className="LoadConfirm__loader">
                        <Loader qr={checkUuid} />
                    </div>
                ) : <></>
            }
            
            <div className="LoadConfirm__text">
                {
                    load ? (
                        'Waiting for confirmation'
                    ) : (
                        success
                    )
                }
                
            </div>
            {
                load ? (
                    <div className="LoadConfirm__text">
                        {text}
                    </div>
                ) : <></>
            }
            
        </div>
    )
}

export default LoadConfirm;