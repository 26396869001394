import './BurnCreate.scss';
import StepLine from '../../../../components/StepLine/StepLine';
import { useState } from 'react';

//steps
import StepCur from '../../steps/StepCur/StepCur';
import StepPer from '../../steps/StepPer/StepPer';
import StepAm from '../../steps/StepAm/StepAm';
import StepCn from '../../steps/StepCn/StepCn';


import { useSelector } from 'react-redux';
import apiService from '../../../../service/apiService';
import { useEffect } from 'react';
import Button from '../../../../components/Button/Button';


const service = new apiService();

const burnStepLine = [
    {label: 'Currency', value: '1'},
    {label: 'Percent (%)', value: '2'},
    {label: 'Amount', value: '3'},
    {label: 'Confirm', value: '4'},
]


const BurnCreate = ({active, updateList, setReset, reset}) => {
    const {authData, lines, userCarats, userPower} = useSelector(state => state);
    const [currentStep, setCurrentStep] = useState(0)
    const [nextDisable, setNextDisable] = useState(true)
    const [stepChange, setStepChange] = useState(true)

    //burn pools list
    const [poolList, setPoolList] = useState([])
    const [selectedPool, setSelectedPool] = useState(null)
    const [selectedPercent, setSelectedPercent] = useState(null)
    const [count, setCount] = useState('')
    const [topNft, setTopNft] = useState('')
    const [pointsMultiplier, setPointsMultiplier] = useState(false)
    const [pointPrice, setPointPrice] = useState('')
    

    
    const declineProc = () => {
        setCurrentStep(0)
        setCount(null)
        setSelectedPercent(null)
        setSelectedPool(null)
        setNextDisable(true)
    }


    useEffect(() => {
        if(setReset) {
            if(reset) {
                declineProc()
            }
            setReset(false)
        }
        
        
    }, [reset, setReset])

    useEffect(() => {

        if(authData?.token && authData?.wallet) {
            service.getBurnPools().then(res => {
                const f = res.filter(i => i.nowPool !== 0 && i.nowPool > i.minBurn)
                setPoolList(f)
            })
            service.getTopNft(authData.wallet).then(res => {
                if(res != 'inf') {
                    setTopNft(Number(res.replace('x', '')))
                } else {
                    setTopNft(res)
                }
                
            })

            service.getPointsMultiplier(authData.wallet).then(res => {
                setPointsMultiplier(res)
            })
            
        }

        service.pointPrice().then(res => setPointPrice(res))
    }, [authData])


    useEffect(() => {
        if(!selectedPool) {
            setSelectedPercent(null)
            setCount('')
        }
    }, [selectedPool])


    useEffect(() => {

        if(currentStep == 0) {
            if(selectedPool == null) {
                setNextDisable(true)
            } else {
                setNextDisable(false)
            }
        }

        if(currentStep == 1) {
            if(selectedPercent == null) {
                setNextDisable(true)
            } else {
                if ((selectedPercent.needCarats > 0) || (selectedPercent.needPower > 0)) {
                    if ((selectedPercent.needCarats <= userCarats) && (selectedPercent.needPower <= userPower)) {
                        setNextDisable(false)
                    } else {
                        setNextDisable(true)
                    }
                } else {
                    setNextDisable(false)
                }
            }
        }

        if (currentStep == 2) {
            setNextDisable(true)
        }

        if (currentStep === 1 && selectedPool?.balance == 0) {
            setNextDisable(true)
        }

    }, [selectedPool, selectedPercent, currentStep])

    useEffect(() => {
        if(currentStep == 3) {
            setStepChange(false)
        }
    },[currentStep])

  

    return (
        <div className={"BurnCreate" + (active ? ' active ' : '')}>
            <div className="BurnCreate__steps">
                <StepLine
                    stepChange={true}
                    nextStep={() => setCurrentStep(state => state + 1)}
                    items={burnStepLine}
                    currentIndex={currentStep}
                    setCurrentStep={setCurrentStep}
                    nextDisable={nextDisable}
                    /> 
            </div>
            <div className="BurnCreate__body">


                <StepCur
                    setSelectedPercent={setSelectedPercent}
                    nextDisable={setNextDisable} 
                    selectedPool={selectedPool}
                    balanceList={lines}
                    poolList={poolList} 
                    selectPool={setSelectedPool}
                    active={currentStep == 0}/>

                    
                <StepPer 
                    topNft={topNft}
                    setTopNft={setTopNft}
                    selectPercent={setSelectedPercent}
                    selectedPercent={selectedPercent}
                    nextDisable={setNextDisable}
                    balance={selectedPool}
                    poolList={poolList}
                    active={currentStep == 1}/>

                <StepAm
                    pointPrice={pointPrice}
                    topNft={topNft}
                    pointsMultiplier={pointsMultiplier}
                    setTopNft={setTopNft}
                    selectedPercent={selectedPercent}
                    setCount={setCount}
                    count={count}
                    balance={selectedPool}
                    poolList={poolList}
                    setNextDisable={setNextDisable}
                    active={currentStep == 2}/>

                <StepCn
                    pointPrice={pointPrice}
                    topNft={topNft}
                    pointsMultiplier={pointsMultiplier}
                    setTopNft={setTopNft}
                    setStepChange={setStepChange}
                    updateList={updateList}
                    declineProc={declineProc}
                    count={count}
                    currentStep={currentStep}
                    selectedPercent={selectedPercent} 
                    active={currentStep == 3}/>
            </div>
            {
                currentStep != 3 ? (
                    <div className="BurnCreate__action">
                        <Button
                            text={'Next'}
                            disabled={nextDisable}
                            onClick={() => {
                                setCurrentStep(state => state + 1)
                            }}
                            variant={'default'}
                            />
                    </div>
                ) : null
            }
        </div>
    )
}

export default BurnCreate;