import '../../../burn/create/BurnCreate/BurnCreate.scss';
import StepLine from '../../../../components/StepLine/StepLine';
import { useState } from 'react';


import StepCn from '../../steps/StepCn/StepCn';
import StepCur from '../../steps/StepCur/StepCur';
import StepAm from '../../steps/StepAm/StepAm';
import StepPer from '../../steps/StepPer/StepPer';
import Button from '../../../../components/Button/Button';


import { useSelector } from 'react-redux';
import apiService from '../../../../service/apiService';
import { useEffect } from 'react';
import _ from 'lodash';


const service = new apiService();

const burnStepLine = [
    {label: 'Currency', value: '1'},
    {label: 'Percent (%)', value: '2'},
    {label: 'Amount', value: '3'},
    {label: 'Confirm', value: '4'},
]


const BurnCreate = ({active, updateList, reset, setReset, processList}) => {
    const {authData, lines, userCarats, userPower} = useSelector(state => state);
    const [currentStep, setCurrentStep] = useState(0)
    const [nextDisable, setNextDisable] = useState(true)
    const [stepChange, setStepChange] = useState(true)
    const [max, setMax] = useState(0);
    //burn pools list
    const [poolList, setPoolList] = useState([])
    const [selectedPool, setSelectedPool] = useState(null)
    const [selectedPercent, setSelectedPercent] = useState(null)
    const [count, setCount] = useState('')
    const [topNft, setTopNft] = useState('')
    const [pointPrice, setPointPrice] = useState('')
    
    const declineProc = () => {
        setCurrentStep(0)
        setCount(null)
        setSelectedPercent(null)
        setSelectedPool(null)
        setNextDisable(true)
    }

    useEffect(() => {
        if(processList?.length > 0 && selectedPool) {
            setMax(Number(selectedPool?.balance) - _.sum(processList.filter(item => item?.currencyCode_Symbol == selectedPool?.currency).map(item => Number(item.staked))))
        }
        if(selectedPool && processList?.length == 0) {
            setMax(selectedPool?.balance)
        }
    }, [processList, selectedPool])

    useEffect(() => {
        if(setReset) {
            if(reset) {
                declineProc()
            }
            setReset(false)
        }
    }, [setReset, reset])

    const getStakePoolsFn = () => {
        service.getStakePools().then(res => {
            const f = res.filter(i => i.nowPool !== 0 && i.nowPool > i.minStake)
            setPoolList(f) //aaaa
        })
    }

    useEffect(() => {
        if(authData?.token && authData?.wallet) {
            getStakePoolsFn()
            service.getTopNft(authData?.wallet).then(res => {
                if(res != 'inf') {
                    setTopNft(res.replace('x', ''))
                } else {
                    setTopNft(res)
                }
            })
            service.pointPrice().then(res => {
                setPointPrice(res)
            })
        }
    }, [authData])


    useEffect(() => {
        if(!selectedPool) {
            setSelectedPercent(null)
            setCount('')
        }
    }, [selectedPool])

    

    useEffect(() => {

        if(currentStep == 0) {
            if(selectedPool == null) {
                setNextDisable(true)
            } else {
                setNextDisable(false)
            }
        }

        if(currentStep == 1) {
            if(selectedPercent == null) {
                setNextDisable(true)
            } else if (selectedPercent.minStake > Number(selectedPool?.balance)) {
                setNextDisable(true)
            } else {
                 if ((selectedPercent.needCarats > 0) || (selectedPercent.needPower > 0)) {
                    if ((selectedPercent.needCarats <= userCarats) && (selectedPercent.needPower <= userPower)) {
                        setNextDisable(false)
                    } else {
                        setNextDisable(true)
                    }
                } else {
                    setNextDisable(false)
                }
            }
        }

        // if(currentStep == 2) {
            
        //     if(count == '' || ((Number(count) < Number(selectedPercent?.minStake) || count > Number(selectedPercent?.nowPool)) || (Number(count) > Number(max)))) {
        //         setNextDisable(true)
        //     } else {
        //         setNextDisable(false)
        //     }
        // }
        if (currentStep === 1 && selectedPool?.balance == 0) {
            setNextDisable(true)
        }

    }, [selectedPool, selectedPercent, currentStep, count, max])
    

    useEffect(() => {
        if(currentStep == 3) {
            setStepChange(false)
        }
    },[currentStep])

    return (
        <div className={"BurnCreate" + (active ? ' active ' : '')}>
            <div className="BurnCreate__steps">
                <StepLine
                    stepChange={stepChange}
                    nextStep={() => setCurrentStep(state => state + 1)}
                    items={burnStepLine}
                    currentIndex={currentStep}
                    setCurrentStep={setCurrentStep}
                    nextDisable={nextDisable}
                    /> 
            </div>
            <div className="BurnCreate__body">
                <StepCur
                    nextDisable={setNextDisable} 
                    selectedPool={selectedPool}
                    balanceList={lines}
                    poolList={poolList} 
                    setSelectedPercent={setSelectedPercent}
                    selectPool={setSelectedPool}
                    active={currentStep == 0}/>
                <StepPer 
                    
                    topNft={topNft}
                    setTopNft={setTopNft}
                    selectPercent={setSelectedPercent}
                    selectedPercent={selectedPercent}
                    nextDisable={setNextDisable}
                    balance={selectedPool}
                    poolList={poolList}
                    active={currentStep == 1}/>


                <StepAm
                    topNft={topNft}
                    processList={processList}
                    selectedPercent={selectedPercent}
                    setCount={setCount}
                    count={count}
                    balance={selectedPool}
                    poolList={poolList}
                    youCanStake={max}
                    pointPrice={pointPrice}
                    setNextDisable={setNextDisable}
                    active={currentStep == 2}/>
                <StepCn
                    pointPrice={pointPrice}
                    updateList={updateList}
                    declineProc={declineProc}
                    count={count}
                    currentStep={currentStep}
                    selectedPercent={selectedPercent} 
                    active={currentStep == 3}
                    getStakePoolsFn={getStakePoolsFn}/>
            </div>
            {
                currentStep != 3 ? (
                    <div className="BurnCreate__action">
                        <Button
                            text={'Next'}
                            disabled={nextDisable}
                            onClick={() => {
                                setCurrentStep(state => state + 1)
                            }}
                            variant={'default'}
                            />
                    </div>
                ) : null
            }
            
        </div>
    )
}

export default BurnCreate;