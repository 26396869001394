import './Input.scss';
import { IMaskInput } from 'react-imask';

const Input = ({
    placeholder,
    onChange,
    onBlur,
    value,
    disabled,
    width,
    mask
}) => {
  

    const handleChange = (e) => {
 
        onChange(e)
    }

    if(mask) {
        return (
            <div className={"Input" + (disabled ? ' disabled ' : '')} style={{width: width}}>
            <IMaskInput
                placeholder={placeholder}
                // onChange={e => handleChange(e)}
                onAccept={e => handleChange(e)}
                onBlur={onBlur}
                value={value}
                mapToRadix={['.', ',']}
                // radix={10}
                scale={15}
                unmask={true}
                mask={mask}
            />
            {/* <input 
                type="text" 
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={disabled}
                /> */}

        </div>
        )
    }


    return (
        <div className={"Input" + (disabled ? ' disabled ' : '')} style={{width: width}}>
            <input 
                type="text" 
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={disabled}
                />
        </div>
    )
}

export default Input;