import { Loader } from '../../../components/Loaders';
import { Trustline } from '../../../icons/trustline';
import { Twitter } from '../../../icons/twitter';
import { Website } from '../../../icons/website';
import './create.scss';

export const ThirdStep = ({active, selectedCollection, totalWeight, selectedNfts, stakeInfo}) => {
    return(
        <div className={`second-step third-step ${active ? '' : 'second-step_n'}`}>
            {
                stakeInfo === null ?
                <div className='third-step__confirm'>
                    <Loader /> 
                    <span className='third-step__confirm-waiting'>Waiting for confirmation</span>
                    <span className='third-step__confirm-open'>Confirm the transaction via XUMM app (open your app) no later than 10 min</span>
                </div>
                :
                <div className='third-step__status'>
                    <span className={stakeInfo?.error === true ? 'third-step__status-err' : 'third-step__status-status'}>{stakeInfo?.message}</span>
                </div>
            }
            
            
            <div className='third-step__bottom'>
                <div className='third-step__info'>
                    <div className='third-step__info-stake' />
                    <div className='third-step__info-get' />
                    <span className='third-step__info-stake-text'>You stake</span>
                    <span className='third-step__info-get-text'>You’ll get</span>
                    <span className='third-step__info-stake-value'>{selectedNfts.length} NFT</span>
                    <span className='third-step__info-get-value'>{totalWeight}x{selectedCollection?.dailyReward} {selectedCollection?.currencyCode}</span>
                </div>

                <div className='second-step__info'>
                    <span className='second-step__info-title'>NFT`s name info</span>
                    <div className='second-step__info-links'>
                        <a rel="noreferrer" target="_blank" href={selectedCollection?.twitter} className='StepPer__info_links_item'>
                            <Twitter />
                        </a>
                        <a rel="noreferrer" target="_blank" href={selectedCollection?.webSite} className='StepPer__info_links_item'>
                            <Website />
                        </a>
                        <a rel="noreferrer" target="_blank" href={selectedCollection?.tl} className='StepPer__info_links_item'>
                            <Trustline />
                        </a>
                    </div>
                    <div className='second-step__info-text'>
                        <span>Total Pool Size: {selectedCollection?.totalPoolSize}</span> <br /> 
                        <span>Available Pool Size: {selectedCollection?.availablePoolSize}</span><br /> 
                        <span>Daily Reward per 1 {selectedCollection?.weightUnit}: {selectedCollection?.dailyReward} {selectedCollection?.currencyCode}</span> <br/> <br/> 
                        {
                            selectedCollection?.pointsPerOneUnit ? <span>You need {totalWeight * selectedCollection?.pointsPerOneUnit} {selectedCollection?.currencyCode} 
                            points to stake this NFTs</span>
                            :
                            ''
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}