import './ResultItem.scss';


const ResultItem = ({
    icon,
    quote,
    base,
    onSelect
}) => {
    return (
        <div onClick={onSelect} className="ResultItem">
            <div className="ResultItem__icon">
                <img src={`https://xumm.app/avatar/${base?.issuer}_250_20.png`} alt="" />
            </div>
            <div className="ResultItem__name">
                {base?.currency}
            </div>
        </div>
    )
}

export default ResultItem;