import './StepCur.scss';
import TokenList from '../../../../components/TokenList/TokenList';
import { useEffect } from 'react';


const StepCur = ({
    active, 
    poolList,
    balanceList,
    selectedPool,
    selectPool,
    setSelectedPercent
}) => {



    return (
        <div className={"StepCur" + (active ? ' active ': '')}>
            <h2 className="step__head nd-step__head">Currency</h2>
            <TokenList
                setSelectedPercent={setSelectedPercent}
                selectPool={selectPool}
                tokenList={poolList}
                balanceList={balanceList}
                selectedPool={selectedPool}
                />
        </div>
    )
}

export default StepCur;


