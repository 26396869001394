import './StatModal.scss';
import { Modal } from 'antd';
import Button from '../Button/Button';
import {Row, Col} from 'antd';
import { useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import * as _ from 'lodash';

const fake = [
    {
      "nfTokenID": "string",
      "issuer": "string",
      "currencyCode": "string",
      "currencyCode_HEX": "string",
      "linkToMedia": "string",
      "name": "string",
      "weight": 4,
      "daily_For_Weight": 0,
      "daily": 0,
      "earned": 4,
      "wallet": "string",
      "random": 0,
      "poolIndex": 0,
      "timeStart": "2023-03-19T13:25:42.264Z",
      "timeEnd": "2023-03-19T13:25:42.264Z"
    }
  ]

const StatModal = ({
    visible,
    close,
    type = 'burned'
}) => {
    const {authData, userData} = useSelector(state => state);
    const [list, setList] = useState([])

    useEffect(() => {
        if(type == 'burned' && userData?.Burned?.length > 0) {
            let map = userData.Burned.reduce((r, i) => {
                r[i.currencyCode_Symbol] = r[i.currencyCode_Symbol] || [];
                r[i.currencyCode_Symbol].push(i); 
                return r;
            }, {});
            let arr1 = [];
            for (var key in map) {
                arr1.push(map[key]);
            }
            setList(arr1)
        }
        if(type == 'staked' && userData?.Staked?.length > 0) {
            
            let map = userData.Staked.reduce((r, i) => {
                r[i.currencyCode_Symbol] = r[i.currencyCode_Symbol] || [];
                r[i.currencyCode_Symbol].push(i); 
                return r;
            }, {});
            let arr1 = [];
            for (var key in map) {
                arr1.push(map[key]);
            }
            setList(arr1)
        }
        if(type == 'nft' && userData?.NFTStaked?.length > 0) {
        // if(type == 'nft' && fake?.length > 0) {  
            let map = userData?.NFTStaked?.reduce((r, i) => {
                r[i.currencyCode] = r[i.currencyCode] || [];
                r[i.currencyCode].push(i); 
                return r;
            }, {});
            let arr1 = [];
            for (var key in map) {
                arr1.push(map[key]);
            }
            setList(arr1)
        }

    }, [userData, type])

    const closeHandle = () => {
        close()
    }

    return (
        <Modal
            open={visible}
            onCancel={closeHandle}
            className='modal StatModal'
            >
            <div className="modal__body">
                <h2 className="StatModal__head modal__body_head">
                    Statistics
                </h2>
                <Row gutter={[10,10]}>

                    <Col span={24}>
                        <div className="StatModal__top">
                            Date ({moment(Date.now()).utc().format('DD.MM.YYYY HH:MM')})
                        </div>
                        
                    </Col>
                    <Col 
                        span={24}
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'
                        }}
                        >
                        <div className="StatModal__top">
                            Wallet number ({authData?.wallet})
                        </div>
                        
                    </Col>
                    <Col span={24}>
                        {
                            type == 'burned' ? (
                                <div className="StatModal__body">
                                    <Row gutter={[5,5]}>
                                    
                                        {
                                            list?.length > 0 ? (
                                                list.map((item, index) => (
                                                    <Col key={index} span={24} style={{borderBottom: '1px solid var(--text)'}}>
                                                        <Row gutter={[5,5]}>
                                                            <Col span={24}>{item[0].currencyCode_Symbol}</Col>
                                                            <Col span={24}>
                                                                <span>Total burn: </span>
                                                                <span>{_.round(_.sum(item.map(i => Number(i.burned))), 4)}</span>
                                                            </Col>
                                                            <Col span={24}>
                                                                <span>Total earn: </span>
                                                                <span>{_.round(_.sum(item.map(i => Number(i.earned))), 4)}</span>
                                                            </Col>
                                                            <Col span={24}>
                                                                <span>You'll earn: </span>
                                                                <span>{_.round(_.sum(item.map(i => Number(i.reward) * Number(i.maxRemainingReward))), 4)}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )) 
                                            ) : null
                                        }
                                    </Row>
                                </div>
                            ) : null
                        }
                        {
                            type == 'staked' ? (
                                <div className="StatModal__body">
                                    <Row gutter={[5,5]}>
                                    
                                        {
                                            list?.length > 0 ? (
                                                list.map((item) => (
                                                    <Col span={24} style={{borderBottom: '1px solid var(--text)'}}>
                                                        <Row gutter={[5,5]}>
                                                            <Col span={24}>{item[0].currencyCode_Symbol}</Col>
                                                            <Col span={24}>
                                                                <span>Total stake: </span>
                                                                <span>{_.sum(item.map(i => Number(i.staked)))}</span>
                                                            </Col>
                                                            <Col span={24}>
                                                                <span>Total earn: </span>
                                                                <span>{_.sum(item.map(i => Number(i.earned)))}</span>
                                                            </Col>
                                                            <Col span={24}>
                                                                <span>You'll earn: </span>
                                                                <span>{_.sum(item.map(i => i.reward * i.maxRemainingReward))}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )) 
                                            ) : null
                                        }
                                    </Row>
                                </div>
                            ) : type == 'nft' ? <div className="StatModal__body">
                                    <Row gutter={[5,5]}>
                                        {
                                            list?.length > 0 ? (
                                                list.map((item) => (
                                                    <Col span={24} style={{borderBottom: '1px solid var(--text)'}}>
                                                        <Row gutter={[5,5]}>
                                                            <Col span={24}>{item[0].currencyCode}</Col>
                                                            {/* <Col span={24}>
                                                                <span>Total stake: </span>
                                                                <span>{_.sum(item.map(i => Number(i.staked)))}</span>
                                                            </Col> */}
                                                            <Col span={24}>
                                                                <span>Total earn: </span>
                                                                <span>{_.sum(item.map(i => Number(i.earned)))}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )) 
                                            ) : null
                                        }
                                    </Row>
                                </div> : null
                        }
                    </Col>
                </Row>
                
            </div>
            {/* <div className="modal__action StatModal__action">
                <Button
                    text={'Download'}
                    variant={'default'}
                    />
            </div> */}
        </Modal>
    )
}

export default StatModal;