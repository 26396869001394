import './icon.scss';



const StakingIcon = ({color = 'var(--gray1)', size = 25}) => {

    return (
        <div className="icon StakingIcon" style={{width: size, height: size}}>
           <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.36787 6.72043L8.13221 3.05296C8.61128 2.68419 9.38799 2.68419 9.86705 3.05296L14.6314 6.72043C15.1105 7.0892 15.1105 7.6871 14.6314 8.05587L14.1184 8.45074L10.581 5.72767C9.70802 5.0557 8.29271 5.05571 7.41977 5.72767L3.88157 8.4513L3.36788 8.05587C2.88881 7.6871 2.88881 7.0892 3.36787 6.72043ZM3.16839 9.00028L2.6547 8.60486C1.78177 7.93289 1.78176 6.84342 2.6547 6.17145L7.41904 2.50398C8.29198 1.83201 9.70729 1.83201 10.5802 2.50397L15.3446 6.17145C16.2175 6.84341 16.2175 7.93289 15.3446 8.60485L14.8316 8.99972L15.3453 9.39514C16.2182 10.0671 16.2182 11.1566 15.3453 11.8286L10.581 15.496C9.70802 16.168 8.29271 16.168 7.41978 15.496L2.65544 11.8286C1.7825 11.1566 1.7825 10.0671 2.65543 9.39515L3.16839 9.00028ZM14.1184 9.5487L14.6321 9.94413C15.1112 10.3129 15.1112 10.9108 14.6321 11.2796L9.86779 14.947C9.38872 15.3158 8.61201 15.3158 8.13295 14.947L3.36861 11.2796C2.88954 10.9108 2.88954 10.3129 3.36861 9.94413L3.88157 9.54926L7.41904 12.2723C8.29198 12.9443 9.70729 12.9443 10.5802 12.2723L14.1184 9.5487ZM13.4053 8.99972L9.86706 11.7233C9.38799 12.0921 8.61128 12.0921 8.13222 11.7233L4.59474 9.00028L8.13294 6.27665C8.61201 5.90788 9.38873 5.90788 9.86779 6.27665L13.4053 8.99972Z" fill={color}/>
            </svg>

        </div>
    )
}

export default StakingIcon;