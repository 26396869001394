import './RlInfo.scss';
import { Row, Col } from 'antd';

import {useRef, useState} from 'react';
import Button from '../../../../components/Button/Button';
import {CgSearch} from 'react-icons/cg';
import RichSearch from '../../modals/RichSearch/RichSearch';
import CreateSnapshot from '../../../../components/CreateSnapshot/CreateSnapshot';
import { useEffect } from 'react';
import ReactApexChart from "react-apexcharts";
import RlChart from './components/RlChart/RlChart';



const _ = require('lodash')


const chartColors = ['#FF6A6A', '#57B256', '#6AB6FC', '#615BA5', '#5E595F', '#B7B877', '#D9B2B2', '#EE8437', '#2FB4C6','#C6C6CF'];




const RlInfo = ({info, list, coin}) => {
    const [search, setSearch] = useState(false)
    const [createSSModal, setCreateSSModal] = useState(false)
    const [chartData, setChartData] = useState(null)
    const [selectedName, setSelectedName] = useState('Total')
    const [selectedValue, setSelectedValue] = useState('100%')
    const [selectedColor, setSelectedColor] = useState('var(--text)')



    const openSearch = () => setSearch(true)
    const closeSearch = () => setSearch(false)

    const openCreateSSModal = () => setCreateSSModal(true)
    const closeCreateSSModal = () => setCreateSSModal(false)

    const naTr = (val) => {
        if(val) {
          
            return val.replace('-','')
        }
    }


    useEffect(() => {
        if(list?.length > 0 && coin && info) {
           
            const main = list.slice(0, 9)
            const others = list.slice(9, list.length)

            let othersSum = _.sum(others.map(item => Number(item.state.percent)))
            const mainSum = _.sum(main.map(item => Number(item.state.percent)))
            othersSum = othersSum + (100 - (othersSum + mainSum))

            const dData = [...main.map(item => Number(item.state.percent)), othersSum]
            const lb = [...main.map(item => item.specification.counterparty), 'Others']

            setChartData({
                labels: lb,
                series: dData
            })
            setSelectedColor('var(--text)')
            setSelectedName('Total')
            setSelectedValue('100%')
        }
    }, [list, info, coin])


    
    
    return (
        <div className="RlInfo">
            <CreateSnapshot visible={createSSModal} close={closeCreateSSModal}/>
            <RichSearch visible={search} close={closeSearch}/>
            <Row 
                // gutter={{xs: [0, 10], sm: [0,10], md: [20,20], lg: [0, 20]}}
                gutter={[20,20]}
                
                >
                <Col
                    className='RlInfo__col'
                    xs={24}
                    sm={24}
                    xl={24}
                    >
                    <div className="RlInfo__hh">
                        <div className="RlInfo__hh_title md-title">Richlist</div>
                        <div className="RlInfo__hh_coin md-title">{coin}</div>
                    </div>
                    
                </Col>
                <Col 
                    className='RlInfo__col' 
                    xs={24}
                    sm={12}
                    xl={24}
                    >
                    <div className="RlInfo__snapshot">
                        <button onClick={openCreateSSModal} className="RlInfo__snapshot_btn">
                            Create SnapShot
                        </button>
                    </div>
                </Col>
                <Col
                    className='RlInfo__col'
                    xs={24}
                    sm={12}
                    xl={24}
                    >
                    <div className="RlInfo__search">
                        <Button 
                            onClick={openSearch}
                            beforeIcon={<CgSearch/>}
                            text={'Search coins'}
                            style={{width: '100%'}}
                            />
                    </div>
                </Col>
                
                <Col
                    className='RlInfo__col' 
                    xs={24}
                    sm={24}
                    md={12}
                    xl={24}
                    >
                    <div className="RlInfo__part RlInfo__descr">
                        <div className="RlInfo__descr_part">
                            <div className="RlInfo__descr_part_head">Information</div>
                            <div className="RlInfo__descr_part_body">
                                {/* <div className="RlInfo__descr_part_body_item">
                                    <div className="RlInfo__descr_part_body_item_name">Last update</div>
                                    <div className="RlInfo__descr_part_body_item_value">07.08.2022</div>
                                </div> */}
                                {/* <div className="RlInfo__descr_part_body_item">
                                    <div className="RlInfo__descr_part_body_item_name">Updated interval</div>
                                    <div className="RlInfo__descr_part_body_item_value">24 hours</div>
                                </div> */}
                                <div className="RlInfo__descr_part_body_item">
                                    <div className="RlInfo__descr_part_body_item_name">Price</div>
                                    <div className="RlInfo__descr_part_body_item_value">
                                        
                                    XRP {_.round(Number(info?.price), 15)} 
                                    </div>
                                </div>
                                {/* <div className="RlInfo__descr_part_body_item">
                                    <div className="RlInfo__descr_part_body_item_name">Status</div>
                                    <div className="RlInfo__descr_part_body_item_value">+ 0.05 $</div>
                                </div> */}
                            </div>
                        </div>
                        <div className="RlInfo__descr_part">
                            <div className="RlInfo__descr_part_head">My status</div>
                            <div className="RlInfo__descr_part_body">
                                <div className="RlInfo__descr_part_body_item">
                                    <div className="RlInfo__descr_part_body_item_name">Balance</div>
                                    <div className="RlInfo__descr_part_body_item_value">{naTr(info?.myBalance)}</div>
                                </div> 
                                <div className="RlInfo__descr_part_body_item">
                                    <div className="RlInfo__descr_part_body_item_name">%</div>
                                    <div className="RlInfo__descr_part_body_item_value">{naTr(info?.myProcent)}</div>
                                </div>
                                <div className="RlInfo__descr_part_body_item">
                                    <div className="RlInfo__descr_part_body_item_name">Value in XRP</div>
                                    <div className="RlInfo__descr_part_body_item_value">{`${info?.myBalance != 'N/A' ? _.round(Number(info?.myBalance) * Number(info?.price), 6) : 'N/A'} ${info?.myBalance != 'N/A' ? 'XRP' : ''}`}</div>
                                </div>
                                <div className="RlInfo__descr_part_body_item">
                                    <div className="RlInfo__descr_part_body_item_name">Position</div>
                                    <div className="RlInfo__descr_part_body_item_value">{info?.myNumber}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col
                    className='RlInfo__col' 
                    xs={24}
                    sm={24}
                    md={12}
                    xl={24}
                    >
                    <div className="RlInfo__part RlInfo__dg">
                        <div className="RlInfo__dg_head">Rating of holders</div>
                        <div className="RlInfo__dg_body">
                            {
                                chartData ? (
                                    <ReactApexChart
                                        // width={'100%'}
                                        // height={'100%'}
                                        
                                        type='donut'
                                        options={{
                                            
                                            colors: chartColors,
                                            stroke: {
                                                show: false
                                            },
                                            tooltip: {
                                                enabled: false
                                            },
                                            legend: {
                                                show: false,
                                                // width: 100,
                                                position: 'bottom',
                                                horizontalAlign: 'left'
                                            },
                                            dataLabels: {
                                                enabled : false,
                                            },
                                            labels:chartData?.labels,
                                            series:{
                                                data: chartData?.series
                                            },
                                            plotOptions: {
                                                pie: {
                                                    donut: {
                                                        labels: {
                                                            show: true,
                                                            name: {
                                                                formatter: (e, i, c) => {
                                                                    if(e != 'Others') {
                                                                        const transformItem = e.slice(0, 10) + '...' + e.slice(-5);
                                                                        return transformItem;
                                                                    }
                                                                    return e;
                                                                },
                                                                fontSize: 12,
                                                                color: 'var(--text)'
                                                            },
                                                            value: {
                                                                color: 'var(--text)',
                                                                formatter: (e) => {
                                                                    return _.round(e, 2) + '%'
                                                                }
                                                            },
                                                            
                                                        },
                                                        
                                                    }
                                                }
                                            },
                                            chart: {
                                                // selection: {
                                                //     enabled: true
                                                // },
                                                
                                            }

                                        }}
                                        labels={chartData?.labels}
                                        series={chartData?.series}
                                        />
                                ) : null
                            }
                           {/* <RlChart
                            coin={coin}
                            list={list}
                            info={info}
                            /> */}
                        </div>
                    </div>
                </Col>
                
            </Row>
        </div>
    )
}

export default RlInfo;