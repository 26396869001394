import './TabNft.scss';
import { useState, useEffect } from 'react';
import { Row, Col, message } from 'antd';
import Input from '../../../Input/Input';
import Button from '../../../Button/Button';
import { useSelector } from 'react-redux';
import apiService from '../../../../service/apiService';



const service = new apiService()


const TabNft = ({close, updateList}) => {
    const {authData} = useSelector(state => state)
    const [load, setLoad] = useState(false)
    const [issuer, setIssuer] = useState('')
    const [label, setLabel] = useState('')

    const closeHandle = () => {
        setIssuer('')
        setLabel('')
        close()
    }
    const onSave = () => {
        setLoad(true)
        const {token, wallet} = authData;
        // console.log('token', token)
        // console.log('wallet', wallet)
        // console.log(issuer)
        // console.log(label)
        service.makeSnapshot(wallet, token, issuer, label ? label : 'new-snapshot').then(res => {
            if(res.status == 200) {
                closeHandle()
                message.success('Snapshot created')
                if(updateList) {
                    updateList()
                }
            } else {
                message.error('An error has occurred, please check your details')
            }
        }).finally(_ => {
            setLoad(false)
        })
    }


    return (
        <div className="TabNft">
            <Row gutter={[0, 50]}>
                <Col span={24}>
                    <Row gutter={[20, 20]}>
                        <Col span={24}>
                            <Input
                                placeholder={'Issuer'}
                                value={issuer}
                                onChange={e => setIssuer(e.target.value)}
                                />
                        </Col>
                        <Col span={24}>
                            <Input
                                placeholder={'Snapshot name'}
                                value={label}
                                onChange={e => setLabel(e.target.value)}
                                />
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                        text={'Create'}
                        variant={'default'}
                        onClick={onSave}
                        disabled={!issuer}
                        load={load}
                        />
                </Col>
            </Row>
        </div>
    )
}

export default TabNft;