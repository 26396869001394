import './SbInfo.scss';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as _ from 'lodash';
import { useState } from 'react';
import apiService from '../../service/apiService';


const service = new apiService();

const SbInfo = ({data}) => {
    const lines = useSelector(state => state.lines);
    const userData = useSelector(state => state.userData);
    const authData = useSelector(state => state.authData);
    const xrp = useSelector(state => state.xrp);
    const userCarats = useSelector(state => state.userCarats);
    const userPower = useSelector(state => state.userPower);
    const [balance, setBalance] = useState(0);
    const [nft, setNft]= useState('');

    useEffect(() => {
        if(lines?.length > 0) {
            setBalance(lines.filter(item => item?.currency == 'RLT').map(item => Number(item.balance)).reduce((a, b) => a + b, 0))
        }
    }, [lines, userData])

    useEffect(() => {
        if(authData?.wallet) {
            service.getTopNft(authData?.wallet).then(res => {
                if(res == 'x1') {
                    setNft('No')
                } else {
                    setNft('Yes')
                }
            })
        } 
    }, [authData])
    return (
        <div className="SbInfo">
            <div className="SbInfo__in">
                <h3 className="SbInfo__head">My status</h3>
                <div className="SbInfo__item">
                    <div className="SbInfo__item_name">RLT balance</div>
                    <div className="SbInfo__item_value">{_.round(balance, 3)}</div>
                </div>
                <div className="SbInfo__item">
                    <div className="SbInfo__item_name">RLT points</div>
                    <div className="SbInfo__item_value">{_.round(userData?.Points, 3)}</div>
                </div>
                <div className="SbInfo__item">
                    <div className="SbInfo__item_name">NFT</div>
                    <div className="SbInfo__item_value">{nft}</div>
                </div>
                <div className="SbInfo__item">
                    <div className="SbInfo__item_name">Karats</div>
                    <div className="SbInfo__item_value">{userCarats}</div>
                </div>
                <div className="SbInfo__item">
                    <div className="SbInfo__item_name">Power</div>
                    <div className="SbInfo__item_value">{userPower}</div>
                </div>
                <div className="SbInfo__item">
                    <div className="SbInfo__item_name">XRP Balance</div>
                    <div className="SbInfo__item_value">{xrp?.freeXrpBalance.toFixed(6)}</div>
                </div>
                <div className="SbInfo__item">
                    <div className="SbInfo__item_name">Locked Balance</div>
                    <div className="SbInfo__item_value">{xrp?.lockedBalance}</div>
                </div>
            </div>
        </div>
    )
}

export default SbInfo;