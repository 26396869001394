import { useSelector } from 'react-redux';
import './icon.scss';



const VoteIcon = ({color = 'var(--gray1)', size = 25}) => {

    const { theme } = useSelector(s => s)

    return (
        theme === 'dark' ?
        <div className="icon StakingIcon" style={{width: size, height: size}}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9C6.5 9 7.79759 11.7003 8.5 11.0001C9.99984 9.50483 12.0985 6.23677 12.0985 6.23677" stroke="#E1E1E5"/>
                <circle cx="9" cy="9" r="6.6" stroke="#E1E1E5" strokeWidth="0.8"/>
            </svg>
        </div>
        :
        <div className="icon StakingIcon" style={{width: size, height: size}}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.50024 9C6.50024 9 7.79784 11.7003 8.50024 11.0001C10.0001 9.50483 12.0987 6.23677 12.0987 6.23677" stroke="#1A1A1C"/>
                <circle cx="9" cy="9" r="6.6" stroke="#1A1A1C" strokeWidth="0.8"/>
            </svg>
        </div>
    )
}

export default VoteIcon;