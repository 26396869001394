import dropsMock from "../../helpers/dropsMock";
import AirDropItem from "../AirDropItem/AirDropItem";
import HistoryItem from "../AirDropItem/HistoryItem";
import AirDropEmpty from "../AirDropEmpty/AirDropEmpty";
import { useSelector } from "react-redux";
import apiService from "../../../../service/apiService";


const service = new apiService();


const HistoryTab = ({open, list, updateList}) => {
    const {authData} = useSelector(state => state)


    const deleteDrop = (index_drop) => {
        
        service.deleteAirdrop(authData.wallet, authData.token, index_drop).then(res => {
            if(res == 200) {
                updateList()
            } else {
             
            }
        })
    }


    const repeatDrop = (item) => {
        const body = {
            index_drop: item.index_drop,
            label: item.label,
            procentTokens: item.ProcentTokens ? `*${item.ProcentTokens}` : null,
            countTokens: item.CountTokens,
            address: item.Address,
            secret: item.Secret,
            issuer: item.Issuer,
            asset: item.Asset,
            memo: item.Memo,
            onlyKyc: item.onlyKyc,
            // onlyTl: onlyTl,
            onlyIssuer: item.onlyIssuer,
            snapshotIndex: item.snapshotIndex,

            timeStart: item.timeStart,
            discountName: item.discountName,
            blackList: null,
            old_index_drop: null
        }

        service.createAirdrop(authData?.wallet, authData?.token, body).then(res => {
            if(res.status == 200) {
                updateList()
            } else {
                console.log('error when repeat drop')
                //error
            }
        })
    }



    if(open) {
        return (
            
                list && list.length > 0 ? (
                    list.map((item, index) => (
                        <div className="AirDrop__tabs_content_item" key={index}>
                            <HistoryItem 
                                {...item}
                                onDelete={deleteDrop}
                                repeatDrop={() => repeatDrop(item)}
                                // change={change} 
                                />
                        </div>
                    ))
                ) : <AirDropEmpty/>
            
            
        )
    }
}

export default HistoryTab;