import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Header from '../../components/Header/Header'
import { Twitter } from '../../icons/twitter';
import './voting.scss';
import arrow from '../../assets/icons/arrow.svg';
import { useSelector } from 'react-redux';
import { Loader } from '../../components/Loaders';
import apiService from '../../service/apiService';
import moment from 'moment';

const service = new apiService();
const colors = ['#FF6A6A', '#57B256', '#6AB6FC', '#615BA5', '#6f387a', '#B7B877', '#D9B2B2', '#EE8437', '#2FB4C6','#C6C6CF']

export const Voting = () => {

    const [isFilterOpen, setFilterOpen] = useState(false);
    const [data, setData] = useState(null);
    const [wallets, setWallets] = useState([]);
    const [filter, setFilter] = useState(['All']);

    const [isEnd, setEnd] = useState(false);
    const [untilEnd, setUntilEnd] = useState(0);

    const { theme, authData } = useSelector(s => s);

    const isMobile = window?.innerWidth < 530;
    
    const [transactionId, setTransactionId] = useState('');
    const [isLoading, setLoading] = useState(true);
    const [isModalOpen, setModalOpen] = useState(false);
    const [transactionData, setTransactionData] = useState({
        data: undefined,
        try: 0
    }); 

    useEffect(() => {
        service.GetVote().then(res => {
            if (res.votes) {
                const votes = res.votes.map((vote, i) => ({
                    ...vote,
                    color: colors[i] ?? colors[Math.floor(Math.random() * 10) + 1]
                }))
                setData({
                    ...res,
                    votes
                })

                let newWallets = []
                votes.forEach(vote => {
                    Object.keys(vote.wallets).forEach(wallet => {
                        const obj = {
                            name: wallet,
                            balance: vote.wallets[wallet],
                            color: vote.color,
                            voteFor: vote.name
                        };
                        newWallets.push(obj)
                    })
                })
                setWallets([...newWallets])
                newWallets = []
            } 
        })
    }, [])

    useEffect(() => {
        let interval;
        if (data) {
            const dateEnd = new Date(data?.timeEnd).getTime();
            const dateNow = new Date().getTime()
            if (dateNow > dateEnd) {
                setEnd(true);
            } else {
                setUntilEnd(dateEnd - dateNow);
                setEnd(false);
                interval = setInterval(() => {
                    setUntilEnd(a => a - 1000)
                }, 1000)
            }
        }
        return () => {
            clearInterval(interval)
        }
    }, [data])

    useEffect(() => {
        if (untilEnd < 1000) {
            setEnd(true)
        }
    }, [untilEnd])

    const startVoting = async (index) => {
        setModalOpen(true);
        await service.VoteTransaction(authData.token, authData.wallet, index).then(res => {
            setTransactionId(res);
        });
    };

    useEffect(() => {
        if (transactionId) {
            setTimeout(() => {
                service.checkBurnTrans(transactionId).then(data => {
                    setTransactionData({
                        data: data === undefined ? null : data,
                        try: transactionData.try + 1
                    })
                })
            }, 15000)
        }
    }, [transactionId])

    useEffect(() => {
        if (transactionData.data !== undefined) {
            if (transactionData.data === null) {
                setTimeout(() => {
                    service.checkBurnTrans(transactionId).then(data => {
                        setTransactionData({
                            data: data === undefined ? null : data,
                            try: transactionData.try + 1
                        })
                    })
                }, 15000)
            } else {
                setLoading(false);
            }
        }
    }, [transactionData])

    const closeModal = () => {
        setTransactionData({
            data: undefined,
            try: 0
        });
        setLoading(true);
        setModalOpen(false);
        setTransactionId('');
        service.GetVote().then(res => {
            if (res.votes) {
                const votes = res.votes.map((vote, i) => ({
                    ...vote,
                    color: colors[i] ?? colors[Math.floor(Math.random() * 10) + 1]
                }))
                setData({
                    ...res,
                    votes
                })
                let newWallets = []
                votes.forEach(vote => {
                    Object.keys(vote.wallets).forEach(wallet => {
                        const obj = {
                            name: wallet,
                            balance: vote.wallets[wallet],
                            color: vote.color,
                            voteFor: vote.name
                        };
                        newWallets.push(obj)
                    })
                })
                setWallets([...newWallets])
                newWallets = []
            } 
        })
    }

    return(
        data ?
        <div className={`voting ${theme === 'light' ? 'voting_l' : ''}`}>
            <Header />
            {
                isModalOpen ?
                <div className='voting__confirm'>
                    <div className='voting__confirm-overlay' onClick={isLoading ? () => '' : closeModal} />
                    <div className='voting__confirm-block'>
                        <span className='voting__confirm-title'>Confirm your vote</span>
                        {
                            isLoading ? <Loader /> : ''
                        }
                        {
                            isLoading ? <span className='voting__confirm-wait'>Waiting for confirmation</span> : ''
                        }
                        {
                            isLoading ? <span className='voting__confirm-text'>Confirm the transaction via XUMM app (open your app) no later than 10 min</span> 
                            :
                            transactionData.data ?
                            <span className='voting__confirm-text'>You have successfully voted!</span>
                            :
                            <span className='voting__confirm-text voting__confirm-text_err'>Failed to confirm transaction, vote not counted. Retry the transaction</span>
                        }
                    </div>
                </div>
                :
                ''
            }
            <div className='voting__container'>
                <span className='voting__title'>{data?.header}</span>
                <div className='voting__top'>
                    <div>
                        <span className='voting__text'>
                           {data?.description}
                        </span>
                    </div>
                    <div>
                        <span className='voting__text'>Every {data?.votePrice} {data?.voteCurrency ?? 'RLT'} on your account balance = 1 vote</span> <br/>
                        {
                            isEnd ?
                            <span className='voting__text'>voting is over</span>
                            :
                            <span className='voting__text'>
                                Until the end of voting: 
                                {moment.duration(untilEnd, 'milliseconds').months() >= 1 ? ` ${moment.duration(untilEnd, 'milliseconds').months()} month${moment.duration(untilEnd, 'milliseconds').months() > 1 ? 's' : ''}` : ''}
                                {moment.duration(untilEnd, 'milliseconds').days() >= 1 ? ` ${moment.duration(untilEnd, 'milliseconds').days()} day${moment.duration(untilEnd, 'milliseconds').days() > 1 ? 's' : ''}` : ''}
                                {moment.duration(untilEnd, 'milliseconds').hours() >= 1 ? ` ${moment.duration(untilEnd, 'milliseconds').hours()} hrs` : ''}
                                {moment.duration(untilEnd, 'milliseconds').minutes() >= 1 ? ` ${moment.duration(untilEnd, 'milliseconds').minutes()} min` : ''}
                                {moment.duration(untilEnd, 'milliseconds').seconds() >= 1 ? ` ${moment.duration(untilEnd, 'milliseconds').seconds()} sec` : ''}
                            </span>
                            //<span className='voting__text'>Voting will end: {moment(data?.timeEnd).format('MMMM Do YYYY, h:mm')} </span>
                        }
                    </div>
                </div>

                <div className='voting__bottom'>
                    <div className='voting__left'>
                        <span className='voting__left-pools'>Pools</span>
                        <ReactApexChart
                            width={'220px'}
                            height={'220px'}
                            className="voting__left-diagram"
                            type='donut'
                            options={{
                                colors: data?.votes.map(v => v.color),
                                stroke: {
                                    show: false
                                },
                                tooltip: {
                                    enabled: false
                                },
                                legend: {
                                    show: false,
                                    // width: 100,
                                    position: 'bottom',
                                    horizontalAlign: 'left'
                                },
                                dataLabels: {
                                    enabled: false,
                                },
                                labels: data?.votes.map(v => v.name),
                                series: {
                                    data: data?.votes.map(v => v.percent * 100)
                                },
                                plotOptions: {
                                    pie: {
                                        donut: {
                                            labels: {
                                                show: true,
                                                name: {
                                                    formatter: (e) => {
                                                        return e;
                                                    },
                                                    fontSize: 15,
                                                    color: 'var(--text)',
                                                },
                                                value: {
                                                    color: 'var(--text)',
                                                    formatter: (e) => {
                                                        return (Number(e) < 1 ? Number(e).toFixed(2) : Math.floor(Number(e))) + '%'
                                                    },
                                                    fontSize: 15
                                                },

                                            },

                                        }
                                    }
                                },
                            }}
                            labels={data?.votes.map(v => v.name)}
                            series={data?.votes.map(v => v.percent * 100)}
                        />
                        <div className='voting__left-container'>
                            <div className='voting__left-list'>
                                {
                                    data?.votes.sort((a, b) => b?.percent - a?.percent).map((vote) => (
                                        <div className='voting__element' key={vote.index}>
                                            <div className='voting__element-top'>
                                                <div onClick={isEnd ? () => '' : Object.keys(vote.wallets).includes(authData.wallet) ? () => '': () => startVoting(vote.index)}>
                                                    <div className={`voting__element-check ${Object.keys(vote.wallets).includes(authData.wallet) ? 'voting__element-check_c' : ''}`} />
                                                    <span>{vote.name}</span>
                                                </div>
                                                <a target="_blank" href={vote.twitter}>
                                                    <Twitter tooltip={false} />
                                                </a>
                                            </div>
                                            <div className='voting__element-progress'>
                                                <div
                                                    style={{width: `${Math.floor(vote.percent * 100)}%`, background: vote.color}}
                                                />
                                            </div>
                                            <div className='voting__element-bottom'>
                                                <span>{(vote.percent * 100) < 1 ? (vote.percent * 100).toFixed(2) : Math.floor(vote.percent * 100)}%</span>
                                                <span>{vote.voteCount} votes</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div className='voting__right'>
                        <div className='voting__right-top'>
                            <div className='voting__drop' onClick={() => setFilterOpen(b => !b)}>
                                <span className='voting__right-top-votes'>Votes</span>
                                <img alt="" src={arrow} className={`voting__right-arrow ${!isFilterOpen ? 'voting__right-arrow_opened' : ''}`} />
                            </div>
                            {
                                    isFilterOpen ? 
                                    <div className='voting__drop-menu'>
                                        <div className='voting__drop-elem' onClick={() => {
                                            if (!filter.includes('All')) {
                                                setFilter(['All'])
                                            }
                                        }}>
                                            <div className={`voting__drop-check ${filter.includes('All') ? 'voting__drop-check_c' : ''}`} />
                                            <span>All</span>
                                        </div>
                                        {
                                            data.votes.map(vote => (
                                                <div className='voting__drop-elem' key={vote.index} onClick={() => {
                                                    if (!filter.includes(vote.name)) {
                                                        setFilter([...filter.filter(f => f !== 'All'), vote.name])
                                                    } else {
                                                        if (filter.length > 1) {
                                                            setFilter(filter.filter(f => f !== vote.name))
                                                        }
                                                    }
                                                }}>
                                                    <div className={`voting__drop-check ${filter.includes(vote.name) ? 'voting__drop-check_c' : ''}`} />
                                                    <span style={{color: vote.color}}>{vote.name}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    ''
                                }
                            <span className='voting__left-pools voting__right-acc'>Accounts</span>
                            <span className='voting__left-pools'>Balance</span>
                        </div>
                        <div className='voting__right-list'>
                            {
                                filter.includes('All') ? 
                                wallets.map(wallet => (
                                    <div className='voting__vote' key={wallet.name}>
                                        <div className='voting__vote-vote' style={{background: wallet.color}} />
                                        <span className='voting__vote-acc'>
                                            {
                                                isMobile ? wallet.name.slice(0, 10) + '...' + wallet.name.slice(-10)
                                                :
                                                wallet.name
                                            }
                                        </span>
                                        <span className='voting__vote-balance'>{wallet.balance.toFixed(0)}</span>
                                    </div>
                                ))
                                :
                                wallets.filter(f => filter.includes(f.voteFor)).map(wallet => (
                                    <div className='voting__vote' key={wallet.name}>
                                        <div className='voting__vote-vote' style={{background: wallet.color}} />
                                        <span className='voting__vote-acc'>
                                            {
                                                isMobile ? wallet.name.slice(0, 10) + '...' + wallet.name.slice(-10)
                                                :
                                                wallet.name
                                            }
                                        </span>
                                        <span className='voting__vote-balance'>{wallet.balance.toFixed(0)}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        :
        <Loader />
    )
}