import './WkLoader.scss';


const WkLoader = ({show}) => {
    return (
        <div className="WkLoader">
            
        </div>
    )
}

export default WkLoader;