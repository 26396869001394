import './BurnProc.scss';
import BurnProcItem from '../../components/BurnProcItem/BurnProcItem';
import { useEffect } from 'react';


const BurnProc = ({active, list}) => {
    

    if(active) {
        return (
            <>
                {
                    list?.length > 0 ? (
                        list.map((item, index) => (
                            <BurnProcItem
                                key={index}
                                complete={false} 
                                {...item}
                                timeStart={item.timeStart}
                                />
                        ))
                    ) : null
                }
            </>
        )
    }

    return null
}

export default BurnProc;