import './IconButton.scss';



const IconButton = ({icon, badge, notCount, onClick}) => {
    return (
        <button className="IconButton" onClick={onClick}>
            {icon}
            {
                badge ? (
                    <div className="IconButton__badge"></div>
                ) : null
            }
        </button>
    )
}

export default IconButton;