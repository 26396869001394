import './Radio.scss';


const Radio = ({
    onChange,
    id,
    name,
    text,
    checked,
    value
}) => {
    return (
        <div className="Radio">
            <input 
                value={value}
                type="radio" 
                name={name}
                id={id}
                checked={checked}
                onChange={onChange}
                />
            <label htmlFor={id} className="Radio__label">
                <div className="Radio__label_icon"></div>
                <div className="Radio__label_text">
                    {text} 
                </div>
            </label>
        </div>
    )
}

export default Radio;