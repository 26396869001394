import './PushItem.scss';
import { useCallback } from 'react';
import {MdError, MdCheckCircle, MdWarning, MdInfo} from 'react-icons/md';

const STATUS = {
    error: 'ERROR',
    success: 'SUCCESS',
    warning: 'WARNING',
    info: 'INFO'
}

const StatusError = () => {
    return <MdError color='var(--red2)' fontSize={20} style={{lineHeight: '20px'}}/>
}

const StatusSuccess = () => {
    return <MdCheckCircle color='var(--green1' fontSize={20} style={{lineHeight: '20px'}}/>
}

const StatusWarning = () => {
    return <MdWarning color='orange' fontSize={20} style={{lineHeight: '20px'}}/>
}

const StatusInfo = () => {
    return <MdInfo color="var(--gray3)" fontSize={20} style={{lineHeight: '20px'}}/>
}



const PushItem = ({status, text}) => {

    const statusHandle = useCallback((status) => {
        switch (status) {
            case STATUS.error:
                return (
                    <StatusError/>
                )
            case STATUS.success:
                return (
                    <StatusSuccess/>
                )
            case STATUS.warning:
                return (
                    <StatusWarning/>
                )
            case STATUS.info:
                return (
                    <StatusInfo/>
                )
            default:
                return;
        }
    }, [status])


    return (
        <div className="PushItem">
            <div className="PushItem__status">
                {statusHandle(status)}
            </div>
            <div className="PushItem__text">
                message of notification
            </div>
        </div>
    )
}

export default PushItem;