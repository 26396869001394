import './ChooseSnapshot.scss';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import apiService from '../../../../service/apiService';
import { useSelector } from 'react-redux';
import {Row, Col} from 'antd';
import Button from '../../../../components/Button/Button';
import { Loader } from '../../../../components/Loaders';
import { MoonLoader } from 'react-spinners';

const as = new apiService()

const ChooseSnapshot = ({selectSnapshot, visible, close}) => {
    const {authData} = useSelector(state => state)
    const [snapshotList, setSnapshotList] = useState([])
    const [loadList, setLoadList] = useState(false)


    useEffect(() => {
        if(visible && authData?.wallet && authData?.token) {
            setLoadList(true)
            as.getUserData(authData.wallet, authData.token).then(res => {
                setSnapshotList(res?.shanpshots?.reverse())
            }).finally(_ => {
                setLoadList(false)
            })
        } else {
            setSnapshotList([])
        }
    }, [visible, authData])

    const chooseSnapshot = ({...item}) => {
        selectSnapshot(item)
        closeHandle()

    }

    const closeHandle = () => {
        close()
    }

    return (
        <Modal width={700} className='modal ChooseSnapshot' open={visible} onCancel={closeHandle}>
            <div className="modal__body">
                <h2 className="modal__body_head ChooseSnapshot__head">
                    Choose SnapShot
                </h2>
                <div className="ChooseSnapshot__body">
                
                {
                    loadList ? (
                        <Loader/>
                    ) : (
                        snapshotList && snapshotList.length > 0 ? (
                            snapshotList.map((item, index) => (
                                <div className={"ChooseSnapshot__item" + (item.loading ? ' disabled ' : '')} key={index}>
                                    <Row gutter={[10, 0]}>
                                        <Col span={18}>
                                            <div className="ChooseSnapshot__item_name">
                                            {item.label}
                                            </div>
                                        </Col>
                                        <Col span={6}>
                                            {
                                                item.loading ? (
                                                    <div className="ChooseSnapshot__item_action">
                                                        <MoonLoader color='var(--green1)' size={30}/>
                                                    </div>
                                                ) : (
                                                    <div className="ChooseSnapshot__item_action">
                                                        <Button
                                                            text={'Choose'}
                                                            variant={'default'}
                                                            onClick={() => chooseSnapshot({...item})}
                                                            />
                                                    </div>
                                                )
                                            }
                                           
                                        </Col>
                                    </Row>
                                </div>
                            ))
                        ) : (
                            <div 
                                style={{
                                    padding: '40px 0', 
                                    dispay: 'flex', 
                                    flexDirection: 'column', 
                                    alignItems:'center',
                                    opacity: '.5'
                                }}

                            >
                                <div style={{textAlign: 'center', color: 'var(--text)', fontWeight: 500}}>
                                    You don't have SnapShots
                                </div>
                                
                            </div>
                        )
                    )
                }

                </div>
            </div>
            
        </Modal>
    )
}

export default ChooseSnapshot;