import './Notfound.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import nf from '../../assets/nf.png'
import Header from '../../components/Header/Header';
const Notfound = () => {
    return (
        <div className="Notfound">
<Header/>
            <div className="page">
                
                {/* <Sidebar/> */}
                <div className="Notfound__body">
                    
                    <div className="Notfound__body_img">
                    <img src={nf} alt="" />
                    </div>
                    {/* <div className="Notfound__body_text">

                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Notfound;