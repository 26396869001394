import './RlTable.scss';
import RlItem from '../RlItem/RlItem';
import { Loader } from '../../../../components/Loaders';
import ResultPlaceholder from '../../modals/RichSearch/components/ResultPlaceholder/ResultPlaceholder';


const RlTable = ({list, load, currentPage}) => {
  
        
    return (
        <div className="RlTable">
            {
                load ? (
                    <Loader/>
                ) : (
                    list && list.length > 0 ? (
                        <table className="RlTable__tb">
                            <tr className="RlTable__tb_row RlTable__tb_headrow">
                                <th className="RlTable__tb_head">№</th>
                                <th className="RlTable__tb_head">Wallet address</th>
                                <th className="RlTable__tb_head">Balance</th>
                                <th className="RlTable__tb_head">%</th>
                                <th className="RlTable__tb_head">Value in XRP</th>
                                <th className="RlTable__tb_head">Links</th>
                            </tr>
                            {
                                list?.length > 0 ? (
                                    list.map((item, index) => (
                                        <RlItem 
                                            key={index} 
                                            {...item}
                                            index={((currentPage * 20 + item.index))} />
                                    ))
                                ) : null
                            }
                        </table>
                    ) : <ResultPlaceholder text={'Nothing found'}/>
                )
            }
            
        </div>
    )
}

export default RlTable;