import './DTPicker.scss';
import DateTimePicker from 'react-datetime-picker';

const DTPicker = ({
    onChange,
    value
}) => {

    return (
        <div className="DTPicker">
            <DateTimePicker
                className={'DTPicker__el'}
                onChange={onChange}
                value={value}
                />
        </div>
    )
}

export default DTPicker;