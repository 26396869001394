import './BurnProcItem.scss';
import { useRef, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import {FiChevronDown} from 'react-icons/fi';
import Button from '../../../../components/Button/Button';
import moment from 'moment';
import * as _ from 'lodash';

const BurnProcItem = ({
    staked,
    currencyCode_HEX,
    currencyCode_Symbol,
    earned,
    issuer,
    maxRemainingReward,
    otherTokenName,
    random,
    remainingReward,
    reward,
    wallet,
    complete,
    timeStart,
    timeEnd
}) => {


    const bodyRef = useRef();
    const [bodyHeight, setBodyHeight] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    const isSame = otherTokenName === null
    
    useEffect(() => {
        if(isOpen && bodyRef?.current) {
            setBodyHeight(bodyRef.current.scrollHeight)
        }
    }, [isOpen, bodyRef])

    const toggleBody = () => {
        setIsOpen(!isOpen)
    }

  
    return (
        <div className={"BurnProcItem DropItem" + (isOpen ? ' is-open ' : '')}>
            <div className="BurnProcItem__head DropItem__head" onClick={toggleBody}>
                <Row style={{alignItems: 'flex-end'}} gutter={[20,0]}>
                    <Col
                        span={6}     
                        >
                        <Row gutter={[5,5]}>
                            <Col span={24}>
                                {/* {moment(Date.now() - ((maxRemainingReward - remainingReward) * 86400000)).format('DD.MM.YYYY')} */}
                                {timeStart ? moment(timeStart).format('DD.MM.YYYY') : moment(timeEnd).format('DD.MM.YYYY')}
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        span={8}
                        >
                        <div>
                            {
                                isSame ? 
                                `${currencyCode_Symbol} (${_.round((100 * reward * maxRemainingReward) / staked)}%)`
                                :
                                `${otherTokenName} -> ${currencyCode_Symbol} (1 / ${_.round(reward / staked, 6)})`
                            }
                        </div>
                        {
                            complete ? (
                                <div>Completed</div>
                            ) : (
                                <div>Remaining period: {remainingReward} days</div>
                            )
                        }
                    </Col>
                    <Col 
                        span={6} style={{textAlign: 'right'}}>
                            {_.round(earned, 3)} {currencyCode_Symbol}   
                    </Col>
                    <Col 
                        span={4}
                        >
                        <div className="DropItem__head_toggle BurnProcItem__head_toggle">
                            <FiChevronDown  fontSize={22}/>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="BurnProcItem__body DropItem__body" ref={bodyRef} style={{height: isOpen ? bodyHeight : 0}}>
                <div className="BurnProcItem__body_in DropItem__body_in">
                    <Row gutter={[10,10]}>
                        <Col md={12} span={24}>
                            Details:
                        </Col>
                        <Col md={12} span={24}>
                            <Row gutter={[0,0]}>
                                <Col span={24}>
                                    <Row gutter={[10,10]}>
                                        <Col span={12}>
                                            <span>Reward period:</span>  
                                        </Col>
                                        <Col span={12}>
                                            <span>{maxRemainingReward} days</span> 
                                        </Col>
                                    </Row>
                                </Col>  
                                <Col span={24}>
                                    <Row gutter={[10,10]}>
                                        <Col span={12}>
                                            <span>Remaining period: </span>   
                                        </Col>
                                        <Col span={12}>
                                            {
                                                complete ? (
                                                    <span>Completed</span>
                                                ) : (
                                                    <span>{remainingReward} days</span>
                                                )
                                            }
                                            
                                        </Col>
                                    </Row>
                                </Col>   
                                {
                                    isSame ?
                                    <>
                                        <Col span={24}>
                                            <Row gutter={[10,10]}>
                                                <Col span={12}><span>Daily %:</span> </Col>
                                                <Col span={12}><span>{_.round((reward / staked) * 100, 3)}%</span></Col>
                                            </Row>
                                        </Col> 
                                        <Col span={24}>
                                            <Row gutter={[10,10]}>
                                                <Col span={12}><span>Total %:</span></Col>
                                                <Col span={12}><span>{_.round(100 * reward * maxRemainingReward / staked)}%</span></Col>
                                            </Row>
                                        </Col>
                                    </>
                                    :
                                    <Col span={24}>
                                        <Row gutter={[10,10]}>
                                            <Col span={12}><span>Ratio:</span> </Col>
                                            <Col span={12}><span>1 / {_.round(reward / staked, 3)}</span></Col>
                                        </Row>
                                    </Col>
                                }
                                <Col span={24}>
                                    <Row gutter={[10,10]}>
                                        <Col span={12}><span>Daily reward:</span>  </Col>
                                        <Col span={12}>{_.round(reward, 3)} {currencyCode_Symbol}</Col>
                                    </Row>
                                </Col>  
                                <Col span={24}>
                                    <Row gutter={[10,10]}>
                                        <Col span={12}><span>Total reward:</span>  </Col>
                                        <Col span={12}>
                                            {_.round(reward * maxRemainingReward)} {currencyCode_Symbol}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row gutter={[10,10]}>
                                        <Col span={12}><span>Staked:</span>   </Col>
                                        <Col span={12}><span>{staked} {isSame ? currencyCode_Symbol : otherTokenName}</span></Col>
                                    </Row>
                                </Col>  
                                <Col span={24}>
                                    <Row gutter={[10,10]}>
                                        <Col span={12}><span>Received:</span></Col>
                                        <Col span={12}><span>{_.round(earned, 3)} {currencyCode_Symbol}</span></Col>
                                    </Row>
                                </Col>
                            </Row>    
                        </Col> 
                    </Row>
                </div>
                
            </div>
        </div>
    )
}

export default BurnProcItem;