import './icon.scss';

const AboutIcon = ({color = 'var(--gray1)', size = 25}) => {
    return (
        <div className="AirDropIcon icon" style={{width: size, height: size}}>
           <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.50746 2.8C3.58424 2.8 2.83582 3.51634 2.83582 4.4V13.4C2.83582 14.2837 3.58424 15 4.50746 15H13.2983C14.2215 15 14.9699 14.2837 14.9699 13.4V7.275H15.8057V13.4C15.8057 14.7255 14.6831 15.8 13.2983 15.8H4.50746C3.12263 15.8 2 14.7255 2 13.4V4.4C2 3.07452 3.12263 2 4.50746 2H13.3613V2.8H4.50746ZM14.9905 2.4L16 3.45417L12.5605 6.7633L11.5384 6.72879L11.551 5.70912L14.9905 2.4ZM5.47443 4.04063C4.68104 4.04063 4.03787 4.65623 4.03787 5.41563V8.95083V9.45474H4.82145V8.95083V5.41563C4.82145 5.07045 5.1138 4.79063 5.47443 4.79063H9.45711V4.04063H5.47443Z" fill={color}/>
            </svg>



        </div>
    )
}

export default AboutIcon;