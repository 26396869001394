import './icon.scss';
import website from '../assets/icons/website.svg'
import { useSelector } from 'react-redux'

export const Website = () => {

    const {theme} = useSelector(s => s)

    return(
        <div className='tooltip'>
            <div className='tooltiptext'>WebSite</div>
            <img alt="" src={website} className={`link-icon ${theme === 'dark' ? '' : 'link-icon_d'}`} />
        </div>
    )
}