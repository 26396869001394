import './StepPer.scss';
import { Row, Col } from 'antd';
import Radio from '../../../../components/Radio/Radio';
import { useState } from 'react';
import { useEffect } from 'react';
import * as _ from 'lodash';
import { Twitter } from '../../../../icons/twitter';
import { Website } from '../../../../icons/website';
import { Trustline } from '../../../../icons/trustline';

const StepPer = ({
    active,
    balance,
    poolList,
    selectPercent,
    selectedPercent,
    topNft,
    setTopNft,
    pointPrice
}) => {
    const [list, setList] = useState([])
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        if(poolList?.length > 0) {
            setList(poolList.filter(item => item.currencyCode_Symbols == balance?.currency).filter(item => item.nowPool != 0 && item.nowPool > item.minStake))
        }
    }, [poolList, balance])

    const isXrp = selectedPercent?.currencyCode_Symbols === "XRP" || selectedPercent?.issuer === "XRP";

    const isSame = selectedPercent ? (selectedPercent.currencyCode_Symbols + selectedPercent.issuer) === (selectedPercent?.tokenReward?.currencyCode_Symbols + selectedPercent?.tokenReward?.issuer) : false 

    return (
        <div className={"StepPer" + (active ? ' active ' : '')}>
            <h2 className="nd-step__head step__head">Percent (%)</h2>
            <Row gutter={[0, 40]}>
                <Col span={24}>
                    <div className="StepPer__head">
                    You have {_.round(balance?.balance, 3)} {balance?.currency}
                    </div>
                </Col>
                <Col span={24}>
                    <Row gutter={[20, 20]}>
                        <Col md={12} span={24}>
                            <div className="StepPer__list">
                                
                                {
                                    list?.length > 0 ? (
                                        <Row gutter={[0, 20]}>
                                            {
                                                list.map((item, index) => (
                                                    <Col span={24} key={index}>
                                                        <div className="StepPer__list_item">
                                                            <div className='StepPer__icons'>
                                                               {
                                                                   item.needPower > 0 ?
                                                                   <span className='StepPer__icons-power'>Power</span> : ''
                                                               }
                                                                {
                                                                    item.needCarats > 0 ?
                                                                    <span className='StepPer__icons-karat'>Karats</span> : ''
                                                                }
                                                            </div>
                                                            <Radio
                                                                checked={item.reward  == selectedPercent?.reward}
                                                                id={`stepper${item.index}`}
                                                                text={
                                                                    (item.currencyCode_Symbols + item.issuer) === (item?.tokenReward?.currencyCode_Symbols + item?.tokenReward?.issuer) ?
                                                                    `${item.currencyCode_Symbols} (${_.round((item.reward * 100 * item.countRewards))}%)`
                                                                    :
                                                                    `${item.currencyCode_Symbols} -> ${item?.tokenReward?.currencyCode_Symbols} (1 / ${_.round(item.reward * item.countRewards, 6)})`
                                                                }
                                                                onChange={e => {
                                                                    if(e.target.checked) {
                                                                        selectPercent(item)
                                                                    } else {
                                                                        selectPercent(null)
                                                                    }
                                                                }}
                                                                />
                                                        </div>
                                                    </Col>
                                                    
                                                ))
                                            }
                                        </Row>
                                    ) : null
                                }
                                
                            </div>
                        </Col>
                        {
                            selectedPercent ? (
                                <Col md={12} span={24}>
                                    <div className="StepPer__info">
                                        <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                            <div>
                                                <div className="StepPer__info_head">
                                                    {selectedPercent?.header}
                                                </div>
                                                <div className="StepPer__info_links">
                                                    <div className="StepPer__info_links_i">
                                                        <a rel="noreferrer" href={selectedPercent?.twitter} target="_blank" className='StepPer__info_links_item'>
                                                            <Twitter />
                                                        </a>
                                                    </div>
                                                    <div className="StepPer__info_links_i">
                                                        <a rel="noreferrer" href={selectedPercent?.webSite} target="_blank" className='StepPer__info_links_item'>
                                                            <Website />
                                                        </a>
                                                    </div>
                                                    <div className="StepPer__info_links_i">
                                                        <a rel="noreferrer" href={selectedPercent?.tl} target="_blank" className='StepPer__info_links_item'>
                                                            <Trustline />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isSame ?
                                                ''
                                                :
                                                <div>
                                                    <div className="StepPer__info_head">
                                                        {selectedPercent?.tokenReward?.header}
                                                    </div>
                                                    <div className="StepPer__info_links">
                                                        <div className="StepPer__info_links_i">
                                                            <a rel="noreferrer" href={selectedPercent?.tokenReward?.twitter} target="_blank" className='StepPer__info_links_item'>
                                                                <Twitter />
                                                            </a>
                                                        </div>
                                                        <div className="StepPer__info_links_i">
                                                            <a rel="noreferrer" href={selectedPercent?.tokenReward?.webSite} target="_blank" className='StepPer__info_links_item'>
                                                                <Website />
                                                            </a>
                                                        </div>
                                                        <div className="StepPer__info_links_i">
                                                            <a rel="noreferrer" href={selectedPercent?.tokenReward?.tl} target="_blank" className='StepPer__info_links_item'>
                                                                <Trustline />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="StepPer__info_body">
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Total Pool size:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent?.maxPool}
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Availible Pool size:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent?.nowPool}
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Minimum amount of staking:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent?.minStake}
                                                </span>
                                            </div>
                                            {
                                                isSame ?
                                                <div className="StepPer__info_body_item">
                                                    <span className="StepPer__info_body_item_name">
                                                        Staking Percentage Yeild:
                                                    </span>
                                                    <span className="StepPer__info_body_item_value">
                                                        {_.round(selectedPercent.reward * 100 * selectedPercent.countRewards)}%
                                                    </span>
                                                </div>
                                                :
                                                <div className="StepPer__info_body_item">
                                                    <span className="StepPer__info_body_item_name">
                                                        Staking ratio:
                                                    </span>
                                                    <span className="StepPer__info_body_item_value">
                                                        1 / {_.round(selectedPercent.reward * selectedPercent.countRewards)}
                                                    </span>
                                                </div>
                                            }
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Reward period:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {selectedPercent.countRewards} days
                                                </span>
                                            </div>
                                            <div className="StepPer__info_body_item">
                                                <span className="StepPer__info_body_item_name">
                                                    Daily reward:
                                                </span>
                                                <span className="StepPer__info_body_item_value">
                                                    {
                                                        isSame ?
                                                        `${_.round(selectedPercent.reward * 100, 3)}%`
                                                        :
                                                        `${selectedPercent.reward} ${selectedPercent?.tokenReward?.currencyCode_Symbols}`
                                                    }
                                                </span>
                                            </div>
                                            {
                                                isSame ?
                                                <div className="StepPer__info_body_item">
                                                    <span className="StepPer__info_body_item_name">
                                                        Total Rewards:
                                                    </span>
                                                    <span className="StepPer__info_body_item_value">
                                                        {_.round(selectedPercent.reward * 100 * selectedPercent.countRewards)}%
                                                    </span>
                                                </div>
                                                :
                                                ''
                                            }
                                            {
                                                topNft == 'inf' && !isXrp ? (
                                                    null
                                                ) : (
                                                    selectedPercent?.points?.stakeForOnePoint ? (
                                                        <div className="StepPer__info_body_item">
                                                            <span className="StepPer__info_body_item_name">
                                                                For 1 RLT point you can stake
                                                            </span>
                                                            <span className="StepPer__info_body_item_value">
                                                                {_.round(selectedPercent.points?.stakeForOnePoint, 4)} {selectedPercent.currencyCode_Symbols}
                                                            </span>
                                                        </div>
                                                    ) : null
                                                )
                                            }

                                            {
                                                ((selectedPercent.needCarats > 0) || (selectedPercent.needPower > 0)) && 
                                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                                        <span className='StepPer__carats'>To use this pool you must have:</span>
                                                        {
                                                            selectedPercent.needCarats > 0 ?
                                                            <span className='StepPer__carats'>{'>'}= {selectedPercent.needCarats} Karats <a target="_blank" href="https://sologenic.org/profile/rocketlaunch">Buy NFT</a></span>
                                                            :
                                                            ''
                                                        }
                                                        {
                                                            selectedPercent.needPower > 0 ?
                                                            <span className='StepPer__carats'>{'>'}= {selectedPercent.needPower} Power <a target="_blank" href="https://sologenic.org/nfts/rKybQSPFWYYLhWn3UYGDK9dGLbuVcmMusE">Buy NFT</a></span>
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </Col>
                            ) : null
                        }
                    </Row>
                </Col>
            </Row>
        </div>
    )
}


export default StepPer;