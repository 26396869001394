import "./AirDropItem.scss";
import Button from "../../../../components/Button/Button";
import {useState, useEffect, useRef} from 'react';
import { Row, Col } from "antd";
import {FiChevronDown} from 'react-icons/fi';
import moment from "moment";
const _ = require('lodash');


const ProcessItem = ({
    onCancelDrop,
    paused,
    onDelete,
    timeStart,
    Wasted_RLT,
    index_drop,
    Wasted_UserToken,
    Wasted_XRP,
    CountSends,
    TotalRecipients,
    TokenCode,
    continueDrop,
    pauseDrop,
    label,
    onlyKyc
}) => {

    const bodyRef = useRef();
    const [bodyHeight, setBodyHeight] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if(isOpen && bodyRef?.current) {
            setBodyHeight(bodyRef.current.scrollHeight)
        }
    }, [isOpen, bodyRef])

    const toggleBody = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className={"AirDropItem" + (isOpen ? ' is-open ' : '')}>
            <div className="AirDropItem__head">
                <Row gutter={[20, 0]}>
                    <Col 
                        xs={6}
                        sm={6}
                        md={4}
                        className="AirDropItem__head_tm-wrap"
                        >
                        <div className="AirDropItem__head_tm">
                            <div className="AirDropItem__head_tm_date">
                            {moment.utc(timeStart).format('DD.MM.YYYY')}
                            </div>
                            <div className="AirDropItem__head_tm_time">
                            UTC {moment.utc(timeStart).format('hh:mm')}
                            </div>
                        </div>
                    </Col>
                    <Col 
                        xs={14}
                        sm={14}
                        md={6}
                        className={'AirDropItem__head_text-wrap'}
                        >
                        <div className="AirDropItem__head_text">
                            {/* Distribution according to the SnapShot */}
                            {label}
                            {/* <div className="AirDropItem__head_text_status">
                            According to the schedule
                            </div> */}
                            <div className="AirDropItem__head_text_pr">
                            {Wasted_UserToken ? _.round(Wasted_UserToken, 4) : 0} {TokenCode}
                            </div>
                        </div>
                    </Col>
                    <Col 
                        span={4}
                        className="AirDropItem__head_pr-wrap"
                        >
                        <div className="AirDropItem__head_pr">
                        {Wasted_UserToken ? _.round(Wasted_UserToken, 4) : 0} {TokenCode}
                        </div>
                    </Col>
                    {
                        paused ? (
                            <>
                             <Col className="AirDropItem__head_action-wrap" span={4} style={{display: 'flex', alignItems: 'flex-end'}}>
                                

                            </Col>
                            <Col className="AirDropItem__head_action-wrap" style={{display: 'flex', alignItems: 'flex-end'}} span={4}>
                                <Button
                                    onClick={continueDrop}
                                    text={'Continue'}
                                    variant={'default'}
                                    style={{width: '100%'}}/>
                                {/* <Button
                                    text={'Cancel'}
                                    onClick={onCancelDrop}
                                    variant={'danger'}
                                    style={{width: '100%'}}/> */}
                                
                            </Col>
                            </>
                        ) : (
                            <>
                             <Col className="AirDropItem__head_action-wrap" span={4} style={{display: 'flex', alignItems: 'flex-end'}}>
                                

                            </Col>
                            <Col className="AirDropItem__head_action-wrap" span={4} style={{display: 'flex', alignItems: 'flex-end'}}>
                                <Button
                                    onClick={() => pauseDrop(index_drop)}
                                    text={'Stop'}
                                    variant={'danger'}
                                    style={{width: '100%'}}/>
                                
                            </Col>
                            </>
                            
                        )
                    }
                   
                    <Col 
                        xs={4}
                        sm={4}
                        md={2}
                        >
                        <div onClick={toggleBody} className="AirDropItem__head_toggle">
                            <FiChevronDown  fontSize={22}/>
                        </div>
                    </Col>
                </Row>
                
            </div>
            <div className="AirDropItem__body" ref={bodyRef} style={{height: isOpen ? bodyHeight : 0}}>
                <div className="AirDropItem__details">
                    <Row gutter={[10, 10]}>
                        <Col  xs={6}
                        sm={6}
                        md={4}>
                            <div className="AirDropItem__details_name">
                                Details:
                            </div>
                        </Col>
                        <Col xs={14}
                        sm={14}
                        md={6}>
                            <div className="AirDropItem__details_body">
                                {moment(timeStart).format() > Date.now() ? 'Delayed' : null}
                                {onlyKyc ? 'Distribution by snapshot, but confirmed global ID & KYC' : 'Distribution by snapshot'}
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="AirDropItem__ws">
                    <div className="AirDropItem__ws_in">
                        <div className="AirDropItem__ws_item">
                            <div className="AirDropItem__ws_name">Wasted:</div>
                        </div>
                        <div className="AirDropItem__ws_item">
                            <div className="AirDropItem__ws_name">Amount:</div>
                            <div className="AirDropItem__ws_value">{Wasted_UserToken ? _.round(Wasted_UserToken, 4) : 0} {TokenCode}</div>
                        </div>
                        <div className="AirDropItem__ws_item">
                            <div className="AirDropItem__ws_name">Fee XRP:</div>
                            <div className="AirDropItem__ws_value">{Wasted_XRP ? _.round(Wasted_XRP, 4) : 0} XRP</div>
                        </div>
                        <div className="AirDropItem__ws_item">
                            <div className="AirDropItem__ws_name">Fee RLT:</div>
                            <div className="AirDropItem__ws_value">{Wasted_RLT ? _.round(Wasted_RLT, 4) : 0} RLT</div>
                        </div>
                    </div>
                </div>
                <div className="AirDropItem__proc">
                    {
                        paused ? (
                            <div className="AirDropItem__proc_state">Paused</div>
                        ) : (
                            null
                        )
                    }
                    <div className="AirDropItem__proc_state">{Math.round((CountSends / TotalRecipients) * 100)}% completed</div>
                    <div className="AirDropItem__proc_ind">
                        <div className="AirDropItem__proc_ind_el" style={{width: `${(CountSends / TotalRecipients) * 100}%`}}></div>
                    </div>
                    <div className="AirDropItem__proc_state">{CountSends} holders sent</div>
                </div>
                <div className="AirDropItem__body_mb">
                    
                    <Row gutter={[20, 0]}>
                        {
                            paused ? (
                                <>
                                    <Col span={12}>
                                        {/* <Button
                                            onClick={onCancelDrop}
                                            text={'Cancel AirDrop'}
                                            variant={'danger'}
                                            /> */}
                                    </Col>
                                    <Col span={12}>
                                        <Button
                                            onClick={continueDrop}
                                            text={'Continue'}
                                            variant={'default'}
                                            />
                                    </Col>
                                </>
                            ) : (
                                <Col span={24}>
                                    <Button
                                        onClick={() => pauseDrop(index_drop)}
                                        text={'Stop'}
                                        variant={'danger'}
                                        />
                                </Col>
                            )
                        }
                    </Row>
                </div>
                
            </div>
        </div>
    )
}

export default ProcessItem;