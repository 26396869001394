import './NdCurrency.scss';
import Input from '../../../../../components/Input/Input';
import { Row, Col } from 'antd';
import Text from '../../../../../components/Text/Text';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import apiService from '../../../../../service/apiService';
import * as _ from 'lodash';

const service = new apiService();

const NdCurrency = ({
    visible, 
    head,
    currentStep,
    setNextDisable,
    setMemo,
    setAsset,
    setIssuer,
    setAdress,
    setSecret,
    comData,
    comSn,
    setComObj,
    setLabel,
    blacklist
}) => {
    const {authData} = useSelector(state => state)
    const [sid, setSid] = useState('')
    const [sendWallet, setSendWallet] = useState('')
    const [currencyCode, setCurrencyCode] = useState('')
    const [issuerL, setIssuerL] = useState('')
    const [memoL, setMemoL] = useState('')
    const [lb, setLb] = useState('')
    const [xrpFee, setXrpFee] = useState('')
    const [rltFee, setRltFee] = useState('')
    const [tokenFee, setTokenFee] = useState('')

    useEffect(() => {
        if(authData && comData && currentStep == '3' && (comSn == 0 || comSn > 0)) {
            service.getComission(authData?.wallet, authData?.token, comSn, comData, blacklist).then(res => {
                setComObj(res)
                setRltFee(res?.rlT_Fee)
                setXrpFee(res?.xrP_Fee)
                setTokenFee(res?.token_Fee)
            })
        }
    }, [comData, authData, currentStep, comSn, blacklist])


    useEffect(() => {

        if(currentStep == '3' && setNextDisable) {
            setMemo(memoL)
            setSecret(sid)
            setIssuer(issuerL)
            setAdress(sendWallet)
            setAsset(currencyCode)
            setLabel(lb ? lb : 'new-label')
            if(sid && sendWallet && currencyCode && issuerL) {
                setNextDisable(false)
            } else {
                setNextDisable(true)
            }
        }
        // if(currentStep != '3') {
        //     if(sid && sendWallet && currencyCode && issuerL && memoL) {
                
        //     }
        // }
    }, [currentStep, setNextDisable, sid, sendWallet, currencyCode, issuerL, memoL])


    return (
        <div className="nd-step" style={{display: visible ? 'block' : 'none'}}>
            {
                head ? (
                    <h2 className="nd-step__head">{head}</h2>
                ) : null
            }
            <div className="NdCurrency">
                <Row gutter={[20, 20]}>
                    <Col
                        span={24}
                        md={12}
                        >
                        <Row gutter={[0,40]}>
                            <Col span={24}>
                                <Row gutter={[0, 15]}>
                                    <Col span={24}>
                                        <Input
                                            placeholder={'Name'}
                                            value={lb}
                                            onChange={e => setLb(e.target.value)}
                                            />
                                    </Col>
                                    <Col span={24}>
                                        <Input 
                                            value={sid}
                                            onChange={e => setSid(e.target.value)}
                                            placeholder={'Sid phrase'}/>
                                    </Col>
                                    <Col span={24}>
                                        <Input 
                                            value={sendWallet}
                                            onChange={e => setSendWallet(e.target.value)}
                                            placeholder={'Sender wallet'}/>
                                    </Col>
                                    <Col span={24}>
                                        <Row gutter={[0,15]}>
                                            <Col span={24}>
                                                <Input 
                                                    value={currencyCode}
                                                    onChange={e => {
                                                        if(e.target.value.length <= 40) {
                                                            setCurrencyCode(e.target.value)
                                                        } else {
                                                            return;
                                                        }
                                                    }}
                                                    placeholder={'Currency code'}/>
                                            </Col>
                                            <Col span={24}>
                                                <Input 
                                                    value={issuerL}
                                                    onChange={e => setIssuerL(e.target.value)}
                                                    placeholder={'Issuer adress'}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Text
                                            value={memoL}
                                            onChange={e => setMemoL(e.target.value)} 
                                            placeholder={'Memo'} 
                                            minHeight={140}/>
                                    </Col>
                                </Row>
                            </Col>
                            
                        </Row>
                    </Col>
                    <Col
                        span={24}
                        md={12}
                        >
                        <div className="NdCurrency__fee">
                            <div className="NdCurrency__fee_label">
                                You need to enter a following assets:
                            </div>
                            <div className="NdCurrency__fee_body">
                                <Row gutter={[0, 10]}>
                                    <Col span={24}>
                                        <div className="NdCurrency__fee_body_item">
                                            <div className="NdCurrency__fee_body_item_name">Fee</div>
                                            <div className="NdCurrency__fee_body_item_value">
                                                XRP {_.round(xrpFee, 5)}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className="NdCurrency__fee_body_item">
                                            <div className="NdCurrency__fee_body_item_name">RLT Fee</div>
                                            <div className="NdCurrency__fee_body_item_value">
                                                RLT {_.round(rltFee, 5)}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className="NdCurrency__fee_body_item">
                                            <div className="NdCurrency__fee_body_item_name">Amount</div>
                                            <div className="NdCurrency__fee_body_item_value">
                                                {currencyCode} {_.round(tokenFee, 5)}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    
                </Row>
            </div>
        </div>
    )
}

export default NdCurrency;