import './TokenList.scss';
import TokenItem from './components/TokenItem/TokenItem';
import { Row,Col } from 'antd';
import Loader from '../Loaders/Loader/Loader';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const TokenList = ({
    tokenList,
    balanceList,
    selectPool,
    selectedPool,
    setSelectedPercent
}) => {
    const [list, setList] = useState([])
    const [_balanceList, setBalanceList] = useState([]);

    const { xrp } = useSelector(s => s);
    
    useEffect(() => {
        if(tokenList.length > 0) {
            const r = tokenList.reduce((o, i) => {
                if(!o.find(v => v.currencyCode_Symbols == i.currencyCode_Symbols)) {
                    const needCarats = Math.max(...tokenList.filter(t => t.currencyCode_Symbols === i.currencyCode_Symbols).map(t => t.needCarats));
                    const needPower = Math.max(...tokenList.filter(t => t.currencyCode_Symbols === i.currencyCode_Symbols).map(t => t.needPower));
                    const priority = tokenList.filter(t => t.currencyCode_Symbols === i.currencyCode_Symbols).find(t => t.priority)?.priority ?? false
                    const newItem = {...i};
                    newItem.needCarats = needCarats;
                    newItem.needPower = needPower;
                    newItem.priority = priority;
                    o.push(newItem);
                }
                return o;
            }, [])
            setList(r)
        }
    }, [tokenList])

    useEffect(() => {
        if  (list.length) {
            const newlist = [];
            tokenList.forEach(e => {
                const item = balanceList.find(i => i.currency == e.currencyCode_Symbols);
                const isXrp = e.currencyCode_Symbols === 'XRP' || e.issuer === 'XRP'
                if (item) {
                    const oldBalance = item.balance 
                    item.balance = Number(oldBalance) > 100 ? BigInt(parseFloat(oldBalance).toFixed(0)).toString() : Number(oldBalance) === 0 ? 0 : Number(oldBalance).toFixed(6)
                    newlist.push(item)
                } else {
                    const obj = {
                        account: '',
                        balance: isXrp ? xrp?.freeXrpBalance : 0,
                        currency: e.currencyCode_Symbols,
                        limit: 0,
                        limit_peer: 0,
                        no_ripple: true,
                        no_ripple_peer: false,
                        quality_in: 0,
                        quality_out: 0
                    }
                    newlist.push({...obj})
                }
            });
            setBalanceList([...newlist])
        }
    }, [list, balanceList, xrp])

    return (
        <div className="TokenList">
            <div className="TokenList__in">
                <div className="TokenList__head">
                    <Row>
                        <Col span={12} style={{borderRight: '0.5px solid var(--text)'}}>
                            <div className="TokenList__head_item">
                                Token name
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="TokenList__head_item">
                                Your balance
                            </div>
                        </Col>
                    </Row>
                </div>  
                
                <div className="TokenList__body">
                    {
                        list?.length > 0 ? (
                            list.sort((a,b) => Number(b.priority) - Number(a.priority)).map((item, index) => (
                                <TokenItem
                                    key={index}
                                    setSelectedPercent={setSelectedPercent}
                                    selectedPool={selectedPool}
                                    selectPool={selectPool}
                                    Logo={null}
                                    balance={_balanceList?.find(i => i.currency == item.currencyCode_Symbols)}   
                                    {...item}
                                    />
                            ))
                        ) : <Loader/>
                    }
                </div>
            </div>
        </div>
    )
}

export default TokenList;