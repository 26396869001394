import './icon.scss';


const BellIcon = ({color = 'var(--gray1)', size = 25}) => {
    return (
        <div className="BellIcon icon" style={{width: size, height: size}}>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 4.00001C9.85714 3.99822 5.14286 4.45824 5.14286 15.4294L3.07725 18.4334C2.62101 19.0969 3.09602 20 3.90124 20H13" stroke={color} strokeWidth="2"/>
            <path d="M13 4.00001C16.1429 3.99822 20.8571 4.45824 20.8571 15.4294L22.9228 18.4334C23.379 19.0969 22.904 20 22.0988 20H13" stroke={color} strokeWidth="2"/>
            <circle cx="13" cy="2" r="1.5" fill={color} stroke={color}/>
            <mask id="path-4-inside-1_3_120" fill="white">
            <path d="M16 22C16 22.7956 15.6839 23.5587 15.1213 24.1213C14.5587 24.6839 13.7956 25 13 25C12.2044 25 11.4413 24.6839 10.8787 24.1213C10.3161 23.5587 10 22.7956 10 22L13 22H16Z"/>
            </mask>
            <path d="M16 22C16 22.7956 15.6839 23.5587 15.1213 24.1213C14.5587 24.6839 13.7956 25 13 25C12.2044 25 11.4413 24.6839 10.8787 24.1213C10.3161 23.5587 10 22.7956 10 22L13 22H16Z" fill={color} stroke={color} strokeWidth="2" mask="url(#path-4-inside-1_3_120)"/>
            <path d="M5.70663 2.44395C5.70663 2.44395 3.5525 4.53525 2.8143 6.21527C2.11423 7.80851 1.97669 10.6498 1.97669 10.6498" stroke={color} strokeWidth="2" strokeLinecap="round"/>
            </svg>

        </div>
    )
}

export default BellIcon;