import {Modal} from 'antd';
import Button from '../../../../components/Button/Button';
import {Row, Col} from 'antd';



const CancelDrop = ({visible, close}) => {


    const closeHandle = () => {
        close()
    }

    return (
        <Modal
            width={620}
            open={visible}
            onCancel={closeHandle}
            className="modal"
            centered
            >
            
            <div className="modal__body">
                <Row gutter={[0, 70]}>
                    <Col className='modal__body_head' span={24}>
                        Are you sure you want to cancel this AirDrop?
                    </Col>
                    <div className="modal__body_action">
                        <div className="modal__body_action_btn">
                            <Button
                                variant={'danger'}
                                text={'Cancel AirDrop'}
                                onClick={closeHandle}
                                />
                        </div>
                        <div className="modal__body_action_btn">
                            <Button
                                variant={'default'}
                                text={'Continue later'}
                                />
                        </div>
                    </div>
                </Row>
                
            </div>

        </Modal>
    )
}

export default CancelDrop;