import './icon.scss';


const MoonIcon = ({color = 'var(--gray1)', size = 25}) => {
    return (
        <div className="MoonIcon icon" style={{width: size, height: size}}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M24 17.336C22.1844 18.3269 20.1019 18.89 17.8878 18.89C10.8313 18.89 5.11081 13.1696 5.11081 6.113C5.11081 3.89894 5.67396 1.8164 6.66484 0.000827724C2.69391 2.16803 0 6.38132 0 11.2238C0 18.2804 5.72047 24.0008 12.777 24.0008C17.6195 24.0008 21.8328 21.3069 24 17.336Z" fill={color}/>
            <circle cx="12.777" cy="3.57757" r="0.511081" fill={color}/>
            <circle cx="17.8878" cy="5.62189" r="0.511081" fill={color}/>
            <circle cx="17.8878" cy="13.7992" r="0.511081" fill={color}/>
            <circle cx="14.3103" cy="9.19945" r="1.02216" fill={color}/>
            <circle cx="22.4876" cy="6.13297" r="1.02216" fill={color}/>
            <circle cx="16.8657" cy="0.511081" r="0.511081" fill={color}/>
            </svg>

        </div>
    )
}

export default MoonIcon;