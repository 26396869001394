import { useSelector } from 'react-redux';
import { InProgressItem } from './item';
import './progress.scss';

export const Progress = ({ active }) => {
    const { userData } = useSelector(s => s)

    if (!active) {
        return <></>
    }
    
    return (
        <>
            {
                userData?.NFTStaked?.length > 0 ? (
                    userData?.NFTStaked?.filter(elem => !elem.timeEnd).map((item, index) => (
                        <InProgressItem key={index} complete={false} {...item} timeStart={item.timeStart}/>
                    ))
                ) : null
            }
        </>
    )
};

export const History = ({ active }) => {
    const { userData } = useSelector(s => s)

    if (!active) {
        return <></>
    }
    
    return (
        <>
            {
                userData?.NFTStaked?.length > 0 ? (
                    userData?.NFTStaked?.filter(elem => elem.timeEnd).map((item, index) => (
                        <InProgressItem key={index} complete={true} {...item} timeStart={item.timeStart}/>
                    ))
                ) : null
            }
        </>
    )
}