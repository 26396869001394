import './Header.scss';
import Button from '../Button/Button';
import IconButton from '../IconButton/IconButton';
import {RiSunLine} from 'react-icons/ri';
import {TbBellRinging} from 'react-icons/tb';
import { Dropdown } from 'antd';
import ProfileDrop from '../ProfileDrop/ProfileDrop';
import {FiChevronDown} from 'react-icons/fi';
import { useNavigate, useLocation } from 'react-router-dom';
import Auth from '../Auth/Auth';
import { useEffect, useState } from 'react';
import WalletSearch from '../WalletSearch/WalletSearch';
import PushList from '../PushList/PushList';
import {notification} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { themeToggle } from '../../store/actions';
import NotBody from '../NotBody/NotBody';
import LogoutMenu from '../LogoutMenu/LogoutMenu';
import {GiNightSleep} from 'react-icons/gi';
import {BiWallet} from 'react-icons/bi';

import logoLight from '../../assets/icons/logol.svg';
import logoDark from '../../assets/icons/logod.svg';
import MoonIcon from '../../icons/MoonIcon';
import WalletIcon from '../../icons/WalletIcon';
import BellIcon from '../../icons/BellIcon';
import SunIcon from '../../icons/SunIcon';

import infoIcon from '../../assets/icons/info.svg';
import MainCalendar from '../MainCalendar/MainCalendar';
import SbInfo from '../SbInfo/SbInfo';

const LOCAL_STORAGE = window.localStorage;

const Header = () => {
    const nav = useNavigate()
    const loc = useLocation()
    const dispatch = useDispatch()
    const {theme} = useSelector(state => state)
    const [burgerActive, setBurgerActive] = useState(false)
    const [pushActive, setPushActive] = useState(false)
    const [notificationShow, setNotificationShow] = useState(false)
    const [logout, setLogout] = useState(false)

    const [infoOpen, setInfoOpen] = useState(false);

    const burgerToggle = () => setBurgerActive(!burgerActive)
    const pushToggle = () => setPushActive(!pushActive)

    const showNotifications = () => {
        setNotificationShow(true)
    }


    useEffect(() => {
        let notTime;
        if(notificationShow) {
            notTime = setTimeout(() => {
                setNotificationShow(false)
            }, 6000)
        } 
        return () => {
            clearTimeout(notTime)
        }
    }, [notificationShow])


    const toggleTheme = (value) => {
        if(value == 'dark') {
            LOCAL_STORAGE.setItem('xumm-adtools-theme', 'light')
            dispatch(themeToggle('light'))
        } 
        if(value == 'light') {
            LOCAL_STORAGE.setItem('xumm-adtools-theme', 'dark')
            dispatch(themeToggle('dark'))
        }
    }


    return (
        <header className="Header">

            <NotBody open={notificationShow}/>

            <div className="Header__in">
                {/* {
                    loc.pathname.includes('airdrop') ? (
                        <div className="Header__item">
                            <WalletSearch placeholder={'Search'}/>
                        </div>
                    ) : null
                } */}
                <div className="Header__logo">
                <div className="Header__logo_el">
                    {
                        theme == 'light' ? (
                            <img src={logoDark} alt="xrptools.pro" />
                        ) : (
                            <img src={logoLight} alt="xrptools.pro" />
                        )
                    }
                </div>
                </div>
                

                <div className="Header__item Header__item-theme">
                    {
                        theme == 'light' ? (
                            // <IconButton 
                            //     onClick={() => toggleTheme('light')} 
                            //     icon={<GiNightSleep color={theme == 'dark' ? '#fff' : '#000'} size={25}/>}/>
                            <IconButton 
                                onClick={() => toggleTheme('light')} 
                                icon={
                                    <MoonIcon
                                        color={theme == 'dark' ? '#F8F8F8' : '#000'}
                                        />
                                }/>
                        ) : (
                            <IconButton 
                                onClick={() => toggleTheme('dark')} 
                                icon={<SunIcon color={theme == 'dark' ? '#F8F8F8' : '#000'}/>}
                                />
                        )
                    }
                    
                    
                </div>
                <div className="Header__item Header__item-profile">
                    <Dropdown
                        open={logout}
                        overlay={<LogoutMenu close={setLogout}/>}
                        trigger={['click']}
                        onOpenChange={e => {
                            if(e) {
                                setLogout(true)
                            } else {
                                setLogout(false)
                            }
                        }}
                        >
                        <button className={"Header__item_profile" + (logout ? ' open ' : '')}>
                            <div className="Header__item_profile_avatar">
                                <IconButton
                                    icon={<WalletIcon color='var(--icon)' size={25}/>}
                                    />
                            </div>
                            <div className="Header__item_profile_icon">
                                <FiChevronDown color='var(--icon)' fontSize={20}/>
                            </div>
                        </button>
                    </Dropdown>
                    
                </div>
                
                <Dropdown
                        open={infoOpen}
                        overlay={
                            <div className="Header__info-block">
                                <MainCalendar/>
                                <SbInfo/>
                            </div>
                        }
                        trigger={['click']}
                        onOpenChange={e => {
                            if(e) {
                                setInfoOpen(true)
                            } else {
                                setInfoOpen(false)
                            }
                        }}
                >
                    <img className={theme === 'dark' ? 'Header__info  Header__info_d' : 'Header__info'} src={infoIcon} />    
                </Dropdown>
                <div className="Header__item Header__item-burger">
                    <Dropdown
                        open={burgerActive}
                        trigger={['click']}
                        overlay={<ProfileDrop close={setBurgerActive}/>}
                        onOpenChange={e => {
                            if(e) {
                                setBurgerActive(true)
                            } else {
                                setBurgerActive(false)
                            }
                        }}
                        >
                        <button className={'Header__item-burger_btn' + (burgerActive ? ' active ' : '')} onClick={burgerToggle}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </Dropdown>
                    
                </div>
            </div>
        </header>
    )
}

export default Header;