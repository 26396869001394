import { message, Modal } from "antd";
import logo from '../../assets/logo.svg';
import './Auth.scss';
import Qr from "./components/Qr";
import Button from "../Button/Button";
import apiService from "../../service/apiService";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserAuth, updateToken } from "../../store/actions";

import {BeatLoader, MoonLoader} from 'react-spinners';
import logoDark from '../../assets/icons/logod.svg';
import logoLight from '../../assets/icons/logol.svg';
import moment from "moment";


const service = new apiService();


const LOCAL_STORAGE = window.localStorage;



const Auth = () => {
    const dispatch = useDispatch()
    const {authData, token, theme} = useSelector(state => state)
    const [qrImg, setQrImg] = useState(null)
    const [link, setLink] = useState()
    const [uuid, setUuid] = useState(null)
    const [modalVis, setModalVis] = useState(false)
    const [load, setLoad] = useState(false)
    

    useEffect(() => {
        if(!token && window.innerWidth > 1000) {
            setModalVis(true)
        } else {
            setModalVis(false)
        }
    }, [token])

    useEffect(() => {
        if(modalVis) {
            service.signin().then(res => {
                if(res?.uuid) {
                    setQrImg(`https://xumm.app/sign/${res.uuid}.png`)
                    setLink(`https://xumm.app/sign/${res.uuid}`)
                    setUuid(res.uuid)
                    return res.uuid
                } 
            }).then(result => {
                if(result) {
                 
                    let checkQrTimeout;
                    setLoad(true)
                    checkQrTimeout = setInterval(() => {
                        service.checkTransaction(result).then(res => {
                            if(res) {
                                LOCAL_STORAGE.setItem('xumm-adtools-user-token', res.userToken)
                                LOCAL_STORAGE.setItem('xumm-adtools-user-uuid', res.uuid)
                                LOCAL_STORAGE.setItem('xumm-adtools-user-wallet', res.wallet)
                                LOCAL_STORAGE.setItem('xumn-time', moment(new Date()).add(30, 'days').format('MMMM Do YYYY'))
                                dispatch(updateUserAuth({
                                    token: res.userToken,
                                    uuid: res.uuid,
                                    wallet: res.wallet
                                }))
                                dispatch(updateToken(res.userToken))
                                setModalVis(false)
                                if(res) {
                                    clearInterval(checkQrTimeout)
                                    setLoad(false)
                                
                                    // window.location.reload()
                                }
                            }
                            
                        })
                    }, 3000)
                }
                
                
            })
        }
    }, [modalVis])

    // const signinHandle = () => {
    //     setLoad(true)
    //     service.checkTransaction(uuid).then(res => {
    //         if(!res) {
    //             message.error('Отсканируйте QR-код с мобильного устройства и подпишитесь')
    //         } else {
    //             LOCAL_STORAGE.setItem('xumm-adtools-user-token', res.userToken)
    //             LOCAL_STORAGE.setItem('xumm-adtools-user-uuid', res.uuid)
    //             LOCAL_STORAGE.setItem('xumm-adtools-user-wallet', res.wallet)
    //             dispatch(updateUserAuth({
    //                 token: res.userToken,
    //                 uuid: res.uuid,
    //                 wallet: res.wallet
    //             }))
    //             dispatch(updateToken(res.userToken))
    //             setModalVis(false)
    //         }
    //     }).finally(_ => setLoad(false))
    // }

    
    return (
        <Modal
            className="Auth modal"
            open={modalVis}
            
            width={845}
            centered
            >
                <div className="modal__body Auth__body">
                    <div className="Auth__body_descr">
                        <div className="Auth__body_descr_logo">
                            {
                                theme == 'light' ? (
                                    <img src={logoDark}/>
                                ) : (
                                    <img src={logoLight}/>
                                )
                            }
                 
                        </div>
                        <div className="Auth__body_descr_text">
                        XRPLTOOLS.PRO is more than an assistant, it is a new and convenient app for managing your coins (not related to storage) for distributing to holders and not only, compiling Richlists, generating Snapshots.
                        <br></br>
                        The AirDrop giveaway navigation also contains information on tracking AirDrops in the moment, as well as viewing the history of all hands conducted through XRPLTOOLS.PRO.
                        Scan the QR code to get information related to your account on the XRPL network

                        </div>
                    </div>
                    <div className="Auth__body_main">
                        <h2 className="Auth__body_main_head">Welcome back!</h2>
                        <div className="Auth__body_main_sub">Please sign in with your Xumm app</div>
                        <div className="Auth__body_main_qr">
                            <Qr image={qrImg}/>
                            
                        </div>
                        {/* <div className="Auth__body_main_action">
                            <Button
                                disabled={!qrImg}
                                load={load}
                                text={'Sign in'}
                                variant={'default'}
                                onClick={signinHandle}
                                />
                        </div> */}
                        <div className="Auth__body_main_action" style={{display: load ? 'flex' : 'none'}}>
                            <BeatLoader color="var(--text)"/>
                        </div>
                    </div>
                </div>
        </Modal>
    )
}

export default Auth;