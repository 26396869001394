import './NdAmount.scss';
import { Row, Col } from 'antd';
import Radio from '../../../../../components/Radio/Radio';
import Input from '../../../../../components/Input/Input';
import IconButton from '../../../../../components/IconButton/IconButton';
import {HiOutlineTrash} from 'react-icons/hi';
import {BiPlus} from 'react-icons/bi';
import SelectA from '../../../../../components/SelectA/SelectA';
import { useEffect, useState } from 'react';
import Button from '../../../../../components/Button/Button';
import {IoMdAddCircle} from 'react-icons/io';

// const amounts = [
//     {}, {}
// ]

const NdAmount = ({
    visible, 
    head, 
    currentStep,
    setNextDisable,
    setProcentTokens,
    setCountTokens
}) => {
    const [list, setList] = useState([])
    const [windowSizeMd, setWindowSizeMd] = useState(false)
    const [selected, setSelected] = useState('');

    const [fixValue, setFixValue] = useState('')
    const [percValue, setPercValue] = useState('')

   


    const addNewTier = () => {
        setList(state => {
            return [
                ...state,
                {}
            ]
        })
    }

    const deleteNewTier = (index) => {
        const rm = list;
        const m =rm.splice(index, 1)
        setList([...rm])
    }

    useEffect(() => {
        if(setNextDisable && currentStep == '1') {
            if(!fixValue && !percValue) {
                setNextDisable(true)
            } else {
                setNextDisable(false)
            }
        }

        if(currentStep != '1' && setProcentTokens && setCountTokens) {
            if(fixValue) {
                setCountTokens(fixValue)
                setProcentTokens(null)
            }
            if(percValue) {
                setProcentTokens(percValue)
                setCountTokens(null)
            }
        }
    }, [setProcentTokens, setProcentTokens,currentStep, setNextDisable, selected, fixValue, percValue])

    //resize
    const resizeFunc = () => {
        if(window.innerWidth <= 768) {
            setWindowSizeMd(true)
        } else {
            setWindowSizeMd(false)
        }
    }
    //resize
    useEffect(() => {
        resizeFunc()
        window.addEventListener('resize', resizeFunc)
        return () => {
            window.removeEventListener('resize', resizeFunc)
        }
    }, [])

    const onChange = (e) => {
        setSelected(e.target.value)
        setPercValue('')
        setFixValue('')
    }

    return (
        <div className="nd-step" style={{display: visible ? 'block' : 'none'}}>
            {
                head ? (
                    <h2 className="nd-step__head">{head}</h2>
                ) : null
            }
            
            <div className="NdAmount">
                <div className="NdAmount__item">
                    <Row gutter={[0, 25]}>
                        <Col span={24}>
                            <div className="NdAmount__item_fr">
                                <div className="NdAmount__item_fr_toggle">
                                    <Radio
                                        id='Fix'
                                        text={'Fix'}
                                        value={'Fix'}
                                        onChange={onChange}
                                        checked={selected == 'Fix'}
                                        />
                                </div>
                                <div className={"NdAmount__item_fr_val" + (selected == 'Fix' ? '' : ' hide ')}>
                                            <Input 
                                                mask={Number}
                                                width={180} 
                                                value={fixValue}
                                                onChange={e => {
                                                    
                                                    setFixValue(e)
                                                    if(e != '') {
                                                        setPercValue(null)
                                                    }
                                                }}
                                                />
                                        </div>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="NdAmount__item_sc">
                                <div className="NdAmount__item_sc_toggle">
                                    <Radio
                                        checked={selected == 'DAP'}
                                        id='DAP'
                                        text={'Distribution as a percentage of the amount on the balance of the holder'}
                                        value={'DAP'}
                                        onChange={onChange}
                                        />
                                </div>
                                <div className={"NdAmount__item_sc_val" + (selected == 'DAP' ? ''  : ' hide ')}>
                                    <Input 
                                        placeholder={'%'} 
                                        width={95}
                                        value={percValue}
                                        onChange={e => {
                                            setPercValue(e.target.value)
                                            if(e.target.value) {
                                                setFixValue(null)
                                            }
                                        }}
                                        />
                                </div>
                                
                            </div>
                        </Col>
                        {/* <Col span={24}>
                            <div className="NdAmount__item_th">
                                <div className="NdAmount__item_th_toggle">
                                    <Radio
                                        id='DBT'
                                        text={'Distribution by tiers'}
                                        value={'DBT'}
                                        onChange={onChange}
                                        checked={selected == 'DBT'}
                                        />
                                </div>
                                {
                                    selected == 'DBT' ? (
                                        <div className="NdAmount__item_th_body">
                                            <Row className='NdAmount__item_th_body_head-wr' gutter={[25, 0]}>
                                                <Col span={5} className="NdAmount__item_th_body_head">
                                                    Min token value
                                                </Col>
                                                <Col span={5} className="NdAmount__item_th_body_head">
                                                    Max token value
                                                </Col>
                                                <Col span={5} className="NdAmount__item_th_body_head">
                                                    Type
                                                </Col>
                                                <Col span={5} className="NdAmount__item_th_body_head">
                                                    Value
                                                </Col>
                                                <Col span={4} className="NdAmount__item_th_body_head"></Col>
                                            </Row>
                                            {
                                                list && list.length > 0 ? (
                                                    list.map((item, index) => (
                                                    <div className="NdAmount__item_th_body_item" key={index}>
                                                        <Row gutter={[25, 10]} style={{alignItems: 'center'}}>
                                                            <Col
                                                                xs={12}
                                                                sm={12}
                                                                md={5}
                                                                // span={5}
                                                                className='NdAmount__item_th_body_item_part'
                                                                >   
                                                                <Input
                                                                    placeholder={'up'}
                                                                    />

                                                            </Col>
                                                            <Col
                                                                xs={12}
                                                                sm={12}
                                                                md={5}
                                                                // span={5}
                                                                className='NdAmount__item_th_body_item_part'
                                                                >
                                                                <Input
                                                                    placeholder={'to'}
                                                                    />
                                                            </Col>
                                                            <Col
                                                                xs={12}
                                                                sm={12}
                                                                md={5}
                                                                // span={5}
                                                                className='NdAmount__item_th_body_item_part'
                                                                >
                                                                <SelectA value={'val 1'}/>

                                                            </Col>
                                                            <Col
                                                                xs={12}
                                                                sm={12}
                                                                md={5}
                                                                // span={5}
                                                                className='NdAmount__item_th_body_item_part'
                                                                >
                                                                <Input
                                                                    placeholder={'value'}
                                                                    />                          
                                                            </Col>
                                                            <Col
                                                                xs={24}
                                                                sm={24}
                                                                md={4}
                                                                // span={4}
                                                                className='NdAmount__item_th_body_item_action'
                                                                >   
                                                                {
                                                                    windowSizeMd ? (
                                                                        <Button
                                                                            style={{width: '100%'}}
                                                                            text={'Delete'}
                                                                            onClick={() => deleteNewTier(index)}
                                                                            variant={'danger'}
                                                                            />
                                                                    ) : (
                                                                        <IconButton
                                                                            onClick={() => deleteNewTier(index)}
                                                                            icon={<HiOutlineTrash color='var(--red1)'/>}
                                                                            />
                                                                    )
                                                                }
                                                                
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    ))
                                                ) : null
                                            }
                                            <div className="NdAmount__item_th_body_action">
                                                {
                                                    windowSizeMd ? (
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                justifyContent:'center',
                                                                alignItems: 'center'
                                                            }}
                                                            >
                                                            <IconButton
                                                                onClick={addNewTier}
                                                                icon={<IoMdAddCircle size={40} color='var(--text)'/>}
                                                            />
                                                        </div>
                                                        
                                                    ) : (
                                                        <Button 
                                                            onClick={addNewTier}
                                                            afterIcon={<BiPlus/>} 
                                                            text="Add new"
                                                            
                                                            />
                                                    )
                                                }
                                                
                                            </div>
                                        </div>
                                    ) : null
                                }
                                
                            </div>
                        </Col> */}
                        
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default NdAmount;