import './NewDrop.scss';
import { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import NewDropTm from './components/NewDropTm/NewDropTm';
import NdTod from './components/steps/NdTod/NdTod';
import NdAmount from './components/steps/NdAmount/NdAmount';
import NdDate from './components/steps/NdDate/NdDate';
import NdCurrency from './components/steps/NdCurrency/NdCurrency';
import Button from '../../components/Button/Button';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import apiService from '../../service/apiService';
import { useNavigate } from 'react-router-dom';
import MainCalendar from '../../components/MainCalendar/MainCalendar';
import SbInfo from '../../components/SbInfo/SbInfo';



const service = new apiService()

const recieversMock = [
    {
        account: '',
        balance: ''
    }
]


const NewDrop = () => {
    const {authData} = useSelector(state => state)
    const [currentStep, setCurrentStep] = useState(0)
    const nav = useNavigate()
    
    const [indexDrop, setIndexDrop] = useState('')
    const [label, setLabel] = useState('')
    const [countTokens, setCountTokens] = useState(null)
    const [procentTokens, setProcentTokens] = useState(null)
    const [adress, setAdress] = useState('')
    const [secret, setSecret] = useState('')
    const [issuer, setIssuer] = useState('')
    const [asset, setAsset] = useState('')
    const [memo, setMemo] = useState('')
    const [discountName, setDiscountName] = useState('')
    const [onlyKyc, setOnlyKyc] = useState(false)
    const [onlyTl, setOnlyTl] = useState(false)
    const [recievers, setRecievers] = useState('')
    const [blackList, setBlackList] = useState([])
    // const [onlyIssuer, setOnlyIssuer] = useState({issuer: '', currency: ''})
    const [timeStart, setTimeStart] = useState('')
    const [nextDisable, setNextDisable] = useState(false)
    const [comObj, setComObj] = useState(null)
    const [btnLoad, setBtnLoad] = useState(false)
    

    
    const nextStep = () => {
        setCurrentStep(state => state + 1)
    }

    const prevStep = () => {
        setCurrentStep(state => state - 1)
    }

    const createAirDrop = () => {
        //sender wallet = adress
        //issuer = issuer

        //check comission
        setBtnLoad(true)
        service.checkComission(
            authData?.wallet,
            authData?.token,
            adress,
            issuer,
            comObj
        ).then(res => {
            const l = Object.entries(res).map(item => item[1])
            const f = l.every(i => i == true) 
            
            if(f) {
                const body = {
                    index_drop: Number(indexDrop),
                    label: label,
                    procentTokens: typeof(procentTokens) == 'object' ? null : Number(procentTokens) / 100,
                    countTokens: typeof(countTokens) == 'object' ? null : Number(countTokens),
                    address: adress,
                    secret: secret,
                    issuer: issuer,
                    asset: asset,
                    memo: memo,
                    onlyKyc: onlyKyc,
                    onlyTl: onlyTl,
                    onlyIssuer: null,
                    snapshotIndex: Number(recievers),
                    timeStart: timeStart,
                    discountName: "",
                    blackList: blackList,
                    old_index_drop: null
                }
                service.createAirdrop(authData?.wallet, authData?.token, body).then(res => {
                    if(res.status == 200) {
                        nav('/airdrop')
                    }
                }).finally(_ => setBtnLoad(false))
            } else {
                //показываем ошибку
                console.log('error')
                setBtnLoad(false)
            }
            
        })

        
    }



    return (

        <div className="page NewDrop">
            <div className="page__body">
                <Header/>
                <div className="page__body_in">
                    <div className="content">
                        <h2 className="NewDrop__head md-title">
                            New AirDrop
                        </h2>
                        <div className="NewDrop__tm">
                            <NewDropTm
                                btnLoad={btnLoad}
                                createAirDrop={createAirDrop} 
                                setCurrentStep={setCurrentStep} 
                                currentIndex={currentStep} 
                                nextDisable={nextDisable}
                                nextStep={nextStep}/>
                        </div>
                        <div className="NewDrop__body">
                            <NdTod 
                                setBlacklist={setBlackList}
                                setRecievers={setRecievers}
                                setOnlyTl={setOnlyTl}
                                setOnlyKyc={setOnlyKyc}
                                setNextDisable={setNextDisable}
                                currentStep={currentStep}
                                head={'Type of distribution'}
                                visible={currentStep == 0}/>
                            <NdAmount 
                                setCountTokens={setCountTokens}
                                setProcentTokens={setProcentTokens}
                                setNextDisable={setNextDisable}
                                currentStep={currentStep}
                                head={'Amount'}
                                visible={currentStep == 1}/>
                            <NdDate 
                                setNextDisable={setNextDisable}
                                setTimeStart={setTimeStart}
                                currentStep={currentStep}
                                head={'Date'}
                                visible={currentStep == 2}/>
                            <NdCurrency
                                blacklist={blackList}
                                setLabel={setLabel}
                                comData={countTokens ? countTokens : `*${Number(procentTokens / 100)}`}
                                comSn={Number(recievers)}
                                setMemo={setMemo}
                                setAsset={setAsset}
                                setIssuer={setIssuer}
                                setAdress={setAdress}
                                setSecret={setSecret}
                                setNextDisable={setNextDisable}
                                currentStep={currentStep}
                                head={'Currency'} 
                                setComObj={setComObj}
                                visible={currentStep == 3}/>
                        </div>
                        <div className="NewDrop__action">
                                <Button
                                        disabled={nextDisable}
                                        onClick={currentStep != 3 ? nextStep : createAirDrop}
                                        text={currentStep !== 3 ? 'Next' : 'Create new AirDrop'}
                                        variant={'default'}
                                    />
                            
                        </div>
                    </div>
                    <div className="cd">
                        <MainCalendar/>
                        <SbInfo/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewDrop;