import './icon.scss';

const SnapShotIcon = ({color = 'var(--gray1)', size = 25}) => {
    return (
        <div className="AirDropIcon icon" style={{width: size, height: size}}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4 5L6 2H12L14 5C15.1046 5 16 5.89543 16 7V13C16 14.1046 15.1046 15 14 15H4C2.89543 15 2 14.1046 2 13V7C2 5.89543 2.89543 5 4 5ZM11.597 2.8L13.0637 5H4.93633L6.403 2.8H11.597ZM14 5.8H4C3.33726 5.8 2.8 6.33726 2.8 7V13C2.8 13.6627 3.33726 14.2 4 14.2H14C14.6627 14.2 15.2 13.6627 15.2 13V7C15.2 6.33726 14.6627 5.8 14 5.8ZM11.2 10C11.2 11.215 10.215 12.2 9 12.2C7.78497 12.2 6.8 11.215 6.8 10C6.8 8.78497 7.78497 7.8 9 7.8C10.215 7.8 11.2 8.78497 11.2 10ZM12 10C12 11.6569 10.6569 13 9 13C7.34315 13 6 11.6569 6 10C6 8.34315 7.34315 7 9 7C10.6569 7 12 8.34315 12 10Z" fill={color}/>
            </svg>

        </div>
    )
}

export default SnapShotIcon;