import { Trustline } from '../../../icons/trustline';
import { Twitter } from '../../../icons/twitter';
import { Website } from '../../../icons/website';
import './create.scss';
import checkIcon from '../../../assets/icons/check.svg';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Loader from '../../../components/Loaders/Loader/Loader';
import apiService from '../../../service/apiService';

const service = new apiService()

export const SecondStep = ({active, selectedCollection, nfts, selectedNfts, setSelectedNfts, totalWeight, isNftsLoading}) => {

    const {theme, userData} = useSelector(s => s);

    const enough = (userData.Points / selectedCollection?.pointsPerOneUnit).toFixed(0)
    
    const [isBlocked, setBlocked] = useState(false);
    const [top, setTop] = useState('')

    useEffect(() => {
        if (totalWeight > enough) {
            setBlocked(true)
        } else {
            setBlocked(false)
        }
        if (top == 'inf') {
            setBlocked(false);
        }
    }, [totalWeight, top]);

    useEffect(() => {
        service.getTopNft(userData.wallet).then(r => {
            setTop(r)
        })
    }, []);
    
    return(
        isNftsLoading ?
        <div className={active ? '' : 'second-step_n'}><Loader /></div>
        :
        <div className={`second-step ${active ? '' : 'second-step_n'} ${theme === 'dark' ? '' : 'second-step_l'}`}>
            <div className='second-step__top'>
                <div className='second-step__main'>
                    <div className='second-step__main-1'>
                        <img alt='' src={selectedCollection?.logo} className='second-step__main-pic' />
                        <div className='second-step__main-artist'>
                            <span>Artist:</span>
                            <span>{selectedCollection?.artist}</span>
                        </div>
                    </div>

                    <div className='second-step__main-2'>
                        <span className='second-step__main-title'>{selectedCollection?.collection}</span>
                        <span className='second-step__main-info'>{selectedCollection?.description}</span>
                        <span className='second-step__main-list'>{selectedCollection?.comments}</span>
                    </div>
                </div>
                <div className='second-step__info'>
                    <div className='second-step__info-links'>
                        <a rel="noreferrer" target="_blank" href={selectedCollection?.twitter} className='StepPer__info_links_item'>
                            <Twitter />
                        </a>
                        <a rel="noreferrer" target="_blank" href={selectedCollection?.webSite} className='StepPer__info_links_item'>
                            <Website />
                        </a>
                        <a rel="noreferrer" target="_blank" href={selectedCollection?.tl} className='StepPer__info_links_item'>
                            <Trustline />
                        </a>
                    </div>
                    <div className='second-step__info-text'>
                        <span>Total Pool Size: {selectedCollection?.totalPoolSize}</span> <br /> 
                        <span>Available Pool Size: {selectedCollection?.availablePoolSize}</span><br /> 
                        <span>Daily Reward per 1 {selectedCollection?.weightUnit}: {selectedCollection?.dailyReward} {selectedCollection?.currencyCode}</span> <br/> <br/> 
                        {
                            top == 'inf' ? '' :
                            selectedCollection?.pointsPerOneUnit ?
                            <span>You need {totalWeight * selectedCollection?.pointsPerOneUnit} {selectedCollection?.currencyCode} points to stake this NFTs</span>
                            :
                            ''
                        }
                    </div>
                </div>
            </div>
            {   
                top == 'inf' ? '' :
                selectedCollection?.pointsPerOneUnit ? 
                <span className='second-step__text'>
                    Your {selectedCollection?.currencyCode} points are enough to stake the NFT with rewards 
                    weight of {enough} {selectedCollection?.weightUnit}. Choose the NFT that you want to stake.
                </span>
                :
                ''
            }
            <span className='second-step__text'>The NFT’s you have selected are valued as {totalWeight} {selectedCollection?.weightUnit}</span>

            <div className='second-step__grid'>
                {
                    nfts.sort(function(x, y) {
                        return Number(y.staked) - Number(x.staked);
                     }).map(nft => (
                        <div 
                            className={`second-step__card 
                                ${nft.staked ? 'second-step__card_d' : ''}
                                ${selectedNfts.find(n => n.nfTokenID === nft.nfTokenID) ? 'second-step__card_s' : ''}
                                ${(isBlocked && !selectedNfts.find(n => n.nfTokenID === nft.nfTokenID)) ? 'second-step__card_d' : ''}
                                ${((nft.weight + totalWeight > enough) && top !== 'inf' &&  !selectedNfts.find(n => n.nfTokenID === nft.nfTokenID)) ? 'second-step__card_d' : ''}
                                `} 
                            key={nft.nfTokenID}
                            onClick={() => {
                                if (selectedNfts.find(n => n.nfTokenID === nft.nfTokenID)) {
                                    setSelectedNfts(s => s.filter(e => e.nfTokenID !== nft.nfTokenID))
                                } else {
                                    setSelectedNfts(s => [...s, nft])
                                }
                            }}
                        >
                            <div className={`second-step__card-check 
                                ${selectedNfts.find(n => n.nfTokenID === nft.nfTokenID) ? 'second-step__card-check_s' : ''}
                                ${nft.staked ? 'second-step__card-check_s' : ''}
                                `}>
                                <img alt='' src={checkIcon} />
                            </div>
                            <img alt='' src={nft.linkToMedia} className='second-step__card-pic' />
                            <span className='second-step__card-name'>{nft.name}</span>
                            <span className='second-step__card-karat'>{nft.weight} {nft.weightName}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
};