import './Tabs.scss';



const Tabs = ({tabs, activeIndex, style, onSelect, decline}) => {


    return (
        <div className="Tabs" style={{style}}>
            {
                tabs.map((item, index) => (
                    <div key={index} onClick={() => {
                        onSelect(index)
                        if(index == 0) {
                            decline(true);
                        }
                    }} className={"Tabs__item" + (activeIndex == index ? ' active ' : '')}>
                        {item}
                    </div>
                ))
            }
        </div>
    )
}

export default Tabs;