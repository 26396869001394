import './SelectA.scss';
import { Select } from 'antd';
import {BiChevronDown} from 'react-icons/bi';
import { useState } from 'react';


const optionsMock = [
    {
        value: 'val 1',
        label: 'val 1'
    },
    {
        value: 'val 2',
        label: 'val 2',
    },
    {
        value: 'val 3',
        label: 'val 3'
    }
]

const SelectA = ({
    onChange,
    value,
    options
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleHandle = (e) => {
        if(e) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
    }

    return (
        <Select
            suffixIcon={<BiChevronDown style={{transform: isOpen ? 'rotate(180deg)' : 'rotate(0)'}}/>}
            onDropdownVisibleChange={e => toggleHandle(e)}
            showSearch={false}
            className='SelectA'
            onChange={onChange}
            value={value}
            options={optionsMock}
            />
    )
}

export default SelectA;