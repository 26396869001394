import coin from '../../../../assets/coin.png';
const resultMock = [
    {
        icon: coin,
        name: 'XRP'
    },
    {
        icon: coin,
        name: 'XRP'
    },
    {
        icon: coin,
        name: 'XRP'
    },
    {
        icon: coin,
        name: 'XRP'
    },
    {
        icon: coin,
        name: 'XRP'
    },
    {
        icon: coin,
        name: 'XRP'
    },
    {
        icon: coin,
        name: 'XRP'
    },
    {
        icon: coin,
        name: 'XRP'
    },
    {
        icon: coin,
        name: 'XRP'
    },
    {
        icon: coin,
        name: 'XRP'
    },
    {
        icon: coin,
        name: 'XRP'
    },
    {
        icon: coin,
        name: 'XRP'
    },
    {
        icon: coin,
        name: 'XRP'
    },
    {
        icon: coin,
        name: 'XRP'
    },
]

export default resultMock;