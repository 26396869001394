import '../../stake/components/BurnProcItem/BurnProcItem';
import { useRef, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import {FiChevronDown} from 'react-icons/fi';
import moment from 'moment';
import * as _ from 'lodash';

export const InProgressItem = ({
    complete,
    timeStart,
    timeEnd,

    nfTokenID,
    daily,
    currencyCode,
    linkToMedia,
    earned
}) => {


    const bodyRef = useRef();
    const [bodyHeight, setBodyHeight] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    const days = Math.round(new Date(new Date().getTime() - new Date(timeStart).getTime()) / 1000 / 60 / 60 / 24);

    useEffect(() => {
        if(isOpen && bodyRef?.current) {
            setBodyHeight(bodyRef.current.scrollHeight)
        }
    }, [isOpen, bodyRef])

    const toggleBody = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className={"BurnProcItem DropItem" + (isOpen ? ' is-open ' : '')}>
            <div className="BurnProcItem__head DropItem__head" onClick={toggleBody}>
                <Row style={{alignItems: 'flex-end'}} gutter={[20,0]}>
                    <Col
                        span={6}     
                        >
                        <Row gutter={[5,5]}>
                            <Col span={24}>
                                {/* {moment(Date.now() - ((maxRemainingReward - remainingReward) * 86400000)).format('DD.MM.YYYY')} */}
                                {timeStart ? moment(timeStart).format('DD.MM.YYYY') : moment(timeEnd).format('DD.MM.YYYY')}
                            </Col>
                            {/* <Col span={24}>
                                18:01
                            </Col> */}
                        </Row>
                    </Col>
                    <Col
                        span={8}
                        >
                        <div>{nfTokenID.slice(0, 10)}...{nfTokenID.slice(-10)}</div>
                        {
                            complete ? (
                                <div>Completed</div>
                            ) : (
                                <div>Current staking day: {days}</div>
                            )
                        }
                       
                    </Col>
                    
                    <Col 
                        span={6} style={{textAlign: 'right'}}>
                            {earned} {currencyCode}   
                    </Col>
                    <Col 
                        span={4}
                        >
                        <div  className="DropItem__head_toggle BurnProcItem__head_toggle">
                            <FiChevronDown  fontSize={22}/>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="BurnProcItem__body DropItem__body" ref={bodyRef} style={{height: isOpen ? bodyHeight : 0}}>
                <div className="BurnProcItem__body_in DropItem__body_in">
                    <Row gutter={[10,10]}>
                        <Col span={12}>
                            <div className='in-progress-item__ram'>
                                Details:
                                <a href={`https://bithomp.com/nft/${nfTokenID}`} target="_blank">
                                    <img alt='' className='in-progress-item__pic' src={linkToMedia} />
                                </a>
                            </div>
                        </Col>
                        <Col span={12}>
                            <Row gutter={[0,0]}>
                                <Col span={24}>
                                    <Row gutter={[10,10]}>
                                        <Col span={12}>
                                            <span>Current staking day:</span>  
                                        </Col>
                                        <Col span={12}>
                                            <span>{days}</span> 
                                        </Col>
                                    </Row>
                                </Col>  
                                <Col span={24}>
                                    <Row gutter={[10,10]}>
                                        <Col span={12}><span>Daily reward:</span> </Col>
                                        <Col span={12}><span>{daily} {currencyCode}</span></Col>
                                    </Row>
                                </Col> 
                                <Col span={24}>
                                    <Row gutter={[10,10]}>
                                        <Col span={12}><span>Received:</span></Col>
                                        <Col span={12}><span>{earned} {currencyCode}</span></Col>
                                    </Row>
                                </Col>
                            </Row>    
                        </Col> 
                    </Row>
                </div>
                
            </div>
        </div>
    )
}