import './RlItem.scss';
import {Popover} from 'antd';
import bithompImg from '../../../../assets/bithomp.png';
import xrpscanImg from '../../../../assets/xrpscan.png';
import * as _ from 'lodash';


const RlItem = ({
    index,
    counterparty,
    specification,
    state,
    price
}) => {
    return (
        <tr className="RlItem">
            <td className="RlItem__item">{index + 1}</td>
            <td className="RlItem__item RlItem__item-address">{specification?.counterparty}</td>
            <td className="RlItem__item">{Number(state?.balance) < 0 ? Number(state?.balance) * -1 : Number(state?.balance)}</td>
            <td className="RlItem__item RlItem__item-percent">{Number(state?.percent) < 0 ? 0 : Number(state?.percent)}%</td>
            <td className="RlItem__item">{Number(state?.balance) < 0 ? _.round(Number(state?.balance) * -1 * price, 6) : _.round(Number(state?.balance) * Number(price), 6)} XRP</td>
            <td className="RlItem__item">
                <div className="RlItem__item_links">
                    <Popover 
                        trigger={'hover'}
                        content={'XRPScan'}
                        placement={'bottom'}
                        >
                        <a 
                            href={`https://xrpscan.com/account/${specification?.counterparty}`}
                            target={'_blank'} 
                            className="RlItem__item_link">
                            <img src={xrpscanImg} alt="" />        
                        </a>
                    </Popover>
                    <Popover
                        trigger={'hover'}
                        content={'Bithomp'}
                        placement={'bottom'}
                        >
                        <a 
                            href={`https://bithomp.com/explorer/${specification?.counterparty}`} 
                            target={'_blank'} 
                            className="RlItem__item_link">
                            <img src={bithompImg} alt="" />
                        </a>
                    </Popover>
                   
                </div>
                
            </td>
        </tr>
    )
}

export default RlItem;