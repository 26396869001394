import '../nd-step.scss';
import './NdTod.scss';
import Radio from '../../../../../components/Radio/Radio';
import { Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import ChooseSnapshot from '../../../modals/ChooseSnapshot/ChooseSnapshot';
import Button from '../../../../../components/Button/Button';
import IconButton from '../../../../../components/IconButton/IconButton';
import {AiOutlineEdit} from 'react-icons/ai';
import Input from '../../../../../components/Input/Input';
import { BiPlus } from 'react-icons/bi';
import Text from '../../../../../components/Text/Text';


const NdTod = ({
    visible, 
    head, 
    currentStep, 
    setNextDisable,
    setOnlyKyc,
    setOnlyTl,
    setRecievers,
    setBlacklist
}) => {
    const [selected, setSelected] = useState('')
    const [snapshotModal, setSnapshotModal] = useState(false)
    const [selectedSnapshot, setSelectedSnapshot] = useState(null)
    const [bl, setBl ] = useState('')


    const closeSnapshotModal = () => {
        setSnapshotModal(false)
        // if(!selectedSnapshot) {
        //     setSelected(null)
        // }
    }





    const openSnapshotModal = () => setSnapshotModal(true)


    const radioChange = (e) => {
        setSelected(e.target.value)
        if(e.target.value == 'Distribution by snapshot' || e.target.value == 'Distribution by snapshot, but confirmed global ID & KYC') {
            openSnapshotModal()
        } else {
            setSelectedSnapshot(null)
        }
    }

    



    useEffect(() => {
        if(setNextDisable && currentStep == '0') {
            if(!selected || !selectedSnapshot) {
                setNextDisable(true)
            } else {
                setNextDisable(false)
            }
        }
        
        if(currentStep != '0') {
            if(selected == 'Distribution by snapshot' && selectedSnapshot) {
                setOnlyTl(null)
                setOnlyKyc(false)
                setRecievers(selectedSnapshot.index)
                setBlacklist(bl.split('\n'))
                
            }
            // if(selectedSnapshot) {
            //     setOnlyTl(null)
            //     setOnlyKyc(false)
            //     setRecievers(selectedSnapshot.index)
            // }
            if(selected == 'Distribution by snapshot, but confirmed global ID & KYC' && selectedSnapshot) {
                setOnlyTl(null)
                setOnlyKyc(true)
                setRecievers(selectedSnapshot.index)
                setBlacklist(bl.split('\n'))
            }
        }
        
    }, [currentStep, setNextDisable, selected, selectedSnapshot])

 

    return (
        <div className="nd-step NdTod" style={{display: visible ? 'block' : 'none'}}>
            <ChooseSnapshot selectSnapshot={setSelectedSnapshot} visible={snapshotModal} close={closeSnapshotModal}/>
            {
                head ? (
                    <h2 className="nd-step__head">{head}</h2>
                ) : null
            }
            <div className="NdTod">
                <Row gutter={[0, 25]}>
                    {/* <Col span={24}>
                        <Radio
                            text={'Distribution to all TL'}
                            id="nd-tod-1"
                            onChange={radioChange}
                            checked={selected == 'Distribution to all TL'}
                            value={'Distribution to all TL'}
                            />
                    </Col> */}
                    <Col span={24}>
                        <Row gutter={[35, 10]} style={{alignItems: 'center'}}>
                            <Col span={24} className={"NdTod__item"}>
                                <Radio
                                onChange={radioChange}
                                checked={selected == 'Distribution by snapshot' && selectedSnapshot}
                                text={'Distribution by snapshot'}
                                id="nd-tod-2"
                                value={'Distribution by snapshot'}
                                />

                            </Col>
                            {
                                selectedSnapshot && selected == 'Distribution by snapshot' ? (
                                    <Col span={24}>
                                        <Row gutter={[10,10]}>
                                            <Col>
                                                <span style={{color: 'var(--text)', borderBottom: '1px solid var(--text)'}}>
                                                    {selectedSnapshot?.label}
                                                </span>
                                            </Col>
                                            <Col>
                                                <IconButton
                                                    icon={<AiOutlineEdit color='var(--text)' size={20}/>}
                                                    onClick={openSnapshotModal}
                                                    />
                                            </Col>
                                        </Row>
                                    </Col>
                                ) : null
                            }
                            
                        </Row>
                            
                        
                    </Col>
                    <Col span={24}>
                        <Col span={24} className={"NdTod__item"}>
                            <Radio
                                checked={selected == 'Distribution by snapshot, but confirmed global ID & KYC' && selectedSnapshot}
                                value={'Distribution by snapshot, but confirmed global ID & KYC'}
                                onChange={radioChange}
                                text={'Distribution by snapshot, but confirmed global ID & KYC'}
                                id={'nd-tod-3'}
                            />
                        </Col>
                        {
                                selectedSnapshot && selected == 'Distribution by snapshot, but confirmed global ID & KYC' ? (
                                    <Col span={24}>
                                        <Row gutter={[10,10]}>
                                            <Col>
                                                <span style={{color: 'var(--text)', borderBottom: '1px solid var(--text)'}}>
                                                    {selectedSnapshot?.label}
                                                </span>
                                                
                                            </Col>
                                            <Col>
                                                <IconButton
                                                    icon={<AiOutlineEdit color='var(--text)' size={20}/>}
                                                    onClick={openSnapshotModal}
                                                    />
                                            </Col>
                                        </Row>
                                    </Col>
                                ) : null
                            }
                        
                    </Col>
                    <Col span={24}>
                        <Row gutter={[20,20]}>
                            <Col span={24}>
                                Blacklist
                            </Col>
                            <Col span={24}>
                                <Text
                                    minHeight={150}
                                    placeholder={'Addresses'}
                                    onChange={e => setBl(e.target.value)}
                                    />
                            </Col>
                            <Col span={24} style={{opacity: '.5'}}>
                            ** If you want to exclude special addresses from the distribution (for example, a blacklist or project addresses), then write these addresses in a list. Each address starts with a new line.
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default NdTod;