import { Col, Row } from 'antd';
import { Loader } from '../../../components/Loaders';
import Radio from '../../../components/Radio/Radio';
import './create.scss';

export const FirstStep = ({active, collectionsList = [], selectedCollection, setSelectedCollection, isLoading }) => {

    if (isLoading) {
        return <Loader />;
    }

    return(
        <div className={"StepCur" + (active ? ' active ': '')}>
            <h2 className="step__head nd-step__head">NFT</h2>
            <div className="TokenList">
                <div className="TokenList__in">
                    <div className="TokenList__head">
                        <Row>
                            <Col span={12} style={{borderRight: '0.5px solid var(--text)'}}>
                                <div className="TokenList__head_item">
                                    Collection name
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="TokenList__head_item">
                                    Your NFT’s balance
                                </div>
                            </Col>
                        </Row>
                    </div>  
                    
                    <div className="TokenList__body">
                        {
                            collectionsList.sort((a,b) => Number(b.priority) - Number(a.priority)).map(col => (
                                <div className={"TokenItem" + (col.priority ? " TokenItem_priority" : '')} key={col.index} onClick={() => setSelectedCollection(col)}>
                                    <Row gutter={[0,0]}>
                                        <Col span={12}>
                                            <div className="TokenItem__main">
                                                <div className="TokenItem__main_action">
                                                    <Radio
                                                        id={col.index}
                                                        checked={selectedCollection?.index === col.index}
                                                    />
                                                </div>
                                                <div className="TokenItem__main_info">
                                                    <div className={"TokenItem__main_info_icon" + (col.logo ? '' : ' empty ')}>
                                                        {
                                                            col.logo ? (
                                                                <img src={col.logo} />
                                                            ) : null
                                                        }
                                                    </div>
                                                    <div className="TokenItem__main_info_name">
                                                        {col.collection}
                                                        {
                                                            col.priority ?
                                                            <div className='TokenItem__new'>
                                                                <span>New!</span>
                                                            </div>
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div className="TokenItem__balance">
                                                {col.nftBalance}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}