import { message } from "antd";
import endpoints from "./endpoints";
const headers = {
    'Content-type': 'application/json',
    'Accept': 'application/json',
}


class apiService {

    signin = async (id) => {
        try {
            let res = await fetch(endpoints.sign, {
                method: 'GET',
                headers
            })

            return await res.json()

        } catch(err) {
            console.log(err)    
        }
    }

    checkTransaction = async (uuid) => {
        try {
            let res = await fetch(endpoints.checktr + `?uuid=${uuid}`, {
                method: 'GET',
                headers,
            })

            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }


    getUserData = async (wallet, token) => {
        try {
            let res = await fetch(endpoints.userData + `?wallet=${wallet}&issued_user_token=${token}`, {
                method: 'GET',
                headers,
            })
            
            return await res.json();
        } catch(err) {
            console.log(err)
        }
    }


    makeSnapshot = async (wallet, issuer_token, issuer, label) => {
        try {
            let res = await fetch(endpoints.snapshot + `?wallet=${wallet}&issued_user_token=${issuer_token}&issuer=${issuer}&label=${label}`, {
                method: 'GET',
                headers
            })

            return await res;
        } catch(err) {
            console.log(err)
        }
    }

    deleteSnapshot = async (wallet, issued_user_token, index) => {
        try {
            let res = await fetch(endpoints.deleteSnapshot + `?wallet=${wallet}&issued_user_token=${issued_user_token}&index=${index}`, {
                method: 'DELETE',
                headers
            })

            return await res.status
        } catch(err) {
            console.log(err)
        }
    }

    getRichList = async (issuer, wallet, currency) => {
        try {
            let res = await fetch(endpoints.richlist + `?issuer=${issuer}&wallet=${wallet}&CurrencyCode=${currency}`, {
                method: 'GET',
                headers
            })

            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }

    getPairs = async () => {
        try {
            let res = await fetch(endpoints.getPairs, {
                method: 'GET',
                headers
            })

            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }

    downloadSnapshot = async (wallet, token, index) => {

        try {
            let res = await fetch(endpoints.downloadSnapshot 
                + `?wallet=${wallet}&issued_user_token=${token}&index=${index}`, {
                method: 'GET',
                headers
            })
            return await res.blob();
        } catch(err) {
            console.log(err)
        }
    }

    createAirdrop = async (wallet, token, body) => {
        try {
            let res = await fetch(endpoints.airdrop + `?wallet=${wallet}&issued_user_token=${token}`, {
                method: 'POST',
                headers,
                body: JSON.stringify(body)
            })

            return await res
        } catch(err) {
            console.log(err)
        }
    }

    deleteAirdrop = async (wallet, token, dropIndex) => {
        try {
            let res = await fetch(endpoints.deleteAirdrop + `?wallet=${wallet}&issued_user_token=${token}&indexDrop=${dropIndex}`, {
                method: 'DELETE',
                headers,
            })

            return await res.status;
        } catch(err) {
            console.log(err)
        }
    }


    makeSnapshot_token = async (wallet, token, label, body) => {
        try {
            let res = await fetch(endpoints.snaphto_token + `?wallet=${wallet}&issued_user_token=${token}&label=${label}`, {
                method: 'POST',
                headers,
                body: JSON.stringify(body)
            })
            return await res;
        } catch(err) {
            console.log(err)
        }
    }

    getComission = async (wallet, token, index, rewardForParticpant, body) => {
        try {
            let res = await fetch(endpoints.getComission + `?wallet=${wallet}&issued_user_token=${token}&index=${index}&rewardForParticpant=${rewardForParticpant}`, {
                method: 'POST',
                headers,
                body: JSON.stringify(body)

            })
            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }

    checkComission = async (wallet, token, senderWallet, issuer, body) => {
        try {
            let res = await fetch(endpoints.checkComission + `?wallet=${wallet}&issued_user_token=${token}&SenderWallet=${senderWallet}&issuer=${issuer}`, {
                method: 'POST',
                headers,
                body: JSON.stringify(body)

            })

            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }

    uploadSnapshot = async (wallet, token, name, body) => {
        try {
            let res = await fetch(endpoints.uploadSnapshot + `?wallet=${wallet}&issued_user_token=${token}&name=${name}`, {
                method: 'POST',
                // headers: {
                //     // 'Content-type': 'multipart/form-data'
                //     'Content-type': 'multipart/form-data',
                //     'Accept': 'text/plain',
                // },
                body: body
            })

            return await res
        } catch(err) {
            console.log(err)
        }
    }


    getBurnPools = async (wallet, token) => {
        try {   
            let res = await fetch(endpoints.getBurnPools, {
                method: 'GET',
                headers
            })

            return await res.json();
        } catch(err) {
            console.log(err)
        }
    }

    getBalance = async (body) => {
        try {
            let res = await fetch(endpoints.getBalance, {
                method: 'POST',
                headers,
                body: JSON.stringify(body)
            })

            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }
    getXRPBalance = async (wallet) => {
        try {
            let res = await fetch(endpoints.getBalance, {
                method: 'POST',
                headers,
                body: JSON.stringify({
                    method: "account_info",
                    params: [
                        {
                            account: wallet,
                        }
                    ],
                })
            })

            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }
    getTokensInOffers = async (wallet) => {
        try {
            let res = await fetch(endpoints.getTokensInOffers + `?wallet=${wallet}`, {
                method: 'GET',
                headers
            })

            return await res.json();
        } catch(err) {
            console.log(err)
        }
    }

    newBurn = async (wallet, token, poolindex, amount) => {
        try {
            let res = await fetch(endpoints.newBurn + `?wallet=${wallet}&issued_user_token=${token}&poolIndex=${poolindex}&amount=${amount}`, {
                method: 'GET',
                headers
            })

            return await res.json();
        } catch(err) {
            console.log(err)
        }
    }

    checkBurnTrans = async (uuid) => {
        try {
            let res = await fetch(endpoints.checkBurnTrans + `?uuid=${uuid}`, {
                method: 'GET',
                headers
            })

            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }

    getStakePools = async () => {
        try {
            let res = await fetch(endpoints.getStakePools, {
                method: 'GET',
                headers
            })

            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }

    newStake = async (wallet, token, poolindex, amount) => {
        try {
            let res = await fetch(endpoints.newStake + `?wallet=${wallet}&issued_user_token=${token}&poolIndex=${poolindex}&amount=${amount}`, {
                method: 'GET',
                headers,
            })

            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }

    getTopNft = async (wallet) => {
        try {
            let res = await fetch(endpoints.getTopNft + `?wallet=${wallet}`, {
                method: 'GET',
                headers,
            })

            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }

    getPointsMultiplier = async (wallet) => {
        try {
            let res = await fetch(endpoints.getPointsMultiplier + `?wallet=${wallet}`, {
                method: 'GET',
                headers,
            })

            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }

    pointPrice = async () => {
        try {
            let res = await fetch(endpoints.PointPrice, {
                method: 'GET',
                headers
            })
            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }

    GetNFTStakePools = async (token, wallet) => {
        try {
            let res = await fetch(endpoints.GetNFTStakePools + `?issued_user_token=${token}&wallet=${wallet}`, {
                method: 'GET',
                headers
            })
            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }

    GetUserStakableNFTs = async (token, wallet, poolIndex) => {
        try {
            let res = await fetch(endpoints.GetUserStakableNFTs + `?issued_user_token=${token}&wallet=${wallet}&poolIndex=${poolIndex}`, {
                method: 'GET',
                headers
            })
            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }

    NewNFTStake = async (token, wallet, poolIndex, nftList) => {
        try {
            let res = await fetch(endpoints.NewNFTStake + `?issued_user_token=${token}&wallet=${wallet}&poolIndex=${poolIndex}`, {
                method: 'POST',
                headers,
                body: JSON.stringify(nftList)
            })
            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }

    GetUserCarats = async (wallet) => {
        try {
            const res = await fetch(endpoints.GetUserCarats + `?wallet=${wallet}`, {
                method: 'GET',
                headers
            })
            return res.json()
        } catch(err) {
            console.log(err)
        }
    }

    GetUserPower = async (wallet) => {
        try {
            const res = await fetch(endpoints.GetUserPower + `?wallet=${wallet}`, {
                method: 'GET',
                headers
            })
            return res.json()
        } catch(err) {
            console.log(err)
        }
    }

    GetVote = async () => {
        try {
            let res = await fetch(endpoints.GetVote, {
                method: 'GET',
                headers
            })
            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }

    VoteTransaction = async (token, wallet, index) => {
        try {
            let res = await fetch(endpoints.VoteTransaction + `?user_token=${token}&wallet=${wallet}&APP_id=${index}&OptionIndex=${index}`, {
                method: 'GET',
                headers
            })
            return await res.json()
        } catch(err) {
            console.log(err)
        }
    }
}

export default apiService;