import './RlChart.scss';
import { useEffect, useState, useRef } from 'react';
import ReactApexChart from "react-apexcharts";
const _ = require('lodash')
const chartColors = ['#FF6A6A', '#57B256', '#6AB6FC', '#615BA5', '#5E595F', '#B7B877', '#D9B2B2', '#EE8437', '#2FB4C6','#C6C6CF'];

const RlChart = ({list, coin,info}) => {
    const [chartData, setChartData] = useState(null)
    const [lb, setLb] = useState([])
    const [series, setSeries] = useState([])
    

    useEffect(() => {
        if(list?.length > 0 && coin && info) {
          
            const main = list.slice(0, 9)
            const others = list.slice(9, list.length)
       
            let othersSum = _.sum(others.map(item => Number(item.state.percent)))
            const mainSum = _.sum(main.map(item => Number(item.state.percent)))
            othersSum = othersSum + (100 - (othersSum + mainSum))

            const dData = [...main.map(item => Number(item.state.percent)), othersSum]
            
            const lb = [...main.map(item => item.specification.counterparty), 'Others']
    
            setLb(lb)
            setSeries(dData)
           
        }
    }, [list, info, coin])




    if(lb?.length > 0 && series?.length > 0) {
        return (
                    <ReactApexChart
                        
                        type='donut'
                        labels={lb}
                        series={series}
                        
                        options={{
                            labels: lb,   
                            series:series, 
                            colors: chartColors,
                            stroke: {
                                show: false
                            },
                            tooltip: {
                                enabled: false
                            },
                            legend: {
                                show: false,
                            },
                            dataLabels: {
                                enabled : false,
                            },
                            plotOptions: {
                                
                                // pie: {
                                //     donut: {  
                                //         labels: {
                                //             show: true,
                                //             name: {
                                //                 formatter: (e, i, c) => {
                                //                     const ind = c.globals.seriesNames.findIndex(item => item == e);
                                //                     const findItem = lb[ind]
                                //                     if(findItem != 'Others') {
                                //                         const transformItem = findItem.slice(0, 10) + '...' + findItem.slice(-5);
                                //                         return transformItem;
                                //                     }
                                //                     return findItem;
                                //                 },
                                //                 fontSize: 12,
                                //                 color: 'var(--text)'
                                //             },
                                //             value: {
                                //                 color: 'var(--text)',
                                //                 formatter: (e) => {
                                //                     return _.round(e, 2) + '%'
                                //                 }
                                //             },
                                            
                                //         },
                                //     }
                                // }
                            },
                            chart: {
                                selection: {
                                    enabled: true,
                                },
                            }
                        }}
                        
                        
                        />
                
        )
    }
}

export default RlChart;