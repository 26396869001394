import './BurnHistItem.scss';
import { useRef, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import {FiChevronDown} from 'react-icons/fi';
import Button from '../../../../components/Button/Button';



const BurnHistItem = () => {
    const bodyRef = useRef();
    const [bodyHeight, setBodyHeight] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if(isOpen && bodyRef?.current) {
            setBodyHeight(bodyRef.current.scrollHeight)
        }
    }, [isOpen, bodyRef])

    const toggleBody = () => {
        setIsOpen(!isOpen)
    }


    return (
        <div className={"BurnHistItem DropItem" + (isOpen ? ' is-open ' : '')}>
            <div className="BurnHistItem__head DropItem__head">
                <Row gutter={[20,0]}>
                    <Col
                        span={4}     
                        >
                        <Row gutter={[5,5]}>
                            <Col span={24}>
                                05.12.2022
                            </Col>
                            <Col span={24}>
                                18:01
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        span={8}
                        >
                            
                    </Col>
                    <Col 
                        span={6}>

                    </Col>
                    <Col 
                        span={4}>
                        <Button
                            variant={'default'}
                            text={'Repeat'}
                            style={{width: '100%'}}
                            />
                    </Col>
                    <Col 
                        span={2}
                        >
                        <div onClick={toggleBody} className="DropItem__head_toggle BurnHistItem__head_toggle">
                            <FiChevronDown  fontSize={22}/>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="BurnHistItem__body DropItem__body" ref={bodyRef} style={{height: isOpen ? bodyHeight : 0}}>
                <div className="BurnHistItem__body_in DropItem__body_in">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum tempora exercitationem quisquam molestiae iste fugit laborum quos similique aperiam? Doloribus in odit id placeat et voluptatum! Molestias aspernatur eaque autem!  
                </div>
                
            </div>
        </div>
    )
}

export default BurnHistItem;