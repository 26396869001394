import dropsMock from "../../helpers/dropsMock"
import AirDropItem from "../AirDropItem/AirDropItem"
import AirDropEmpty from "../AirDropEmpty/AirDropEmpty"
import { useSelector } from "react-redux";
import apiService from "../../../../service/apiService";


const service = new apiService();

const AirDropTab = ({open, change, list, updateList}) => {
    const {authData} = useSelector(state => state)

    const deleteDrop = (index_drop) => {
        
        service.deleteAirdrop(authData.wallet, authData.token, index_drop).then(res => {
            if(res == 200) {
                updateList()
            } else {
        
            }
        })
    }

    



    if(open) {
        return (
            <>
                {
                    list && list.length > 0 ? (
                        list.map((item, index) => (
                            <div className="AirDrop__tabs_content_item" key={index}>
                                <AirDropItem
                                    {...item}
                                    onDelete={deleteDrop}
                                    change={change} 
                                    type={'DROP'}/>
                            </div>
                        ))
                    ) : <AirDropEmpty/>
                }
            </>
        )
    }
    
}

export default AirDropTab;