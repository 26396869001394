import './LogoutMenu.scss';
import LogoutCn from '../LogoutCn/LogoutCn';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import Button from '../Button/Button';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';


const LogoutMenu = ({close}) => {
    const {authData} = useSelector(state=> state)
    const [logout, setLogout] = useState(false)


    const openLogout = () => {
        setLogout(true)
        close(false)
    }
    const closeLogout = () => setLogout(false)

    return (
        <div className="LogoutMenu">
            <LogoutCn visible={logout} close={closeLogout}/>
            <div className="LogoutMenu__wallet">
                {authData?.wallet}
            </div>
            <Button
                text={'Log out'}
                variant={'danger'}
                style={{width: '100%'}}
                onClick={openLogout}
                />
        </div>
    )
}

export default LogoutMenu;