import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import './App.scss';
//pages
import Notfound from "../pages/notfound/Notfound";
import AirDrop from "../pages/airdrop/AirDrop";
import NewDrop from "../pages/newdrop/NewDrop";
import RichList from "../pages/richlist/RichList";
import SnapShot from "../pages/snapshot/SnapShot";
import About from "../pages/about/About";
import Sidebar from "../components/Sidebar/Sidebar";
import AuthMobile from "../pages/authmobile/AuthMobile";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Auth from "../components/Auth/Auth";
import apiService from "../service/apiService";
import CheckAuth from "../hoc/CheckAuth";
import { updateToken, updateUserAuth, updateUserData, updateLines, updateXRPBalance, updateTokensInOffers, updateUserCarats, updateUserPower } from "../store/actions";
import { ToastContainer, toast } from 'react-toastify';
import Burn from "../pages/burn/Burn";
import Stake from "../pages/stake/Stake";
import hexTostr from "../funcs/hexToString";
import { Nft } from "../pages/nft/nft";
import { Voting } from "../pages/voting/voting";
import moment from "moment";

const LOCAL_STORAGE = window.localStorage;
const service = new apiService();




const App = () => {
    const {authData, theme, lines, userData} = useSelector(state => state)
    const [marker, setMarker] = useState('')
    const [linesLimit, setLinesLimit] = useState(400)
    const dispatch = useDispatch()
    const loc = useLocation()
    const nav = useNavigate()



    useEffect(() => {
        if(authData?.token && authData?.wallet) {
            service.getUserData(authData.wallet, authData.token).then(res => {
                dispatch(updateUserData(res))
            })
        }
    }, [authData, marker])




    useEffect(() => {
        let balanceBody;
        if(authData?.wallet && authData?.token) {
            if(marker) {
                balanceBody = {
                    method: "account_lines",
                    params: [
                        {
                            account: authData?.wallet,
                            limit: linesLimit,
                        }
                    ],
                    marker: marker
                }
            } else {
                balanceBody = {
                    method: "account_lines",
                    params: [
                        {   
                            account: authData?.wallet,
                            limit: linesLimit,
                        }
                    ],
                }
            }

            
            
            if(marker !== null) {
                service.getBalance(balanceBody).then(res => {
                    const arr = res.result.lines;
                    const r = arr.map(item => {
                        if(item.currency.length > 3) {
                            return {
                                ...item,
                                currency: hexTostr(item.currency).split('\x00')[0]
                            }
                        } else {
                            return item;
                        }
                    })
                    dispatch(updateLines([...lines, ...r]))
                    if(res?.result?.marker) {
                        setMarker(res.result.marker)
                    } else {
                        setMarker(null)
                    }
                })
            }
        }
    },[authData, marker, linesLimit, authData])




    


    //theme effect
    useEffect(() => {
        if(theme) {
            document.documentElement.dataset.theme = theme
        }
    }, [theme])


    useEffect(() => {
        if(loc?.pathname == '/') {
            nav('/airdrop', {replace: true})
        }
    }, [loc])


    //get userdata (airdrops, snapshots)
    useEffect(() => {
        if(authData?.token && authData?.wallet) {
            service.getUserData(authData?.wallet, authData?.token).then(res => {
                if(!res) {
                    if (LOCAL_STORAGE.getItem('xumn-time')) {
                        const expireAt = moment(LOCAL_STORAGE.getItem('xumn-time'), 'MMMM Do YYYY').valueOf();
                        const today = new Date().getTime();

                        if (today > expireAt) {
                            LOCAL_STORAGE.removeItem('xumm-adtools-user-token')
                            LOCAL_STORAGE.removeItem('xumm-adtools-user-uuid')
                            LOCAL_STORAGE.removeItem('xumm-adtools-user-wallet')
                            LOCAL_STORAGE.removeItem('xumn-time')
                            dispatch(updateToken(null))
                            dispatch(updateUserAuth({
                                token: null,
                                uuid: null,
                                wallet: null
                            }))
                        }
                    } else {
                        LOCAL_STORAGE.removeItem('xumm-adtools-user-token')
                        LOCAL_STORAGE.removeItem('xumm-adtools-user-uuid')
                        LOCAL_STORAGE.removeItem('xumm-adtools-user-wallet')
                        LOCAL_STORAGE.removeItem('xumn-time')
                        dispatch(updateToken(null))
                        dispatch(updateUserAuth({
                            token: null,
                            uuid: null,
                            wallet: null
                        }))
                    }
                }
            })
        } else {
            return;
        }
    }, [authData])
    
     useEffect(() => {
        if (authData?.wallet) {
            service.getXRPBalance(authData.wallet).then(data => {
                dispatch(updateXRPBalance(data?.result))
            })
            setInterval(() => {
                service.getXRPBalance(authData.wallet).then(data => {
                    dispatch(updateXRPBalance(data?.result))
                })
            }, 30_000)

            service.GetUserCarats(authData.wallet).then(data => {
                dispatch(updateUserCarats(data))
            })
            service.GetUserPower(authData.wallet).then(data => {
                dispatch(updateUserPower(data))
            })
        }
    }, [authData])

    useEffect(() => {
        if (authData?.wallet) {
            service.getTokensInOffers(authData.wallet).then(data => {
                dispatch(updateTokensInOffers(data?.tokens || []))
            })
        }
    }, [authData])

    return (
        <div className="App">
            <ToastContainer
                
                />
            <Auth/>
            <Sidebar/>
            <Routes>
                {/* <Route path="/" element={<AirDrop/>}/> */}
                <Route path="/auth" element={<AuthMobile/>}/>
                <Route path="/airdrop" element={<CheckAuth><AirDrop/></CheckAuth>}/>
                <Route path="/airdrop/new-drop" element={<CheckAuth><NewDrop/></CheckAuth>}></Route>
                {/* <Route path="/richlist" element={<CheckAuth><RichList/></CheckAuth>}/> */}
                <Route path="/richlist/:coin/:issuer" element={<CheckAuth><RichList/></CheckAuth>}/>
                <Route path="/snapshot" element={<CheckAuth><SnapShot/></CheckAuth>}/>
                <Route path="/about" element={<CheckAuth><About/></CheckAuth>}/>
                <Route path="/burn" element={<CheckAuth><Burn/></CheckAuth>}/>
                <Route path="/staking" element={<CheckAuth><Stake/></CheckAuth>}/>
                <Route path="/staking-nft" element={<CheckAuth><Nft/></CheckAuth>}/>
                <Route path="/voting" element={<CheckAuth><Voting/></CheckAuth>}/>
                <Route path="*" element={<CheckAuth><Notfound/></CheckAuth>}/>
                
            </Routes>
        </div>
    )
}

export default App;