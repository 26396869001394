import "./AirDropItem.scss";
import Button from "../../../../components/Button/Button";
import {useState, useEffect, useRef} from 'react';
import { Row, Col } from "antd";
import {FiChevronDown} from 'react-icons/fi';
import moment from 'moment';
const _ = require('lodash');





const AirDropItem = ({
    onDelete,
    change,
    timeStart,
    Wasted_RLT,
    index_drop,
    Wasted_UserToken,
    Wasted_XRP,
    TokenCode,
    label,
    Status,
    onlyKyc,
    Need_UserToken,
    Need_XRP,
    Need_RLT
}) => {

    const bodyRef = useRef();
    const [bodyHeight, setBodyHeight] = useState(0)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if(isOpen && bodyRef?.current) {
            setBodyHeight(bodyRef.current.scrollHeight)
        }
    }, [isOpen, bodyRef])

    const toggleBody = () => {
        setIsOpen(!isOpen)
    }




    return (
        <div className={"AirDropItem" + (isOpen ? ' is-open ' : '')}>
            <div className="AirDropItem__head">
                <Row gutter={[20, 0]}>
                    <Col 
                        // span={4}
                        xs={6}
                        sm={6}
                        md={4} 
                        className="AirDropItem__head_tm-wrap">
                        <div className="AirDropItem__head_tm">
                            <div className="AirDropItem__head_tm_date">
                            {moment.utc(timeStart).format('DD.MM.YYYY')}
                            </div>
                            <div className="AirDropItem__head_tm_time">
                            UTC {moment.utc(timeStart).format('hh:mm')}
                            </div>
                        </div>
                    </Col>
                    <Col 
                        // span={10} 
                        xs={14}
                        sm={14}
                        md={10}
                        className="AirDropItem__head_text-wrap">
                        <div className="AirDropItem__head_text">
                            {label}
                            {/* Distribution according to the SnapShot */}
                            {/* <div className="AirDropItem__head_text_status">
                            According to the schedule
                            </div> */}
                            <div className="AirDropItem__head_text_pr">
                                {Need_UserToken ? _.round(Need_UserToken, 4) : 0} {TokenCode}
                            </div>
                        </div>
                    </Col>
                    <Col 
                        span={4} 
                        className="AirDropItem__head_pr-wrap">
                        <div className="AirDropItem__head_pr">
                        {Need_UserToken ? _.round(Need_UserToken, 4) : 0} {TokenCode}
                        </div>
                    </Col>
                    <Col span={4} className="AirDropItem__head_action-wrap">
                        <Button
                            disabled={true}
                            onClick={change}
                            text={'Change'}
                            variant={'default'}
                            style={{width: '100%'}}
                            />
                        
                    </Col>
                    <Col 
                        // span={2}
                        xs={4}
                        sm={4}
                        md={2}
                        >
                        <div onClick={toggleBody} className="AirDropItem__head_toggle">
                            <FiChevronDown  fontSize={22}/>
                        </div>
                    </Col>
                </Row>
                
            </div>
            <div className="AirDropItem__body" ref={bodyRef} style={{height: isOpen ? bodyHeight : 0}}>
                <div className="AirDropItem__details">
                    <Row gutter={[10, 10]}>
                        <Col xs={6}
                        sm={6}
                        md={4} >
                            <div className="AirDropItem__details_name">
                                Details:
                            </div>
                        </Col>
                        <Col xs={14}
                        sm={14}
                        md={10}>
                            <div className="AirDropItem__details_body">
                                {moment(timeStart).format() > Date.now() ? 'Delayed' : null}
                                {onlyKyc ? 'Distribution by snapshot, but confirmed global ID & KYC' : 'Distribution by snapshot'}
                         
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="AirDropItem__done">
                    <Row gutter={[20,0]} style={{alignItems: 'flex-end'}}>
                        <Col span={8}>
                            <div className="AirDropItem__done_details">
                                <div className="AirDropItem__done_details_name">
                                    You need to enter the following assets:
                                </div>
                            </div>
                        </Col>
                        <Col 
                            // span={10}
                            xs={24}
                            sm={24}
                            md={10}
                            >
                            <div className="AirDropItem__done_info">
                                
                                <div className="AirDropItem__done_info_item">
                                    <div className="AirDropItem__done_info_item_name">Amount:</div>
                                    <div className="AirDropItem__done_info_item_value">{Need_UserToken ? _.round(Need_UserToken, 4) : 0} {TokenCode}</div>
                                </div>
                                <div className="AirDropItem__done_info_item">
                                    <div className="AirDropItem__done_info_item_name">Fee XRP:</div>
                                    <div className="AirDropItem__done_info_item_value">{Need_XRP ? _.round(Need_XRP, 4) : 0} XRP</div>
                                </div>
                                <div className="AirDropItem__done_info_item">
                                    <div className="AirDropItem__done_info_item_name">Fee RLT:</div>
                                    <div className="AirDropItem__done_info_item_value">{Need_RLT ? _.round(Need_RLT, 4) : 0} RLT</div>
                                </div>
                            </div>
                        </Col>
                        <Col className="AirDropItem__done_action" span={4} style={{display: 'flex',alignItems: 'flex-end'}}>
                            <Button
                            onClick={() => onDelete(index_drop)}
                            text={'Delete'}
                            variant={'danger'}
                            style={{width: '100%'}}
                            />
                        </Col>
                        <Col span={24}>
                            <div className="AirDropItem__body_mb">
                                <Row gutter={[20, 0]}>
                                    <Col span={12}>
                                        <Button
                                            disabled={true}
                                            text={'Change'}
                                            variant={'default'}
                                            // onClick={change}
                                            />
                                    </Col>
                                    <Col span={12}>
                                        <Button
                                            onClick={() => onDelete(index_drop)}
                                            text={'Delete'}
                                            variant={'danger'}
                                            />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
                
            </div>
        </div>
    )
}

export default AirDropItem;