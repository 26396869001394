const ba = (b,a) => Math.min(Number(b), Number(a))

const compareBalance = (balanceObj = {}, userData = {}) => {
    // const main = Number(cpoints) * Number(yourPoints);
    
    // if(main < Number(avilable) && main < Number(balance)) {
    //     return main;
    // } else {
    //     return ba(balance, avilable);
    // }
    const { balance = 0, currency = '' } = balanceObj;
    const { Staked = [] } = userData;
    const staked = Staked.filter(e => e.currencyCode_Symbol === currency).filter(e => e.remainingReward !== 0);
    const stakedSum = staked.reduce((acc, value) => acc + value.staked, 0);

    return Number(balance) - stakedSum
}

export const getStaked = (balanceObj = {}, userData = {}) => {
    // const main = Number(cpoints) * Number(yourPoints);
    
    // if(main < Number(avilable) && main < Number(balance)) {
    //     return main;
    // } else {
    //     return ba(balance, avilable);
    // }
    const { currency = '' } = balanceObj;
    const { Staked = [] } = userData;
    const staked = Staked.filter(e => e.currencyCode_Symbol === currency).filter(e => e.remainingReward !== 0);
    const stakedSum = staked.reduce((acc, value) => acc + value.staked, 0);

    return stakedSum
}

export default compareBalance;