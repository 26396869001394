import './AuthMobile.scss';
import Qr from '../../components/Auth/components/Qr';
import { Col, Row } from 'antd';
import Button from '../../components/Button/Button';
import Header from '../../components/Header/Header';
import { useSelector , useDispatch} from 'react-redux';
import { useState, useEffect } from 'react';
import apiService from '../../service/apiService';
import { message } from 'antd';
import { updateUserAuth, updateToken } from '../../store/actions';
import { useNavigate, Link } from 'react-router-dom';
import googlePlay from '../../assets/google-play-badge.png';
import appStore from '../../assets/app-store-badge.svg';
import moment from 'moment';


const LOCAL_STORAGE = window.localStorage;

const service = new apiService()



const AuthMobile = () => {
    const dispatch = useDispatch()
    const nav = useNavigate()
    const {authData, token} = useSelector(state => state)
    const [qrImg, setQrImg] = useState(null)
    const [link, setLink] = useState(null)
    const [uuid, setUuid] = useState(null)
    const [load, setLoad] = useState(false)
    const [mob, setMob] = useState(false)


    useEffect(() => {
        
        if(token || window.innerWidth > 1000) {
            nav('/airdrop', {replace: true})
        }
        
    }, [token])


    useEffect(() => {
        service.signin().then(res => {
            if(res?.uuid) {
                setQrImg(`https://xumm.app/sign/${res.uuid}.png`)
                setLink(`https://xumm.app/sign/${res.uuid}`)
                setUuid(res.uuid)
            }
        })
    }, [])

    useEffect(() => {
        if(uuid) {
            const checkMob = setInterval(() => {
                service.checkTransaction(uuid).then(res => {
                    if(res) {
                        LOCAL_STORAGE.setItem('xumm-adtools-user-token', res.userToken)
                        LOCAL_STORAGE.setItem('xumm-adtools-user-uuid', res.uuid)
                        LOCAL_STORAGE.setItem('xumm-adtools-user-wallet', res.wallet)
                        LOCAL_STORAGE.setItem('xumn-time', moment(new Date()).add(30, 'days').format('MMMM Do YYYY'))
                        dispatch(updateUserAuth({
                            token: res.userToken,
                            uuid: res.uuid,
                            wallet: res.wallet
                        }))
                        dispatch(updateToken(res.userToken))
                        setMob(false)
                        clearInterval(checkMob)
                    }
                })
            }, 3000)
            
        }
    }, [uuid])


    // const signinHandle = () => {
    //     setLoad(true)
    //     service.checkTransaction(uuid).then(res => {
    //         if(!res) {
    //             message.error('Отсканируйте QR-код с мобильного устройства и подпишитесь')
    //         } else {
    //             LOCAL_STORAGE.setItem('xumm-adtools-user-token', res.userToken)
    //             LOCAL_STORAGE.setItem('xumm-adtools-user-uuid', res.uuid)
    //             LOCAL_STORAGE.setItem('xumm-adtools-user-wallet', res.wallet)
    //             dispatch(updateUserAuth({
    //                 token: res.userToken,
    //                 uuid: res.uuid,
    //                 wallet: res.wallet
    //             }))
    //             dispatch(updateToken(res.userToken))
    //         }
    //     }).finally(_ => setLoad(false))
    // }

    const redirectToApp = () => {
        setMob(true)
        window.open(link, '_blank')
    }



    return (
        <div className="page AuthMobile">
            <div className="page__body">
                {/* <Header/> */}
                <div className="page__body_in AuthMobile__in">
                    <Row gutter={[0, 30]}>
                            <Col span={24}>
                                <h2 className="md-title AuthMobile__head">Log in</h2>
                            </Col>
                            <Col span={24}>
                                <div className="AuthMobile__text">
                                XRPLTOOLS.PRO is more than an assistant, it is a new and convenient program for managing your tokens (not related to storage) for distributing them to holders, multiplying them by Burn to earn or Staking and more, compiling Richlists, generating Snapshots.
                                <br></br>
                                <br></br>
                                We are constantly working on improving and updating functionality.
                                <br />
                                Scan the QR code to get information related to your account on the XRPL network.

                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="AuthMobile__qr">
                                    <Qr
                                        image={qrImg}
                                        />
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="AuthMobile__ex">
                                    Scan this QR Code with XUMM
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="AuthMobile__action">
                                    <Button
                                        load={mob}
                                        onClick={redirectToApp}
                                        disabled={!link}
                                        text={'Open XUMM'}
                                        variant={'default'}
                                        />
                                </div>
                            </Col>
                            {/* <Col span={24}>
                                <div className="AuthMobile__action">
                                    <Button
                                        // load={load}
                                        load={load}
                                        onClick={signinHandle}
                                        disabled={!qrImg}
                                        text={'Sign in with QR'}
                                        variant={'default'}
                                        /> 
                                </div>
                                
                            </Col> */}
                            <Col span={24}>
                                <Row gutter={[0,5]}>
                                    <Col span={24}>
                                        <a 
                                            href={'https://play.google.com/store/apps/details?id=com.xrpllabs.xumm'} 
                                            target={'_blank'} 
                                            className='AuthMobile__dw'>
                                            <img src={googlePlay} alt=""/>
                                        </a>
                                    </Col>
                                    <Col span={24}>
                                        <a  
                                            href={'https://apps.apple.com/us/app/xumm/id1492302343'} 
                                            target={'_blank'} 
                                            className='AuthMobile__dw AuthMobile__dw-as'>
                                            <img src={appStore} alt="" />
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                </div>
            </div>
        </div>
    )
}

export default AuthMobile;