import './StepLine.scss';
import { Row, Col } from 'antd';
import { BiCheck } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import Button from '../Button/Button';



const StepLine = ({
    currentIndex,
    items,
    btnLoad,
    nextStep,
    nextDisable,
    steps,
    onSubmit,
    setCurrentStep,
    stepChange,
    isOnlyThree
}) => {
    const {theme} = useSelector(state => state);

    return (
        <div className={`StepLine ${isOnlyThree ? 'StepLine_three' : ''}`}>
            <Row gutter={[20, 0]} style={{width: '100%'}}>
                {
                    items.map((item, index) => (
                        <Col
                            xs={6}
                            sm={6}
                            md={5} 
                            key={index}
                            className={'StepLine__step'}
                            >
                            <div 
                                onClick={() => {
                                    if(index < currentIndex && stepChange) {
                                        setCurrentStep(index)
                                    }
                                }}
                                className={"StepLine__item" + (currentIndex == index ? ' current ' : '') + (index < currentIndex ? ' done ' : '')}>
                                <div className="StepLine__item_val">
                                    <div className="StepLine__item_val_el">
                                        {
                                            index < currentIndex ? (
                                                <BiCheck fontSize={30} color={theme == 'dark' ? '#8BC4C8' : '#295458'}/>
                                            ) : (
                                                item.value       
                                            )
                                        }
                                    </div>
                                    
                                </div>
                                <div className="StepLine__item_text">
                                    {item.label}
                                </div>
                            </div>
                        </Col>
                    ))
                }
                <Col 
                    xs={0}
                    sm={0}
                    md={4}
                    className="StepLine__action"
                    >
                    {
                        currentIndex != items.length - 1 ? (
                            <div className="StepLine__action_in">
                                <Button
                                    load={btnLoad}
                                    onClick={currentIndex != 3 ? nextStep : onSubmit}
                                    text={'Next'} 
                                    variant={'default'}
                                    disabled={nextDisable}
                                    />
                            </div>
                        ) : (
                            null
                        )
                    }
                
                </Col>
            </Row>
        </div>
    )
}

export default StepLine;