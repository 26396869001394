import Button from '../../../../components/Button/Button';
import './NewDropTm.scss';
import { Row, Col, theme } from 'antd';
import {BiCheck} from 'react-icons/bi';



const steps = [
    {value: 1,label: 'Type of distribution'},
    {value: 2,label: 'Amount'},
    {value: 3,label: 'Date'},
    {value: 4,label: 'Currency'}
]

const NewDropTm = ({
    setCurrentStep, 
    currentIndex, 
    nextStep, 
    createAirDrop, 
    nextDisable,
    btnLoad
}) => {

    

    return (
        <div className="NewDropTm">
            <Row gutter={[20, 0]} style={{width: '100%'}}>
                {
                    steps.map((item, index) => (
                        <Col
                            xs={6}
                            sm={6}
                            md={5} 
                            key={index}
                            className={'NewDropTm__step'}
                            >
                            <div 
                                // onClick={() => {
                                //     if(index < currentIndex) {
                                //         setCurrentStep(index)
                                //     }
                                // }}
                                className={"NewDropTm__item" + (currentIndex == index ? ' current ' : '') + (index < currentIndex ? ' done ' : '')}>
                                <div className="NewDropTm__item_val">
                                    <div className="NewDropTm__item_val_el">
                                        {
                                            index < currentIndex ? (
                                                <BiCheck fontSize={30} color={theme == 'dark' ? '#8BC4C8' : '#295458'}/>
                                            ) : (
                                                item.value       
                                            )
                                        }
                                    </div>
                                    
                                </div>
                                <div className="NewDropTm__item_text">
                                    {item.label}
                                </div>
                            </div>
                        </Col>
                    ))
                }
                <Col 
                    xs={0}
                    sm={0}
                    md={4}
                    className="NewDropTm__action"
                    >
                <div className="NewDropTm__action_in">
                    <Button
                        load={btnLoad}
                        onClick={currentIndex != 3 ? nextStep : createAirDrop}
                        text={currentIndex != steps.length - 1 ? 'Next' : 'Create AirDrop'} 
                        variant={'default'}
                        disabled={nextDisable}
                        />
                </div>
                </Col>
            </Row>
        </div>
    )
}

export default NewDropTm;