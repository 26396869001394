import './Sidebar.scss';
import { NavLink, Link, useLocation } from 'react-router-dom';

import AirDropIcon from '../../icons/AirDropIcon';
import RichListIcon from '../../icons/RichListIcon';
import SnapShotIcon from '../../icons/SnapShotIcon';
import AboutIcon from '../../icons/AboutIcon';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import apiService from '../../service/apiService';
import logoDark from '../../assets/icons/logod.svg';
import logoLight from '../../assets/icons/logol.svg';
import BurnIcon from '../../icons/BurnIcon';
import StakingIcon from '../../icons/StakingIcon';
import arrow from '../../assets/icons/arrow.svg';
import VoteIcon from '../../icons/voteIcon';


const service = new apiService();

const Sidebar = () => {

    const {theme} = useSelector(state => state);
    const [defaultCoin, setDefaultCoin] = useState('RLT')
    const [defaultIssuer, setDefaultIssuer] = useState('')
    const [openedMenu, setOpenedMenu] = useState([])

    const { pathname } = useLocation();
    
    useEffect(() => {
        if(defaultCoin) {
            service.getPairs().then(res => {
                const initCoin = res.pairs.find(item => item?.base?.currency == 'RLT')
                setDefaultIssuer(initCoin?.base?.issuer)
            })
        }
    }, [defaultCoin])

    return (
        <div className={"Sidebar"}>
            <div className="Sidebar__in">
                <Link className='Sidebar__logo'>
                    {
                        theme == 'light' ? (
                            <img src={logoDark}/>
                        ) : (
                            <img src={logoLight} alt="" />
                        )
                    }
                </Link>
                <div className={`Sidebar__main ${theme === 'dark' ? '' : 'Sidebar__main_l'}`}>
                    <div className="Sidebar__main_list">
                        <div className="Sidebar__main_item">
                            <NavLink 
                                onClick={() => setOpenedMenu(o => o.includes(1) ? o.filter(e => e !== 1) : [...o, 1])} 
                                className={(pathname === '/snapshot' || pathname === '/airdrop') ? 'active' : 'unactive'}
                            >
                                <AirDropIcon size={18} color={'var(--text)'}/>
                                <span>Distribution</span>
                                <img 
                                    className={`Sidebar__main_arrow ${openedMenu.includes(1) ? 'Sidebar__main_arrow_opened' : ''} ${theme === 'light' ? 'Sidebar__main_arrow_d' : ''}`} 
                                    src={arrow} 
                                /> 
                            </NavLink>
                            <div className={`Sidebar__main_subitem ${openedMenu.includes(1) ? 'Sidebar__main_subitem_opened' : ''}`}>
                                <NavLink to={'/airdrop'}>AirDrop</NavLink>
                                <NavLink to={'/snapshot'}>SnapShot</NavLink>
                            </div>
                        </div>
                        <div className="Sidebar__main_item">
                            <NavLink to={`/richlist/${defaultCoin}/${defaultIssuer}`}>
                                <RichListIcon size={18} color={'var(--text)'}/>
                                <span>Richlist</span>
                            </NavLink>
                        </div>
                        <div className="Sidebar__main_item">
                            <NavLink to={'/burn'}>
                                <BurnIcon size={18} color={'var(--text)'}/>
                                <span>Burn to earn</span>
                            </NavLink>
                        </div>
                        <div className="Sidebar__main_item">
                            <NavLink 
                                onClick={() => setOpenedMenu(o => o.includes(2) ? o.filter(e => e !== 2) : [...o, 2])} 
                                className={(pathname === '/staking' || pathname === '/staking-nft') ? 'active' : 'unactive'}
                            >
                                <StakingIcon size={18} color={'var(--text)'}/>
                                <span>Staking</span>
                                <img 
                                    className={`Sidebar__main_arrow ${openedMenu.includes(2) ? 'Sidebar__main_arrow_opened' : ''} ${theme === 'light' ? 'Sidebar__main_arrow_d' : ''}`} 
                                    src={arrow} 
                                /> 
                            </NavLink>
                            <div className={`Sidebar__main_subitem ${openedMenu.includes(2) ? 'Sidebar__main_subitem_opened' : ''}`}>
                                <NavLink to={'/staking'}>Token</NavLink>
                                <NavLink to={'/staking-nft'}>NFT</NavLink>
                            </div>
                        </div>
                        <div className="Sidebar__main_item">
                            <NavLink to={'/voting'}>
                                <VoteIcon size={18} color={'var(--text)'}/>
                                <span>Voting</span>
                            </NavLink>
                        </div>
                    </div>    
                </div>      
                <div className="Sidebar__ex">
                    <div className="Sidebar__ex_list">
                        <div className="Sidebar__ex_item">
                            <NavLink to={'/about'}>
                                <AboutIcon size={18} color={'var(--text)'}/>
                                <span>About</span>
                            </NavLink>
                        </div>
                    </div>
                </div> 
            </div>
                 
        </div>
    )
}

export default Sidebar;