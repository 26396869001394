import './Loader.scss';
import { MoonLoader } from 'react-spinners'

const Loader = ({ qr }) => {

    const width = window?.innerWidth;
    return (
        <div className='Loader'>
            <MoonLoader color="var(--green1)" />
            {
                qr ?
                <div className='Loader__qr'>
                    <img alt="" src={`https://xumm.app/sign/${qr}.png`} />
                    {
                        width < 800 ?
                        <a href={`https://xumm.app/sign/${qr}`} target="_blank">
                            XUMM Wallet
                        </a>
                        :
                        ''
                    }
                </div>
                :
                ''
            }
        </div>
    )
}

export default Loader;