import './SnapShotUpload.scss';
import { Modal } from 'antd';
import Input from '../../../../components/Input/Input';
import { useState, useEffect } from 'react';
import {Row, Col} from 'antd';
import IconButton from '../../../../components/IconButton/IconButton';
import {BiTrash} from 'react-icons/bi';
import Button from '../../../../components/Button/Button';
import { useSelector } from 'react-redux';
import apiService from '../../../../service/apiService';
import {AiOutlineUpload} from 'react-icons/ai';
import { useRef } from 'react';



const service = new apiService();



const SnapShotUpload = ({visible, close, updateList}) => {
    const {authData} = useSelector(state => state)
    const [name, setName] = useState('')
    const [file, setFile] = useState(null)
    const fileRef = useRef();
    const [load, setLoad] = useState(false)

    const closeHandle = () => {
        setName('')
        setFile(null)
        close()
    }

    const onChange = (e) => {
        setFile(e.target.files[0])
    }


    const onSubmit = () => {
        setLoad(true)
        if(authData?.wallet && authData?.token) {
            const data = new FormData()
            data.append('file', file)
            service.uploadSnapshot(authData.wallet, authData.token, name, data).then(res => {
                if(res.status == 200) {
                    updateList()
                    closeHandle()
                } else {
                    console.log('error')
                }
            }).finally(_ => setLoad(false))
        }
    }


    return (
        <Modal
       
            open={visible}
            onCancel={closeHandle}
            className='modal SnapShotUpload'
            >
            <div className="modal__body">
                <h2 className="modal__body_head">Upload your Snapshot</h2>
                <Row
                    gutter={[20,20]}
                    >
                    <Col span={24}>
                        <Input
                            value={name}
                            onChange={e => setName(e.target.value)}
                            placeholder={'Name'}
                            />
                    </Col>
                    <Col span={24}>
                        <div className="SnapShotUpload__file">
                            {
                                file ? (
                                    <div className="SnapShotUpload__file_thumb">
                                        <div className="SnapShotUpload__file_thumb_name">{file.name}</div>
                                        <div className="SnapShotUpload__file_thumb_action">
                                            <IconButton
                                                onClick={() => {
                                                    setFile('')
                                                    // fileRef.current.value = ''
                                                }}
                                                icon={<BiTrash/>}
                                                />
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <input ref={fileRef} accept={'.txt'} id='snapshotUpload' onChange={onChange} type="file" className='SnapShotUpload__file_inp'/>
                                        <label htmlFor='snapshotUpload' className="SnapShotUpload__file_label">
                                            <div className="SnapShotUpload__file_label_icon">
                                                <AiOutlineUpload/>
                                            </div>
                                            <div className="SnapShotUpload__file_label_text">upload file</div>
                                        
                                        </label>
                                    </>
                                )
                            }
                            
                        </div>
                    </Col>
                    <Col span={24} >
                        <div className="SnapShotUpload__ex">
                        ** Attached file conditions:
                            Address and balance must be separated with ";", without spaces
                        </div>
                        
                    </Col>
                </Row> 
                <div className="modal__action SnapShotUpload__action">
                    <Button
                        load={load}
                        disabled={!file || !name}
                        text={'Save'}
                        variant={'default'}
                        onClick={onSubmit}
                        />
                </div>
            </div>
        </Modal>
    )
}

export default SnapShotUpload;