import './SnapShot.scss';
import Header from '../../components/Header/Header';
import SSItem from './components/SSItem/SSItem';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import apiService from '../../service/apiService';
import NoSnapshot from './components/NoSnapshot/NoSnapshot';
import { Loader } from '../../components/Loaders';
import CreateSnapshot from '../../components/CreateSnapshot/CreateSnapshot';
import Button from '../../components/Button/Button';
import endpoints from '../../service/endpoints';
import {Row, Col} from 'antd';
import SnapShotUpload from './modals/SnapShotUpload/SnapShotUpload';
import MainCalendar from '../../components/MainCalendar/MainCalendar';
import SbInfo from '../../components/SbInfo/SbInfo';

const as = new apiService();

const SnapShot = () => {
    const {authData} = useSelector(state => state)
    const [list, setList] = useState([])
    const [loadList, setLoadList] = useState(false)
    const [createSsModal, setCreateSsModal] = useState(false)
    const [delLoad, setDelLoad] = useState(false)
    const [snupload, setSnupload] = useState(false)

    const openSnUpload = () => setSnupload(true)
    const closeSnUpload = () => setSnupload(false)


    useEffect(() => {
        setLoadList(true)
        if(authData?.token && authData?.wallet) {
            as.getUserData(authData?.wallet, authData?.token).then(res => {
                setList(res?.shanpshots?.reverse())
            }).finally(_ => {
                setLoadList(false)
            })
        }
        let tmout = setInterval(() => {
            if(authData?.token && authData?.wallet) {
                as.getUserData(authData?.wallet, authData?.token).then(res => {
                    setList(res?.shanpshots?.reverse())
                }).finally(_ => {
                    setLoadList(false)
                })
            }
        }, 30000)

        return () => clearInterval(tmout)
        
        
    }, [authData])

    const updateSnapshotList = () => {
        setLoadList(true)
       

        as.getUserData(authData?.wallet, authData?.token).then(res => {
                setList(res?.shanpshots?.reverse())
            }).finally(_ => {
                setLoadList(false)
            })
        
    }

    const openCreateSsModal = () => setCreateSsModal(true)
    const closeCreateSsModal = () => setCreateSsModal(false)

    return (
        <div className="SnapShot page">
            <div className="page__body">
                <SnapShotUpload
                    visible={snupload}
                    close={closeSnUpload}
                    updateList={updateSnapshotList}
                    />
                <CreateSnapshot
                    visible={createSsModal}
                    close={closeCreateSsModal}
                    updateList={updateSnapshotList}
                    />
                <Header/>
                <div className="page__body_in">
                    <div className="content">
                        <div className="SnapShot__head">
                            <h2 className="SnapShot__head_title md-title">SnapShot</h2>
                            
                        </div>
                        <div className="SnapShot__body">
                            {
                                loadList ? (
                                    <Loader/>
                                ) : (
                                    list && list.length > 0 ? (
                                        list.map((item, index) => (
                                            <SSItem
                                                // download={downloadSnapshot}
                                                link={endpoints.downloadSnapshot + `?wallet=${authData?.wallet}&issued_user_token=${authData?.token}&index=${item.index}`} 
                                                index={item.index}
                                                name={item.label}
                                                key={item.index}
                                                list={list}
                                                setList={setList}
                                                loading={item.loading}
                                                />
                                        ))
                                    ) : <NoSnapshot uploadSnapshot={openSnUpload} createSnapshot={openCreateSsModal}/>
                                )
                            }
                        </div>
                        {
                            !loadList && list && list.length > 0 ? (
                                <div className="SnapShot__action">
                                    <Row gutter={[20,20]}>
                                        <Col className='SnapShot__action_item' span={12}>
                                            <Button
                                            text={'Create Snapshot'}
                                            variant={'default'}
                                            onClick={openCreateSsModal}
                                            />
                                        </Col>
                                        <Col className='SnapShot__action_item' span={12}>
                                            <Button
                                            text={'Upload Snapshot'}
                                            variant={'default'}
                                            onClick={openSnUpload}
                                            />
                                        </Col>
                                    </Row>
                                    
                                </div>
                            ) : null
                        }
                        
                    </div>
                    <div className="cd">
                        <MainCalendar/>
                        <SbInfo/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SnapShot;