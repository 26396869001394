import './ResultPlaceholder.scss';

const ResultPlaceholder = ({text, icon}) => {
    return (
        <div className="ResultPlaceholder">
            <div className="ResultPlaceholder__icon">{icon}</div>
            <div className="ResultPlaceholder__text">{text}</div>
        </div>
    )
}

export default ResultPlaceholder;