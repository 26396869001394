import './NdDate.scss';
import '../nd-step.scss';
import { Row, Col } from 'antd';
import Input from '../../../../../components/Input/Input';
import Radio from '../../../../../components/Radio/Radio';
import DateA from '../../../../../components/DateA/DateA';
import TimeA from '../../../../../components/TimeA/TimeA';
import { useEffect, useState } from 'react';

import moment from 'moment';
import DTPicker from '../../../../../components/DTPicker/DTPicker';

const NdDate = ({
    visible, 
    head,
    setNextDisable,
    setTimeStart,
    currentStep
}) => {
    const [date, setDate] = useState('')
    const [time, setTime] = useState('')
    const [selected, setSelected] = useState('')
    

    const onChange = (e)=> {
        setSelected(e.target.value)
        if(e.target.value) {
            setSelected(e.target.value)
            if(e.target.value == 'today') {
                setDate(moment.utc(Date.now()).format('YYYY-MM-DD'))
                setTime(moment.utc(Date.now()).format('HH:mm:ss'))
            } else {
                setDate('')
            }
        }
    }

    const selectDate = (e) => {
        if(e) {
            setDate(moment.utc(e._d).format('YYYY-MM-DD'))
        
        } else {
            setDate('')
        }
    }



    const selectTime = (e) => {
        if(e) {
            setTime(moment.utc(e._d).format('HH:mm:ss'))
           
        } else {
            setTime('')
        }
    }

    useEffect(() => {
        if(currentStep == '2' && date && time) {
            setNextDisable(false)
        } else {
            setNextDisable(true)
        }
        if(currentStep != '2' && setTimeStart && date && time) {
            setTimeStart(moment.utc(`${date}T${time}`).format())
        }
    }, [currentStep, setNextDisable, setTimeStart, selected, date, time])

 
    
    return (
        <div className="nd-step" style={{display: visible ? 'block' : 'none'}}>
            {
                head ? (
                    <h2 className="nd-step__head">{head}</h2>
                ) : null
            }
            <div className="NdDate">
                <Row gutter={[0, 25]}>
                    <Col span={24}>
                        <div className="NdDate__item">
                            <div className="NdDate__item_toggle">
                                <Radio
                                    value={'today'}
                                    text={'At the moment'}
                                    id={'nddate-1'}
                                    checked={selected == 'today'}
                                    onChange={onChange}
                                    />
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="NdDate__item">
                            <div className="NdDate__item_toggle">
                                <Radio
                                    text={'Delayed'}
                                    id={'nddate-2'}
                                    checked={selected == 'delayed'}
                                    onChange={onChange}
                                    value={'delayed'}
                                    />
                            </div>
                            {
                                selected == 'delayed' ? (
                                    <div className="NdDate__item_body">
                                        <Row gutter={[40, 20]}>
                                            <Col
                                                xs={24}
                                                sm={12}
                                                md={12}
                                                >
                                                <DateA onChange={selectDate}/>
                                                
                                            </Col>
                                            <Col
                                                xs={24}
                                                sm={12}
                                                md={12}
                                                >
                                                <TimeA onChange={selectTime}/>
                                            </Col>
                                        </Row>
                                    </div>
                                ) : null
                            }
                            
                        </div>
                    </Col>
                    {/* <Col span={24}>
                        <div className="NdDate__item">
                            <div className="NdDate__item_toggle">
                                <Radio
                                    text={'According to the schedule'}
                                    id={'nddate-3'}
                                    />
                            </div>
                            <div className="NdDate__item_body">
                                <Row gutter={[0, 20]}>
                                    <Col span={24} style={{fontSize: 12, lineHeight: '14px'}}>
                                        Time of distribution
                                    </Col>
                                    <Col span={24}>
                                        <Row gutter={[40, 0]}>
                                            <Col span={12}>
                                                <DateA/>
                                            </Col>
                                            <Col span={12}>
                                                <DateA/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <div className="NdDate__item_body_fq">
                                            <div className="NdDate__item_body_fq_label">Distribution frequency</div>
                                            <div className="NdDate__item_body_fq_inp">
                                                <Input
                                                    placeholder={'days'}
                                                    />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                
                                
                            </div>
                        </div>
                    </Col> */}
                </Row>
            </div>
        </div>
    )
}

export default NdDate;