import './NotBody.scss';
import NotItem from './components/NotItem/NotItem';

const notListMock = [
    {
        date: '18.09.2022',
        text: 'AirDrop compleated!',
        danger: false
    },
    {
        date: '10.09.2022',
        text: 'Cancelled AirDrop',
        danger: true
    }
]

const NotBody = ({open}) => {
    return (
        <div className={"NotBody" + (open ? ' active ' : '')}>
            {
                notListMock && notListMock.length > 0 ? (
                    notListMock.map((item, index) => (
                        <NotItem
                            key={index}
                            text={item.text}
                            date={item.date}
                            danger={item.danger}
                            />
                    ))
                ) : null
            }
        </div>
    )
}

export default NotBody;